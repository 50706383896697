import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useKey } from "rooks";
import { AnimatePresence, motion } from "framer-motion";

import useScreenshot from "../../hooks/useScreenshot";
import useWeb3Wallet from "../../hooks/useWeb3Wallet";
import { useGlobalState } from "../../store/store";
import { clickableShrunk } from "../Common/style";
import MenuEditIcon from "../../assets/icons/menu-edit.png";
import MenuEditIconActive from "../../assets/icons/menu-edit-active.png";
import MenuTeamIcon from "../../assets/icons/menu-team.png";
import MenuSnapshotIcon from "../../assets/icons/menu-snapshot.png";
import useSiwe from "../../hooks/useSiwe";
import { ActiveArrowIcon } from "../../assets/icons";
import PhaserGame from "../../PhaserGame";
import HideoutScene from "../../scenes/HideoutScene";
import { playClickSound } from "../../utils/sound";
import { useOwnedExplorers } from "../../hooks/web3DataContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  getButtonLastClicked,
  updateButtonLastClicked,
} from "../../utils/storage";
import { colors } from "../../designSystem/colors";
import { PrimaryText } from "../../designSystem";

const MainMenuContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 2vh;
  display: flex;
  flex-direction: column;
  width: 9vh;
`;

const MenuButtonContainer = styled(motion.div).attrs({
  className: "d-flex align-items-center position-relative",
  initial: {
    x: -200,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: -200,
    opacity: 0,
  },
  transition: {
    type: "keyframes",
  },
  layout: true,
})`
  &:not(:first-child) {
    margin-top: 1vh;
  }
`;

const MenuButton = styled.img.attrs({
  onMouseDown: playClickSound,
})`
  ${clickableShrunk}

  height: 5vh;
`;

const EditMenuButton = styled(MenuButton)<{ editingMode: boolean }>`
  ${(props) =>
    props.editingMode
      ? `
       transform: scale(0.9);
      `
      : ""}
`;

const MenuButtonUnclickedDot = styled.div`
  position: absolute;
  height: 1vh;
  width: 1vh;
  top: 0.75vh;
  left: 3.25vh;
  background: ${colors.primaryRed};
  border-radius: 1vh;
  z-index: 1;
`;

const MainMenu: React.FC = () => {
  const editButtonRef = useRef<HTMLImageElement>(null);
  const { active } = useWeb3Wallet();
  const ownedExplorers = useOwnedExplorers();
  const [, takeScreenShot] = useScreenshot();
  const [, setShowConnectWallet] = useGlobalState("showConnectWallet");
  const [, setShowExplorersModal] = useGlobalState("showExplorersModal");
  const { token } = useSiwe();
  const [, setShowSiweModal] = useGlobalState("showSiweModal");
  const [mode, setMode] = useGlobalState("mode");
  const [, setEditModeActiveLayer] = useGlobalState("editModeActiveLayer");

  const [editButtonClicked, setEditButtonClicked] = useState(
    Boolean(getButtonLastClicked("EDIT"))
  );
  const [squadButtonClicked, setSquadButtonClicked] = useState(
    Boolean(getButtonLastClicked("SQUAD"))
  );

  /**
   * Listen to mode and change className of body
   */
  useEffect(() => {
    const bodyEl = document.getElementsByTagName("body")[0];
    switch (mode) {
      case "edit":
        bodyEl.classList.add("edit");
        break;
      default:
        bodyEl.classList.remove("edit");
    }
  }, [mode]);

  /**
   * Edit mode listerner
   * When is in edit mode, listen for ESC key
   */
  useKey(
    ["Escape"],
    () => {
      setEditModeActiveLayer((prevActiveLayer) => {
        if (prevActiveLayer === undefined) {
          setMode(undefined);
        }

        return undefined;
      });
    },
    {
      when: mode === "edit",
    }
  );

  useEffect(() => {
    const hideoutScene = PhaserGame.scene.getScene("hideout") as HideoutScene;
    const editButton = editButtonRef.current;
    if (editButton && mode === "edit") {
      const { left, top, height, width } = editButton.getBoundingClientRect();
      hideoutScene.addStopPropagationLayer("editModeButton", {
        x: left,
        y: top,
        height,
        width,
      });
    } else {
      hideoutScene.removeStopPropagationLayer("editModeButton");
    }
  }, [mode]);

  const editMenuButton = useMemo(() => {
    if (
      !active ||
      ownedExplorers.loading ||
      ownedExplorers.ownedExplorers.length <= 0 ||
      mode === "explorerFocus"
    ) {
      return null;
    }

    return (
      <MenuButtonContainer key="edit">
        {!editButtonClicked && <MenuButtonUnclickedDot />}
        <OverlayTrigger
          placement="right"
          delay={1000}
          overlay={
            <Tooltip>
              <PrimaryText fontSize="1.2vh" lineHeight={1.5}>
                Enters Edit Mode, where you can furnish & design your Hideout.
              </PrimaryText>
            </Tooltip>
          }
        >
          <EditMenuButton
            ref={editButtonRef}
            src={mode === "edit" ? MenuEditIconActive : MenuEditIcon}
            editingMode={mode === "edit"}
            onClick={() => {
              if (!active) {
                return setShowConnectWallet(true);
              }

              if (!token) {
                return setShowSiweModal(true);
              }

              setEditButtonClicked(Boolean(updateButtonLastClicked("EDIT")));
              setMode((_mode) => (_mode ? undefined : "edit"));
            }}
            className="edit-mode-ignore"
          />
        </OverlayTrigger>
        <motion.div
          animate={{
            opacity: mode === "edit" ? 1 : 0,
            x: mode === "edit" ? 0 : 20,
          }}
          transition={{ type: "keyframes" }}
        >
          <ActiveArrowIcon
            height="auto"
            width="3.5vh"
            style={{ marginLeft: "0.5vh" }}
          />
        </motion.div>
      </MenuButtonContainer>
    );
  }, [
    active,
    editButtonClicked,
    mode,
    ownedExplorers,
    setMode,
    setShowConnectWallet,
    setShowSiweModal,
    token,
  ]);

  const squadMenuButton = useMemo(() => {
    if (!active || mode) {
      return null;
    }

    return (
      <MenuButtonContainer key="squad">
        {!squadButtonClicked && <MenuButtonUnclickedDot />}
        <OverlayTrigger
          placement="right"
          delay={1000}
          overlay={
            <Tooltip>
              <PrimaryText fontSize="1.2vh" lineHeight={1.5}>
                Enters Team Selection, where you can customise your team of
                Explorers.
              </PrimaryText>
            </Tooltip>
          }
        >
          <MenuButton
            onClick={() => {
              setMode(undefined);
              if (active) {
                setSquadButtonClicked(
                  Boolean(updateButtonLastClicked("SQUAD"))
                );
                setShowExplorersModal(true);
                return;
              }

              setShowConnectWallet(true);
            }}
            src={MenuTeamIcon}
          />
        </OverlayTrigger>
      </MenuButtonContainer>
    );
  }, [
    active,
    mode,
    setMode,
    setShowConnectWallet,
    setShowExplorersModal,
    squadButtonClicked,
  ]);

  const screenshotMenuButton = useMemo(() => {
    if (
      !active ||
      ownedExplorers.loading ||
      ownedExplorers.ownedExplorers.length <= 0 ||
      mode
    ) {
      return null;
    }

    return (
      <MenuButtonContainer key="screenshot">
        <OverlayTrigger
          placement="right"
          delay={1000}
          overlay={
            <Tooltip>
              <PrimaryText fontSize="1.2vh" lineHeight={1.5}>
                Take a screenshot of your Hideout.
              </PrimaryText>
            </Tooltip>
          }
        >
          <MenuButton
            onClick={() => {
              setMode(undefined);
              takeScreenShot();
            }}
            src={MenuSnapshotIcon}
          />
        </OverlayTrigger>
      </MenuButtonContainer>
    );
  }, [active, mode, ownedExplorers, setMode, takeScreenShot]);

  return (
    <MainMenuContainer>
      <AnimatePresence>
        {editMenuButton}
        {squadMenuButton}
        {screenshotMenuButton}
      </AnimatePresence>
    </MainMenuContainer>
  );
};

export default MainMenu;
