import React from "react";
import { Web3ReactProvider } from "@web3-react/core";

import Layout from "./components/Layouts/Layout";
import { Web3ContextProvider } from "./hooks/web3Context";
import { getLibrary } from "./utils/getLibrary";
import { Web3DataContextProvider } from "./hooks/web3DataContext";
import { PersonalizedDataContextProvider } from "./hooks/personalizedDataContext";
import useGlobalStateListener from "./hooks/useGlobalStateListener";
import PhaserGame from "./PhaserGame";
import BaseScene from "./scenes/BaseScene";

function App() {
  useGlobalStateListener("mode", (state: any) => {
    const activeScenes = PhaserGame.scene.getScenes(false);
    activeScenes.forEach((scene) => {
      (scene as BaseScene).updateState("mode", state);
    });
  });

  return (
    <Web3ContextProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3DataContextProvider>
          <PersonalizedDataContextProvider>
            <Layout />
          </PersonalizedDataContextProvider>
        </Web3DataContextProvider>
      </Web3ReactProvider>
    </Web3ContextProvider>
  );
}

export default App;
