import { createGlobalState } from "react-hooks-global-state";

import { BackgroundLayer } from "../model/hideout";
import { FurnitureUnlockToast, ToastData } from "../model/toast";

interface GlobalStore {
  showConnectWallet: boolean;
  showHideoutProfileModal: boolean;
  showLayoutCustomizationMenuModal: boolean;
  screenshotModal: {
    show: boolean;
    image?: string;
  };
  showExplorersModal: boolean;
  showSiweModal: boolean;
  explorerInfoModal: {
    show: boolean;
    explorerId?: string;
  };
  editModeActiveLayer?: BackgroundLayer;
  toastDatas: ToastData[];
  furnitureUnlockToast: FurnitureUnlockToast[];
  phaserScene: "loading" | "hideout";
  mode?: "edit" | "explorerFocus";

  siweToken?: string;

  showImpersonateModal: boolean;
  impersonateAddress: string;
}

export type GlobalStoreKey = keyof GlobalStore;

export const defaultScreenshotModal = {
  show: false,
};

export const initialState: GlobalStore = {
  showConnectWallet: false,
  showHideoutProfileModal: false,
  showLayoutCustomizationMenuModal: false,
  screenshotModal: defaultScreenshotModal,
  showExplorersModal: false,
  showSiweModal: false,
  explorerInfoModal: {
    show: false,
  },
  editModeActiveLayer: undefined,
  toastDatas: [],
  furnitureUnlockToast: [],
  phaserScene: "loading",
  mode: undefined,

  siweToken: undefined,

  showImpersonateModal: false,
  impersonateAddress: "",
};

export const { useGlobalState, getGlobalState, setGlobalState } =
  createGlobalState(initialState);
