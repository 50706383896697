import { css, FlattenSimpleInterpolation } from "styled-components";

export type CSSUnit = string | number;
export type ResponsiveCSSUnit =
  | CSSUnit
  | {
      xs?: CSSUnit;
      sm?: CSSUnit;
      md?: CSSUnit;
      lg?: CSSUnit;
      xl?: CSSUnit;
      xxl?: CSSUnit;
      xxxl?: CSSUnit;
    };

export const CSSUnitToString = (unit: CSSUnit): string => {
  if (typeof unit === "number") {
    return `${unit}px`;
  }

  return unit;
};

export const resolveResponsiveCSSUnits = (
  subject: string,
  responsiveUnit: ResponsiveCSSUnit
): FlattenSimpleInterpolation => {
  if (
    typeof responsiveUnit === "string" ||
    typeof responsiveUnit === "number"
  ) {
    return css`
      ${subject}: ${CSSUnitToString(responsiveUnit)};
    `;
  }

  return css`
    ${responsiveUnit.xs
      ? `      
        ${subject}: ${CSSUnitToString(responsiveUnit.xs)};
      `
      : ""}

    ${(["sm", "md", "lg", "xl", "xxl", "xxxl"] as const)
      .map((_size) => {
        const size = responsiveUnit[_size];

        return size
          ? `      
            @media (min-width: ${sizes[_size]}px) {
              ${subject}: ${CSSUnitToString(size)};
            }
          `
          : undefined;
      })
      .filter((css) => css)}
  `;
};

const sizes = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1600,
};

export default sizes;
