import { Cameras, GameObjects, Input } from "phaser";
import { isDevelopment } from "../config/env";

import BaseScene from "./BaseScene";
import {
  backgroundLayersMeta,
  backgroundLayersMetaMap,
} from "../data/gameLayout/backgroundLayers";
import ExplorerObject from "../gameObjects/ExplorerObject";
import LayoutGameObjectWithHitbox from "../gameObjects/LayoutGameObjectWithHitbox";
import {
  BackgroundLayer,
  defaultHideoutSpaceOccupancy,
  HideoutSpaceOccupancy,
} from "../model/hideout";
import { calculateNextCoordinate } from "../utils/math";
import { PositionMeta } from "../utils/camera";
import StopPropagationGameObject from "../gameObjects/StopPropagationGameObject";
import { getGlobalState, setGlobalState } from "../store/store";

export default class HideoutScene extends BaseScene {
  static ZOOM_DURATION = 250;
  static MIN_ZOOM = 1;
  static MAX_ZOOM = 2;

  backgroundImage: Partial<{
    [key in BackgroundLayer]: LayoutGameObjectWithHitbox;
  }> = {};
  unconnectedHideoutSprite?: GameObjects.Sprite;
  controls?: Cameras.Controls.SmoothedKeyControl = undefined;
  explorers: ExplorerObject[] = [];

  hideoutSpaceOccupancy: HideoutSpaceOccupancy = defaultHideoutSpaceOccupancy;

  /**
   * Below variable are for handling explorer focusing
   */
  focusExplorer?: ExplorerObject;
  userZoom?: number;

  /**
   * Camera speed are moving 1000 px per second by default.
   * Camera speed will be adjusted accordingly as needed in order to match
   * zoom speed and required distance covered within a fixed duration.
   */
  cameraSpeed: number = 1000;

  /**
   *
   */
  stopPropagationLayers: {
    [key: string]: StopPropagationGameObject;
  } = {};

  /* NOTE: Development use */
  coordinateText?: GameObjects.Text;
  /* NOTE: End Development use */

  /**
   * We use this variable to keep track with showed modal and choose to pause the scene or not.
   */
  showedModal: { [key: string]: boolean } = {};

  constructor() {
    super("hideout");
  }

  create() {
    this.buildBackground();

    // Set default camera position
    this.setDefaultCameraPosition();
    this.scale.on("resize", this.setDefaultCameraPosition, this);

    // Set default camera zoom
    this.setDefaultZoom();
    this.scale.on("resize", this.setDefaultZoom, this);

    // Set zoom on scroll
    this.input.on("wheel", this.handleZoom, this);

    this.events.once(
      "create",
      () => {
        setGlobalState("phaserScene", "hideout");
      },
      this
    );

    /* NOTE: Development use */
    if (isDevelopment()) {
      this.coordinateText = this.add.text(
        this.cameras.main.width,
        0,
        `(${this.input.activePointer.worldX}, ${this.input.activePointer.worldY})`,
        {
          fontSize: "48px",
          fontFamily: "GinoraSans",
          color: "#000",
        }
      );
      this.coordinateText.depth = 100;
    }
    /* NOTE: End Development use */
  }

  update(_time: number, delta: number) {
    this.explorers.forEach((explorer) => explorer.update(delta));
    this.controls?.update(delta);
    (Object.keys(this.backgroundImage) as BackgroundLayer[]).forEach(
      (layerKey) => {
        this.backgroundImage[layerKey]!.update(delta);
      }
    );

    /**
     * Check active layer
     */
    const activeLayer = getGlobalState("editModeActiveLayer");
    const layerCenter = activeLayer
      ? backgroundLayersMetaMap[activeLayer].center
      : undefined;

    /**
     * Zoom active layer or unzoom
     */
    if (!this.focusExplorer) {
      if (layerCenter && !this.userZoom) {
        this.userZoom = this.cameras.main.zoom;

        const mapWidthPx = this.backgroundImage.layouttop?.width || 0;
        const mapHeightPx = this.backgroundImage.layouttop?.height || 0;
        const cameraWidth = this.cameras.main.width;
        const cameraHeight = this.cameras.main.height;

        this.cameras.main.zoomTo(
          Math.max(
            (cameraWidth / mapWidthPx) * 2,
            (cameraHeight / mapHeightPx) * 2
          ),
          HideoutScene.ZOOM_DURATION
        );
      } else if (!layerCenter && this.userZoom) {
        this.cameras.main.zoomTo(this.userZoom, HideoutScene.ZOOM_DURATION);
        this.userZoom = undefined;
      }
    }

    /**
     * Move camera if needed
     */
    const { x: defaultCameraX, y: defaultCameraY } =
      this.getDefaultCameraPosition();
    const cameraWidth = this.cameras.main.width;
    const cameraHeight = this.cameras.main.height;

    const cameraX = this.cameras.main.scrollX;
    const cameraY = this.cameras.main.scrollY;
    let destinationX = defaultCameraX;
    let destinationY = defaultCameraY;

    if (this.focusExplorer) {
      destinationX = this.focusExplorer.bodySprite.x - cameraWidth / 2;
      destinationY = this.focusExplorer.bodySprite.y - cameraHeight / 2;
    } else if (layerCenter) {
      destinationX = layerCenter.x - cameraWidth / 2;
      destinationY = layerCenter.y - cameraHeight / 2;
    }

    const { delta: moveDelta } = calculateNextCoordinate(
      {
        x: cameraX,
        y: cameraY,
      },
      { x: destinationX, y: destinationY },
      delta,
      this.cameraSpeed
    );

    this.cameras.main.scrollX += moveDelta.x;
    this.cameras.main.scrollY += moveDelta.y;
    if (this.backgroundImage.bg) {
      // Move background together with camera
      this.backgroundImage.bg.x += moveDelta.x;
      this.backgroundImage.bg.y += moveDelta.y;
    }

    Object.keys(this.stopPropagationLayers).forEach((key) =>
      this.stopPropagationLayers[key].update()
    );

    /* NOTE: Development use */
    this.coordinateText?.setText(
      `(${this.input.activePointer.worldX}, ${this.input.activePointer.worldY})`
    );
    /* NOTE: End Development use */
  }

  /**
   * We handle zoom action, making sure it will never zoom too big or too small
   * @param _pointer
   * @param _gameObjects
   * @param _deltaX
   * @param deltaY
   */
  handleZoom(
    _pointer: Input.Pointer,
    _gameObjects: GameObjects.GameObject[],
    _deltaX: number,
    deltaY: number
  ) {
    if (this.focusExplorer || this.userZoom) {
      return;
    }

    const mapWidthPx = this.backgroundImage.layouttop?.width || 0;
    const mapHeightPx = this.backgroundImage.layouttop?.height || 0;
    const cameraWidth = this.cameras.main.width;
    const cameraHeight = this.cameras.main.height;
    const newZoom = this.cameras.main.zoom * (deltaY > 0 ? 0.9 : 1.1);

    try {
      // Make sure zoom is not more than 150% of original image size
      if (
        deltaY < 0 &&
        mapHeightPx * newZoom > cameraHeight * HideoutScene.MAX_ZOOM &&
        mapWidthPx * newZoom > cameraWidth * HideoutScene.MAX_ZOOM
      ) {
        this.cameras.main.zoom = Math.max(
          (cameraWidth / mapWidthPx) * HideoutScene.MAX_ZOOM,
          (cameraHeight / mapHeightPx) * HideoutScene.MAX_ZOOM
        );
        return;
      }

      // Make sure after zoom is not smaller than 100% of screen size
      if (
        deltaY > 0 &&
        mapHeightPx * newZoom < cameraHeight * HideoutScene.MIN_ZOOM &&
        mapWidthPx * newZoom < cameraWidth * HideoutScene.MIN_ZOOM
      ) {
        this.cameras.main.zoom = Math.min(
          (cameraWidth / mapWidthPx) * HideoutScene.MIN_ZOOM,
          (cameraHeight / mapHeightPx) * HideoutScene.MIN_ZOOM
        );
        return;
      }

      this.cameras.main.zoom *= deltaY > 0 ? 0.9 : 1.1;
    } finally {
      this.resizeBackgroundImage();
    }
  }

  /**
   * To be called by Explorer Object when they are to be put on focus position
   * @param explorerId ID of the explorer to focus on
   * @returns Success
   */
  handleFocusExplorer(explorerId: string): boolean {
    const explorerObject = this.explorers.find(
      (explorer) => explorer.lootId === explorerId
    );

    if (this.focusExplorer || !explorerObject) {
      return false;
    }

    this.focusExplorer = explorerObject;
    this.userZoom = this.cameras.main.zoom;

    const mapWidthPx = this.backgroundImage.layouttop?.width || 0;
    const mapHeightPx = this.backgroundImage.layouttop?.height || 0;
    const cameraWidth = this.cameras.main.width;
    const cameraHeight = this.cameras.main.height;

    this.cameras.main.zoomTo(
      Math.max(
        (cameraWidth / mapWidthPx) * 2,
        (cameraHeight / mapHeightPx) * 2
      ),
      HideoutScene.ZOOM_DURATION
    );

    this.cameraSpeed =
      (Phaser.Math.Distance.Between(
        this.cameras.main.centerX,
        this.cameras.main.centerY,
        this.focusExplorer.bodySprite.x,
        this.focusExplorer.bodySprite.y
      ) *
        1000) /
      HideoutScene.ZOOM_DURATION;

    this.backgroundImage.bg?.setFocusExplorerMode(true);

    setGlobalState("mode", "explorerFocus");

    return true;
  }

  /**
   * To be called by Explorer Object when they are to be put on focus position
   * @param explorerId ID of the explorer to focus on
   * @returns
   */
  handleUnfocusExplorer(): boolean {
    if (!this.focusExplorer) {
      return false;
    }

    this.focusExplorer = undefined;

    if (this.userZoom) {
      this.cameras.main.zoomTo(this.userZoom, HideoutScene.ZOOM_DURATION);
      this.userZoom = undefined;
    } else {
      this.setDefaultZoom();
    }

    const { x: defaultCameraX, y: defaultCameraY } =
      this.getDefaultCameraPosition();
    this.cameraSpeed =
      (Phaser.Math.Distance.Between(
        this.cameras.main.scrollX,
        this.cameras.main.scrollY,
        defaultCameraX,
        defaultCameraY
      ) *
        1000) /
      HideoutScene.ZOOM_DURATION;

    this.backgroundImage.bg?.setFocusExplorerMode(false);

    setGlobalState("mode", undefined);

    return true;
  }

  buildBackground() {
    backgroundLayersMeta.forEach((layerMeta, index) => {
      const layerObject = this.add.existing(
        new LayoutGameObjectWithHitbox(this, layerMeta.name, layerMeta.hitBoxes)
      );

      layerObject.setDepth(layerMeta.depth || (index + 1) * 2);
      if (layerMeta.name !== "bg") {
        layerObject.setVisible(false);
      }

      this.backgroundImage[layerMeta.name] = layerObject;
    });

    this.unconnectedHideoutSprite = this.add
      .sprite(0, 0, "unconnectedHideout")
      .setInteractive()
      .setOrigin(0)
      .setDepth(10001);
  }

  resizeBackgroundImage() {
    this.backgroundImage.bg?.setScale(
      Math.max(
        this.cameras.main.width /
          this.cameras.main.zoom /
          this.backgroundImage.bg.width,
        this.cameras.main.height /
          this.cameras.main.zoom /
          this.backgroundImage.bg.height
      )
    );
  }

  getDefaultCameraPosition() {
    return {
      x:
        Math.abs(
          this.cameras.main.width - (this.backgroundImage.layouttop?.width || 0)
        ) / 2,
      y:
        Math.abs(
          this.cameras.main.height -
            (this.backgroundImage.layouttop?.height || 0)
        ) / 2,
    };
  }

  setDefaultCameraPosition() {
    /**
     * TODO: Should handle differently
     */
    if (this.userZoom) {
      return;
    }

    const { x: cameraX, y: cameraY } = this.getDefaultCameraPosition();

    // Set camera to center
    this.cameras.main.scrollX = cameraX;
    this.cameras.main.scrollY = cameraY;
  }

  setDefaultZoom() {
    /**
     * TODO: Should handle differently
     */
    if (this.userZoom) {
      return;
    }

    const mapWidthPx = this.backgroundImage.layouttop?.width || 0;
    const mapHeightPx = this.backgroundImage.layouttop?.height || 0;
    const cameraWidth = this.cameras.main.width;
    const cameraHeight = this.cameras.main.height;

    this.cameras.main.zoom = Math.min(
      cameraWidth / mapWidthPx,
      cameraHeight / mapHeightPx
    );

    this.resizeBackgroundImage();
  }

  updateExplorerList(explorerIds: string[]) {
    // Remove explorers
    this.explorers = this.explorers
      .map((explorer) => {
        if (!explorerIds.includes(explorer.lootId)) {
          explorer.destroy(true);
          return undefined;
        }
        return explorer;
      })
      .filter((explorer) => explorer) as ExplorerObject[];

    // Create explorers
    const explorerIdsToCreate = explorerIds.filter(
      (_id) => !this.explorers.find((explorer) => explorer.lootId === _id)
    );

    explorerIdsToCreate.forEach((explorerId, index) => {
      const explorerObject = this.add.existing(
        new ExplorerObject(this, explorerId)
      );
      this.explorers.push(explorerObject);
    });
  }

  updateLayer(layerName: BackgroundLayer, layerIndex: number, color?: string) {
    const layer = this.backgroundImage[layerName];

    if (!layer) {
      return;
    }

    layer.updateLayerIndex(layerIndex, color);
  }

  setConnectedState(isConnected: boolean) {
    this.unconnectedHideoutSprite?.setVisible(!isConnected);
    (Object.keys(this.backgroundImage) as BackgroundLayer[])
      .filter((layer) => layer !== "bg")
      .forEach((layer) => this.backgroundImage[layer]?.setVisible(isConnected));
  }

  updateState(stateKey: string, state: any) {
    switch (stateKey) {
      case "mode":
        switch (state) {
          case undefined:
          case "edit":
            this.explorers.forEach((explorer) =>
              explorer.setEditMode(state === "edit")
            );
            // Set all layers edit mode
            (Object.keys(this.backgroundImage) as BackgroundLayer[]).map(
              (layer) =>
                this.backgroundImage[layer]!.setEditMode(state === "edit")
            );
            break;
        }
        break;
    }
  }

  setModalShow(key: string, show: boolean) {
    this.showedModal[key] = show;

    const shouldPause = Boolean(
      Object.values(this.showedModal).find((show) => show)
    );

    if (shouldPause) this.scene.pause();
    else this.scene.resume();
  }

  addStopPropagationLayer(key: string, pos: PositionMeta) {
    if (this.stopPropagationLayers[key]) {
      this.removeStopPropagationLayer(key);
    }

    const layerObject = this.add.existing(
      new StopPropagationGameObject(this, pos)
    );
    layerObject.setInteractive();
    this.stopPropagationLayers[key] = layerObject;
  }

  removeStopPropagationLayer(key: string) {
    const layer = this.stopPropagationLayers[key];
    if (layer) layer.destroy(true);
    delete this.stopPropagationLayers[key];
  }
}
