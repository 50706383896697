import _idToExplorerMetadata from "../data/explorerGeneratedMetadata.json";
import { IdToExplorerMetadata } from "../model/loot";

const idToExplorerMetadata = _idToExplorerMetadata as IdToExplorerMetadata;

export const getExplorerMetadata = (explorerId: string) =>
  idToExplorerMetadata[explorerId];

export const getExplorerFullName = (explorerId: string) => {
  const metadata = getExplorerMetadata(explorerId);

  return `${metadata.firstName} ${metadata.lastName}`;
};

export const getOriginColor = (origin: string) => {
  switch (origin) {
    case "Apocalypse":
      return "#37a77f";
    case "Beast":
      return "#f3846e";
    case "Colossal":
      return "#eaae83";
    case "Despair":
      return "#f5f2ef";
    case "Luminescence":
      return "#f4dd7a";
    case "Psychic":
      return "#f5999a";
    case "Rage":
      return "#d14a4a";
    case "Skeleton":
      return "#b396cb";
    case "Storm":
      return "#485f7f";
    default:
      return "#726575";
  }
};
