import { BackgroundLayer } from "./hideout";

export interface OwnedExplorersData {
  account?: string;
  ownedExplorers: string[];
  loading: boolean;
}

export const initialOwnedExplorersData: OwnedExplorersData = {
  ownedExplorers: [],
  loading: true,
};

export const defaultOwnedExplorersData: OwnedExplorersData = {
  ownedExplorers: [],
  loading: false,
};

export interface Web3Data {
  ownedExplorersData: OwnedExplorersData;
}

export const initialWeb3Data: Web3Data = {
  ownedExplorersData: initialOwnedExplorersData,
};

export interface PersonalizedData {
  account: string;
  profileImage?: string;
  bannerProfileImage?: string;
  displayExplorers: Array<string>;
  name: string;
  squadName: string;
  hideoutLayer: Partial<{ [layer in BackgroundLayer]: number }>;
  hideoutLayerColor: Partial<{ [layer in BackgroundLayer]: string }>;
}
