export const directions = [
  "northWest",
  "northEast",
  "southEast",
  "southWest",
] as const;
export type Direction = typeof directions[number];

export const headIndexes = [1, 2, 3, 4] as const;
export type HeadIndex = typeof headIndexes[number];

export const DirectionHeadIndexMap: { [direction in Direction]: HeadIndex } = {
  southWest: 1,
  southEast: 2,
  northEast: 3,
  northWest: 4,
};

export const faceCodes = ["a", "b", "c", "d"] as const;
export type FaceCode = typeof faceCodes[number];
export const requiredFaceCodes: FaceCode[] = ["a", "b"];

export const hasCDFaceCodes = (face: string) =>
  ["CHARACTER_FACE_golem_2", "CHARACTER_FACE_golem_4"].includes(face);

export const DirectionToFaceCodeMap: { [direction in Direction]: FaceCode } = {
  southWest: "a",
  southEast: "b",
  northEast: "c",
  northWest: "d",
};

export type DirectionMeta = {
  x: number;
  y: number;
  direction: Direction;
  opposite: Direction;
};

export const directionsMap: {
  [direction in Direction]: DirectionMeta;
} = {
  northWest: {
    x: -2,
    y: -1,
    direction: "northWest",
    opposite: "southEast",
  },
  northEast: {
    x: 2,
    y: -1,
    direction: "northEast",
    opposite: "southWest",
  },
  southEast: {
    x: 2,
    y: 1,
    direction: "southEast",
    opposite: "northWest",
  },
  southWest: {
    x: -2,
    y: 1,
    direction: "southWest",
    opposite: "northEast",
  },
} as const;

export type Coordinate = { x: number; y: number };

/**
 * Boundary composition are as follow
 * b[0] represent top coordinate in the boundary
 * b[1] represent bottom coordinate in the boundary
 */
export type Boundary = [Coordinate, Coordinate];
