import React, { useMemo } from "react";

import { defaultScreenshotModal, useGlobalState } from "../../store/store";
import BasicModal from "../Common/BasicModal";
import useScreenSize from "../../hooks/useScreenSize";
import styled from "styled-components";
import { colors } from "../../designSystem/colors";
import { clickableShrunk } from "../Common/style";
import sizes from "../../utils/sizes";
import { Title, PrimaryText } from "../../designSystem";
import { playSecondaryClickSound } from "../../utils/sound";

const SSImageContainer = styled.div`
  display: flex;
  padding: 16px 16px 24px 16px;
  background: ${colors.modalContentBackground};
  border: 2px solid ${colors.hoverTintBrown}D4;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const OptionButton = styled.div.attrs({
  onMouseDown: playSecondaryClickSound,
})`
  ${clickableShrunk}
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  padding: 8px 32px;
  background-color: ${colors.modalContentBackground};
  border: 1px solid ${colors.primaryRed};
  border-radius: 100px;
`;

const ScreenshotModal: React.FC = () => {
  const [screenshotModal, setScreenshotModal] =
    useGlobalState("screenshotModal");
  const { width } = useScreenSize();

  const showModal = useMemo(
    () => Boolean(screenshotModal.show && screenshotModal.image),
    [screenshotModal]
  );

  const [modalWidth, modalHeight, imageHeight] = useMemo(() => {
    if (width >= sizes.xxl) {
      return [1286, 676, 461];
    }

    if (width >= sizes.lg) {
      return [868, 518, 300];
    }

    if (width >= sizes.md) {
      return [700, 418, 200];
    }

    return [336, 396, 200];
  }, [width]);

  return (
    <BasicModal
      name="screenshot"
      show={showModal}
      onClose={() => setScreenshotModal(defaultScreenshotModal)}
      height={modalHeight}
      maxWidth={modalWidth}
    >
      <div className="d-flex flex-column align-items-center w-100 my-auto">
        <Title fontSize="32px" lineHeight="40px" normalCased>
          Looking Good!
        </Title>
        <SSImageContainer style={{ height: imageHeight }} className="mt-3">
          <img src={screenshotModal.image} alt="screenshot" />
        </SSImageContainer>
        <div className="d-flex w-100 mt-3 justify-content-evenly">
          <OptionButton
            onClick={() => {
              if (screenshotModal.image) {
                const a = document.createElement("a");
                a.setAttribute("download", "screenshot.png");
                a.setAttribute("href", screenshotModal.image);
                a.click();
              }
            }}
          >
            <PrimaryText fontSize="16px" lineHeight="24px">
              download
            </PrimaryText>
          </OptionButton>
          <OptionButton
            onClick={() => {
              setScreenshotModal(defaultScreenshotModal);
            }}
          >
            <PrimaryText fontSize="16px" lineHeight="24px">
              close
            </PrimaryText>
          </OptionButton>
        </div>
      </div>
    </BasicModal>
  );
};

export default ScreenshotModal;
