import { doc, getDoc } from "firebase/firestore";
import { getBlob, ref } from "firebase/storage";
import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

import ArrowLeftImage from "../../assets/icons/arrow-left.webp";
import ArrowRightImage from "../../assets/icons/arrow-right.webp";
import MailboxButtonImg from "../../assets/icons/mailbox.png";
import MailboxButtonUnreadImg from "../../assets/icons/mailbox-unread.png";
import { firestore, storage } from "../../utils/firebase";
import useScreenSize from "../../hooks/useScreenSize";
import { FirestoreMailboxPosterConfigs } from "../../model/firestore";
import { playClickSound, playSecondaryClickSound } from "../../utils/sound";
import { clickableShrunk } from "../Common/style";
import { hashObject } from "../../utils/hash";
import {
  getOpenedMailboxHash,
  updateOpenedMailboxHash,
} from "../../utils/storage";

const POSTER_ASPECT_RATIO = 2293 / 3339;
const POSTER_OFFSET_PERCENTAGE = 0.02;

const MailboxContainer = styled(motion.div)`
  position: absolute;
  top: 2vh;
  right: 2vh;
  display: flex;
`;

const MailboxButton = styled.img.attrs({
  onMouseDown: playClickSound,
})`
  ${clickableShrunk}
  height: 6vh;
`;

const StyledModal = styled(Modal)`
  background-color: rgba(0, 0, 0, 0.6);

  .modal-dialog {
    width: 95vw;
    max-width: unset;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-content {
    overflow: visible;
    background-color: transparent;
    border: none;
  }
`;

const PosterDiv = styled(motion.div)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;

const PosterImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const MailboxPoster: React.FC = () => {
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(0);
  const { height, width } = useScreenSize();
  const [posterImages, setPosterImages] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(true);
  const [currentMailboxHash, setCurrentMailboxHash] = useState<string>();
  const [openedMailboxHash, setOpenedMailboxHash] = useState<string>();

  useEffect(() => {
    if (!show) {
      setPage(0);
    }
  }, [show]);

  const posterImgSize = useMemo(() => {
    const maxWidth = width * 0.4; // Maximum width of poster is 40% of screen width
    const maxHeight = height * 0.9; // Maximum height of poster is 90% of screen height
    const maxAspectRatioWidth = maxHeight * POSTER_ASPECT_RATIO;
    const posterWidth =
      maxAspectRatioWidth < maxWidth ? maxAspectRatioWidth : maxWidth;
    const posterHeight = posterWidth / (2293 / 3339);

    return {
      width: parseFloat(posterWidth.toFixed(2)),
      height: parseFloat(posterHeight.toFixed(2)),
    };
  }, [height, width]);

  const digestMailboxHash = useCallback(
    async (configs: FirestoreMailboxPosterConfigs) => {
      setCurrentMailboxHash(await hashObject(configs));
    },
    []
  );

  const preloadPosterImageData = useCallback(async () => {
    const adminConfigSnapshot = await getDoc(doc(firestore, "admin", "config"));
    const posterConfig = (adminConfigSnapshot.data()?.hideoutPosterConfig ||
      []) as FirestoreMailboxPosterConfigs;

    digestMailboxHash(posterConfig);

    const posterImages = await Promise.all(
      posterConfig.map(async (item) => {
        const imageRef = ref(storage, item.storageRef);

        // Wrap the request in a Promise
        const blob = await getBlob(imageRef);

        return URL.createObjectURL(blob);
      })
    );

    setPosterImages(posterImages);
    setLoading(false);
  }, [digestMailboxHash]);

  useEffect(() => {
    preloadPosterImageData();
  }, [preloadPosterImageData]);

  useEffect(() => {
    setOpenedMailboxHash(getOpenedMailboxHash() || "");
  }, []);

  const calculatePosterLeft = useCallback(
    (posterIndex: number) => {
      if (page === 0) {
        return `calc(50% + ${
          posterImgSize.width / -2 +
          posterIndex * posterImgSize.width * POSTER_OFFSET_PERCENTAGE
        }px)`;
      }

      const posterMargin = (posterImgSize.width * 0.01) / 2;

      if (page > posterIndex) {
        const leftOffset =
          -posterImgSize.width -
          posterMargin +
          posterIndex * posterImgSize.width * POSTER_OFFSET_PERCENTAGE;
        return `calc(50% + ${leftOffset}px)`;
      }

      const leftOffset =
        posterMargin +
        posterIndex * posterImgSize.width * POSTER_OFFSET_PERCENTAGE;
      return `calc(50% + ${leftOffset}px)`;
    },
    [page, posterImgSize]
  );

  const mailboxButton = useMemo(() => {
    if (loading || posterImages.length <= 0) {
      return null;
    }

    return (
      <MailboxContainer
        initial={{ x: -200, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
      >
        <MailboxButton
          src={
            currentMailboxHash === openedMailboxHash
              ? MailboxButtonImg
              : MailboxButtonUnreadImg
          }
          onMouseDown={playClickSound}
          onClick={() => {
            setShow(true);
            setOpenedMailboxHash(
              updateOpenedMailboxHash(currentMailboxHash || "")
            );
          }}
        />
      </MailboxContainer>
    );
  }, [currentMailboxHash, loading, openedMailboxHash, posterImages.length]);

  const leftArrow = useMemo(() => {
    if (page <= 0) {
      return null;
    }

    return (
      <motion.img
        role="button"
        className="clickable"
        key="left"
        src={ArrowLeftImage}
        initial={{ opacity: 0, width: 0 }}
        animate={{
          opacity: 1,
          position: "absolute",
          width: posterImgSize.width * 0.15,
          right: `calc(50% + ${
            posterImgSize.width - posterImgSize.width * 0.04
          }px)`,
        }}
        exit={{ opacity: 0, width: 0 }}
        transition={{
          type: "keyframes",
        }}
        onMouseDown={playSecondaryClickSound}
        onClick={() => setPage((prev) => prev - 1)}
      />
    );
  }, [page, posterImgSize.width]);

  const rightArrow = useMemo(() => {
    if (page >= posterImages.length - 1) {
      return null;
    }

    return (
      <motion.img
        role="button"
        className="clickable"
        key="right"
        src={ArrowRightImage}
        initial={{ opacity: 0, width: 0 }}
        animate={{
          opacity: 1,
          position: "absolute",
          width: posterImgSize.width * 0.15,
          left: `calc(50% + ${
            (posterImages.length - 3) *
              posterImgSize.width *
              POSTER_OFFSET_PERCENTAGE +
            (page === 0 ? posterImgSize.width / 2 : posterImgSize.width)
          }px)`,
        }}
        exit={{ opacity: 0, width: 0 }}
        transition={{
          type: "keyframes",
        }}
        onMouseDown={playSecondaryClickSound}
        onClick={() => setPage((prev) => prev + 1)}
      />
    );
  }, [page, posterImages, posterImgSize.width]);

  return (
    <>
      <AnimatePresence>{mailboxButton}</AnimatePresence>
      <StyledModal show={show} centered onHide={() => setShow(false)}>
        <Modal.Body>
          <div className="d-flex position-relative align-items-center justify-content-center">
            {posterImages.map((posterImage, index) => (
              <PosterDiv
                key={index}
                initial={{ height: 0, width: 0 }}
                animate={{
                  opacity: 1,
                  position: "absolute",
                  left: calculatePosterLeft(index),
                  zIndex: -index,
                  width: posterImgSize.width,
                  height: posterImgSize.height,
                }}
                transition={{
                  type: "keyframes",
                }}
              >
                <PosterImg src={posterImage} />
              </PosterDiv>
            ))}
            {leftArrow}
            {rightArrow}
          </div>
        </Modal.Body>
      </StyledModal>
    </>
  );
};

export default MailboxPoster;
