import { useEffect } from "react";

import { GlobalStoreKey, useGlobalState } from "../store/store";

const useGlobalStateListener = (
  globalStateKey: GlobalStoreKey,
  callback: (state: any) => void
) => {
  const [state] = useGlobalState(globalStateKey);

  useEffect(() => {
    callback(state);
  }, [callback, state]);
};

export default useGlobalStateListener;
