export const colors = {
  pale: "#FFF7E1",
  background: "#FFF0D1",
  modalBackground: "#ffe3a4",
  modalContentBackground: "#FFF2E9",
  hoverTintBrown: "#FEC36F",
  lightGreen: "#E8FFDA",
  green: "#A6C698",
  darkGreen: "#899E7D",
  lightBrown: "#6e664c",
  brown: "#6A585A",
  brown2: "#8e7f5e",
  purple: "#605E72",
  red: "#CC2936",
  primaryRed: "#fc5b49",
  gray: "#746F72",
  lightGray2: "#e7e8dd",
  lightGray: "#DBD3D8",
  blue: "#072AC8",
  lightBlue: "#84D2F6",
  puzzleUnsolved: "#C8D7CC",
  puzzleSolved: "#F8F0D7",
  soupBg: "#FFF6D3",
  highlightText: "#4FB1C5",
  shadowRed: "#fa5a48",
};
