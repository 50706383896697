import ApocalypseLogo from "./apoc.png"
import BeastLogo from "./beast.png"
import ColossalLogo from "./collo.png"
import DefaultLogo from "./default.png"
import DespairLogo from "./desp.png"
import LuminescenceLogo from "./lumi.png"
import PsychicLogo from "./psych.png"
import RageLogo from "./rage.png"
import SkeletonLogo from "./skele.png"
import StormLogo from "./storm.png"

export const getOriginsLogoSrc = (allegiance: string) => {
  switch (allegiance) {
    case "Skeleton":
      return SkeletonLogo
    case "Default":
      return DefaultLogo
    case "Storm":
      return StormLogo
    case "Luminescence":
      return LuminescenceLogo
    case "Apocalypse":
      return ApocalypseLogo
    case "Beast":
      return BeastLogo
    case "Despair":
      return DespairLogo
    case "Psychic":
      return PsychicLogo
    case "Rage":
      return RageLogo
    case "Colossal":
      return ColossalLogo
  }

  return ""
}
