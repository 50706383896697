import { useCallback, useEffect, useState } from "react";
import axios from "axios";

import {
  defaultOwnedExplorersData,
  initialOwnedExplorersData,
  OwnedExplorersData,
} from "../model/state";
import { SUBGRAPH_URL } from "../config/env";
import useWeb3Wallet from "./useWeb3Wallet";

const useFetchOwnedExplorers = (): OwnedExplorersData => {
  const { account } = useWeb3Wallet();

  const [data, setData] = useState<OwnedExplorersData>(
    initialOwnedExplorersData
  );
  const [, setMulticallCounter] = useState<number>(0);

  const fetchOwnedExplorers = useCallback(async () => {
    // Set to default data if account doesnt exists
    if (!account) {
      return setData(defaultOwnedExplorersData);
    }

    /**
     * We keep track with counter so to make sure we always only update with the latest info
     */
    let currentCounter: number;
    setMulticallCounter((counter) => {
      currentCounter = counter + 1;

      setData((prev) => ({
        ...prev,
        loading: true,
      }));

      return currentCounter;
    });

    // Query for owned explorers
    const query = `
    {
      owner(id: "${account.toLowerCase()}") {
        id
        explorers {
          id
        }
      }
    }`;
    const response = await axios.post(SUBGRAPH_URL, {
      query,
    });

    const ownerData = response.data.data.owner;

    const ownedExplorers: string[] = ownerData
      ? ownerData.explorers.map((item: { id: string }) => item.id)
      : [];

    setMulticallCounter((counter) => {
      if (counter === currentCounter) {
        setData((prev) => ({
          ...prev,
          account,
          ownedExplorers,
          loading: false,
        }));
      }

      return counter;
    });
  }, [account]);

  useEffect(() => {
    fetchOwnedExplorers();
  }, [fetchOwnedExplorers]);

  return data;
};

export default useFetchOwnedExplorers;
