import { Coordinate } from "../model/direction";

/**
 * Assumed tile width and tile height for isometric map.
 * Fixed value are utilized due to nature of project which does not
 */
const TILE_WIDTH = 2;
const TILE_HEIGHT = 1;

/**
 * Converting pixel position in isometric render to representative
 * position in orthographic projection.
 * @param position Coordinate of the pixel in isometric render
 * @returns Representative coordinate in orthographic projection
 */
export const convertIsometricToOrthographic = (
  position: Coordinate
): Coordinate => ({
  x: (position.x / (TILE_WIDTH / 2) + position.y / (TILE_HEIGHT / 2)) / 2,
  y: (position.y / (TILE_HEIGHT / 2) - position.x / (TILE_WIDTH / 2)) / 2,
});

export const convertOrthographicToIsometric = (position: Coordinate) => ({
  x: (position.x - position.y) * (TILE_WIDTH / 2),
  y: (position.x + position.y) * (TILE_HEIGHT / 2),
});
