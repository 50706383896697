import React, { ReactElement, useContext } from "react";

import { initialWeb3Data, Web3Data } from "../model/state";
import useFetchOwnedExplorers from "./useFetchOwnedExplorers";

export const Web3DataContext = React.createContext<Web3Data>(initialWeb3Data);

export const useOwnedExplorers = () =>
  useContext(Web3DataContext).ownedExplorersData;

export const Web3DataContextProvider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const ownedExplorersData = useFetchOwnedExplorers();

  return (
    <Web3DataContext.Provider value={{ ownedExplorersData }}>
      {children}
    </Web3DataContext.Provider>
  );
};
