import React, { useMemo } from "react";
import styled from "styled-components";
import { CrossIcon, EditFeather } from "../../../assets/icons";

import { API_BASE_URL } from "../../../config/constants";
import { PrimaryText, SecondaryText, Title } from "../../../designSystem";
import { colors } from "../../../designSystem/colors";
import { usePersonalizedData } from "../../../hooks/personalizedDataContext";
import { useDisplayExplorers } from "../../../hooks/useDisplayExplorers";
import { LoadingText } from "../../../hooks/useLoadingText";
import { getExplorerFullName } from "../../../utils/explorers";
import { clickableShrunk } from "../../Common/style";
import TitleFlag from "../../Common/TitleFlag";
import { useOwnedExplorers } from "../../../hooks/web3DataContext";
import { playSecondaryClickSound } from "../../../utils/sound";

const ExplorerContainer = styled.div.attrs({
  onMouseDown: playSecondaryClickSound,
})`
  display: flex;
  flex-direction: column;
  position: relative;

  &:not(:last-child) {
    margin-right: 3.2vh;
  }
`;

const ExplorerImgContainer = styled.div`
  position: relative;
  width: 10vh;
  height: 10vh;
`;

const AbsoluteCenteredContainer = styled.div.attrs({
  className:
    "position-absolute d-flex align-items-center justify-content-center h-100 w-100",
})``;

const ExplorerImgCircleShadow = styled.div`
  width: 10vh;
  height: 10vh;
  border-radius: 10vh;
  transform: translateY(0.3vh);

  background: ${colors.primaryRed};
`;

const ExplorerImg = styled.div<{
  explorerImg: string;
}>`
  position: relative;
  overflow: hidden;
  width: 10vh;
  height: 10vh;
  border-radius: 10vh;
  border: 0.1vh solid ${colors.primaryRed};

  background: ${colors.modalContentBackground};

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url(${(props) => props.explorerImg});
    background-size: cover;
    transform: scale(1.4);
    transform-origin: top;
  }
`;

const ExplorerHoverContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  transition: 0.2s opacity ease;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
`;

const ExplorerHoverImgTint = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10vh;
  height: 10vh;
  border: 1px solid ${colors.primaryRed};
  border-radius: 10vh;

  background: ${colors.hoverTintBrown}D4;
`;

const ExplorerAddContainer = styled.div.attrs({
  onMouseDown: playSecondaryClickSound,
})`
  ${clickableShrunk}

  display: flex;
  align-items: center;
  justify-content: center;
  width: 10vh;
  height: 10vh;
  background: ${colors.primaryRed};
  border-radius: 10vh;
`;

interface ExplorersSquadContentProps {
  onEditSquad: () => void;
  onEditName: () => void;
}

const ExplorersSquadContent: React.FC<ExplorersSquadContentProps> = ({
  onEditSquad,
  onEditName,
}) => {
  const { displayExplorers, loading: displayExplorersLoading } =
    useDisplayExplorers();
  const { name, loading: personalizedDataLoading } = usePersonalizedData();
  const { ownedExplorers, loading: ownedExplorersLoading } =
    useOwnedExplorers();

  const displayExplorersContent = useMemo(() => {
    if (displayExplorersLoading || ownedExplorersLoading) {
      return (
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <SecondaryText color={colors.primaryRed}>
            <LoadingText />
          </SecondaryText>
        </div>
      );
    }

    if (ownedExplorers.length <= 0) {
      return (
        <div className="d-flex flex-column w-75 h-100 align-items-center justify-content-center text-center mx-auto">
          <SecondaryText
            fontSize="1.6vh"
            lineHeight={1.5}
            color={colors.primaryRed}
            style={{ paddingBottom: "3.2vh" }}
          >
            You do not own any Explorers in your wallet, recruit one to your
            team here:{" "}
            <u
              role="button"
              className="clickable"
              onClick={() => {
                window.open(
                  "https://opensea.io/collection/loot-explorer",
                  "_blank"
                );
              }}
            >
              Opensea
            </u>
          </SecondaryText>
        </div>
      );
    }

    if (!displayExplorers || displayExplorers.length <= 0) {
      return (
        <div className="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
          <SecondaryText
            fontSize="1.6vh"
            lineHeight={1.5}
            color={colors.primaryRed}
          >
            There is currently no explorer in the squad
          </SecondaryText>
          <SecondaryText
            fontSize="1.6vh"
            lineHeight={1.5}
            color={colors.primaryRed}
            style={{ marginTop: "0.8vh", paddingBottom: "3.2vh" }}
            role="button"
            className="clickable"
            onClick={onEditSquad}
          >
            <u>Edit squad</u>
          </SecondaryText>
        </div>
      );
    }

    return (
      <>
        {displayExplorers.map((explorerId) => (
          <ExplorerContainer
            key={explorerId}
            role="button"
            className="clickable"
            onClick={onEditSquad}
          >
            {/* Img */}
            <ExplorerImgContainer>
              <AbsoluteCenteredContainer>
                <ExplorerImgCircleShadow />
              </AbsoluteCenteredContainer>

              <AbsoluteCenteredContainer>
                <ExplorerImg
                  explorerImg={`${API_BASE_URL}/explorers-transparent/${explorerId}?size=small`}
                />
              </AbsoluteCenteredContainer>
            </ExplorerImgContainer>

            {/* Name */}
            <PrimaryText
              className="text-center"
              style={{ marginTop: "1.6vh" }}
              fontSize="1.4vh"
            >
              {getExplorerFullName(explorerId)}
            </PrimaryText>

            {/* Hover Container */}
            <ExplorerHoverContainer>
              <ExplorerHoverImgTint>
                <EditFeather width="4.2vh" height="4.2vh" />
              </ExplorerHoverImgTint>
            </ExplorerHoverContainer>
          </ExplorerContainer>
        ))}
        {displayExplorers.length < 5 && (
          <ExplorerAddContainer onClick={onEditSquad}>
            <CrossIcon color={colors.modalBackground} />
          </ExplorerAddContainer>
        )}
      </>
    );
  }, [
    displayExplorers,
    displayExplorersLoading,
    onEditSquad,
    ownedExplorers.length,
    ownedExplorersLoading,
  ]);

  return (
    <>
      <div
        className="d-flex w-100 justify-content-center"
        style={{ marginTop: "2.4vh" }}
      >
        <TitleFlag onEditPressed={onEditName}>
          <Title fontSize="1.5vh">
            {personalizedDataLoading ? <LoadingText /> : name}
          </Title>
        </TitleFlag>
      </div>

      <div
        className="d-flex w-100 justify-content-center"
        style={{ marginTop: "3.2vh" }}
      >
        <div className="d-flex" style={{ width: "62.8vh", height: "16.5vh" }}>
          {displayExplorersContent}
        </div>
      </div>
    </>
  );
};

export default ExplorersSquadContent;
