import React from "react";
import styled from "styled-components";
import { useGlobalState } from "../../store/store";

const ImpersonateContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

const Impersonate = () => {
  const [, setShowImpersonateModal] = useGlobalState("showImpersonateModal");
  return (
    <ImpersonateContainer>
      <button onClick={() => setShowImpersonateModal((prev) => !prev)}>
        Impersonate Address
      </button>
    </ImpersonateContainer>
  );
};

export default Impersonate;
