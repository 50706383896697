import { GameObjects } from "phaser";
import { backgroundLayersMetaMap } from "../data/gameLayout/backgroundLayers";

import { BackgroundLayer } from "../model/hideout";
import { setGlobalState } from "../store/store";
import {
  getDefaultLayerName,
  getLayerDefault,
  layerCanCustomizeColor,
} from "../utils/backgroundLayer";
import HideoutScene from "../scenes/HideoutScene";
import { hexStringToNumber } from "../utils/math";

class LayoutGameObjectWithHitbox extends GameObjects.Sprite {
  hideoutScene: HideoutScene;
  layer: BackgroundLayer;
  hitboxes: GameObjects.Rectangle[];
  hitboxesHovered: boolean[];
  hidden: boolean;

  colorBackground?: GameObjects.Rectangle;
  colorBackgroundMask?: GameObjects.Sprite;

  constructor(
    scene: HideoutScene,
    layer: BackgroundLayer,
    hitBoxes: { x: number; y: number; width: number; height: number }[]
  ) {
    super(scene, 0, 0, getDefaultLayerName(layer), getDefaultLayerName(layer));
    this.hideoutScene = scene;
    this.layer = layer;
    this.hidden = getLayerDefault(layer) === -1;
    const backgroundLayerMeta = backgroundLayersMetaMap[layer];

    if (this.hidden) {
      this.setVisible(false);
    }

    if (layer === "bg") {
      // Reposition background
      this.setPosition(this.width / 2, this.height / 2);

      /**
       * Register click event onto bg for dismissing edit mode
       */
      this.on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
        /**
         * There is 2 potential bg interactive state
         * 1. Focusing explorer mode
         * 2. Background focus mode
         */

        // Check if focusing explorer, we unfocus the explorer when clicked
        if (this.hideoutScene.focusExplorer) {
          this.hideoutScene.focusExplorer.handleGameObjectClick();
          return;
        }

        // Edit mode
        setGlobalState("editModeActiveLayer", (prevActiveLayer) => {
          if (prevActiveLayer === undefined) {
            setGlobalState("mode", undefined);
          }

          return undefined;
        });
      });
    }

    this.hitboxes = hitBoxes.map(({ x, y, width, height }) =>
      this.scene.add.rectangle(x, y, width, height).setOrigin(0)
    );
    this.hitboxesHovered = hitBoxes.map(() => false);

    /**
     * Register event onto hitbox
     */
    this.hitboxes.forEach((hitbox, hitboxIndex) => {
      /**
       * Mouse hover in
       */
      hitbox.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, () => {
        this.hitboxesHovered[hitboxIndex] = true;
      });

      /**
       * Mouse hover out
       */
      hitbox.on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, () => {
        this.hitboxesHovered[hitboxIndex] = false;
      });

      /**
       * On click, this is only triggered when interactive is set to true
       */
      hitbox.on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
        this.scene.sound.play("click");
        setGlobalState("editModeActiveLayer", (prev) =>
          prev === layer ? undefined : layer
        );
      });
    });

    if (
      backgroundLayerMeta.customization?.find(
        (customizeItem) => customizeItem.type === "color"
      )
    ) {
      this.colorBackground = scene.add.rectangle(
        0,
        0,
        scene.backgroundImage.bg?.width,
        scene.backgroundImage.bg?.height,
        0x000,
        0
      );
      this.colorBackground.setOrigin(0);
      this.colorBackgroundMask = scene.make.sprite(
        {
          x: 0,
          y: 0,
          key: `${layer}colorMask`,
          frame: `${layer}_color-mask`,
        },
        false
      );
      this.colorBackground.mask = new Phaser.Display.Masks.BitmapMask(
        scene,
        this.colorBackgroundMask
      );
    }
  }

  updateLayerIndex(index: number, color?: string) {
    this.hidden = index === -1;
    this.setVisible(!this.hidden);

    if (this.hidden) {
      return;
    }

    const backgroundLayerMeta = backgroundLayersMetaMap[this.layer];
    const layerName = `${this.layer}_${index}`;

    /**
     * If layer is animation, we start animation
     */
    if (backgroundLayerMeta.animated?.[index]) {
      this.play(`layer${layerName}anims`);
      return;
    }

    /**
     * Otherwise stop any animation and change texture
     */
    this.stop();
    this.setTexture(layerName, layerName);

    /**
     * Check color customization, change color as needed
     */
    if (layerCanCustomizeColor(this.layer, index) && color) {
      this.colorBackground?.setFillStyle(hexStringToNumber(color), 1);
    } else {
      this.colorBackground?.setFillStyle(0x000, 0);
    }
  }

  update(delta: number) {
    const hovered = this.hitboxesHovered.find((hovered) => hovered);
    const hoveredEndY = -(this.scene.cameras.main.height * 0.025);

    if (hovered && this.y > hoveredEndY) {
      /**
       * If hovered and layer had not reached the end Y position,
       * we move it towards the end position
       */
      const newY = this.y + hoveredEndY * (delta / 100);
      this.setY(newY < hoveredEndY ? hoveredEndY : newY);
    } else if (!hovered && this.y < 0) {
      /**
       * We move it back when it's no longer hovered
       */
      const newY = this.y - hoveredEndY * (delta / 100);
      this.setY(newY > 0 ? 0 : newY);
    }
  }

  setEditMode(isEdit: boolean) {
    if (this.hidden) {
      return;
    }

    this.hitboxes.forEach((hitbox) =>
      isEdit ? hitbox.setInteractive() : hitbox.disableInteractive()
    );

    if (this.layer === "bg") {
      isEdit ? this.setInteractive() : this.disableInteractive();
    }
  }

  setFocusExplorerMode(isFocus: boolean) {
    if (this.layer === "bg") {
      isFocus ? this.setInteractive() : this.disableInteractive();
    }
  }

  setDepth(value: number): this {
    super.setDepth(value);
    this.colorBackground?.setDepth(value);
    this.hitboxes.forEach((hitbox) => hitbox.setDepth(value));

    return this;
  }

  setY(value: number): this {
    super.setY(value);
    this.colorBackgroundMask?.setY(value);

    return this;
  }
}

export default LayoutGameObjectWithHitbox;
