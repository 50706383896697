import { BaseProvider } from "@ethersproject/providers";
import { ethers } from "ethers";
import React, { ReactElement, useContext } from "react";

import { getNodeURI } from "../config/env";

export type Web3ContextData = {
  provider: BaseProvider;
};

// Uses default provider to get contract data when wallet is not connected.
const defaultProvider = ethers.getDefaultProvider(getNodeURI());

export const Web3Context = React.createContext<Web3ContextData>({
  provider: defaultProvider,
});

export const useWeb3Context = () => useContext(Web3Context);

export const Web3ContextProvider: React.FC<{ children: ReactElement }> = (
  props
) => {
  return (
    <Web3Context.Provider value={{ provider: defaultProvider }}>
      {props.children}
    </Web3Context.Provider>
  );
};
