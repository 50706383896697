export interface IExplorerMetadata {
  primaryType: string;
  secondaryType: string;
  subclass?: string;

  firstName: string;
  lastName: string;

  head: string;
  headName: string;
  face?: string;
  faceName?: string;
  background: string;
  backgroundFile: string;

  weaponVariation: {
    name: string;
  };
  headArmorVariation: {
    name: string;
  };
  waistArmorVariation: {
    name: string;
  };
  chestArmorVariation: {
    name: string;
  };
  allegiance: string;
  origins: string;
}

export type IdToExplorerMetadata = {
  [key: string]: IExplorerMetadata;
};

export const ExplorerMetaDisplay: { [key: string]: string } = {
  CHARACTER_HEAD_RARE_Octo1: "Kelptain Octo",
  CHARACTER_HEAD_RARE_Murk1: "Shark",
  CHARACTER_HEAD_RARE_Turnip1: "Turnip",
  CHARACTER_HEAD_RARE_Cat2: "Caracal",
  CHARACTER_HEAD_RARE_Bunny1: "Bunny",
  CHARACTER_HEAD_RARE_Golem1: "Crystalline Golem",
  CHARACTER_HEAD_RARE_Bear1: "Koala",
  CHARACTER_HEAD_RARE_Tree1: "Sakura",
  CHARACTER_HEAD_RARE_Skele1: "Ruby Commander Skele",
  CHARACTER_HEAD_RARE_Bird1: "Owl",
  CHARACTER_HEAD_RARE_Goblin1: "Elder Goblin",
  CHARACTER_HEAD_RARE_Raccoon1: "Raccoon",
  CHARACTER_HEAD_RARE_Cat1: "White Tiger",
  CHARACTER_HEAD_RARE_Evil1: "Hellbound Spirit",
  CHARACTER_HEAD_RARE_Flame1: "Molten Flame",
};
