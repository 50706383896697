import Phaser from "phaser";

import HideoutScene from "./scenes/HideoutScene";
import LoadingScene from "./scenes/LoadingScene";

const config: Phaser.Types.Core.GameConfig = {
  type: Phaser.AUTO,
  parent: "phaser-container",
  backgroundColor: "#000",
  scale: {
    mode: Phaser.Scale.ScaleModes.RESIZE,
    width: window.innerWidth,
    height: window.innerHeight,
  },
  scene: [LoadingScene, HideoutScene],
};

const PhaserGame = new Phaser.Game(config);
export default PhaserGame;
