import BeastsLogo from "./beasts.png"
import NaturalsLogo from "./nats.png"
import NoctiiLogo from "./noctii.png"
import DeepTidesLogo from "./tides.png"

export const getRaceLogoSrc = (allegiance: string) => {
  switch (allegiance) {
    case "Noctii":
      return NoctiiLogo
    case "DeepTides":
      return DeepTidesLogo
    case "Beasts":
      return BeastsLogo
    case "Naturals":
      return NaturalsLogo
  }
  return ""
}
