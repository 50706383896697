import React from "react";
import styled from "styled-components";
import { EditFeather } from "../../assets/icons";

import { TitleFlagSVG, TitleFlagSVGProps } from "../../assets/titleFlag";
import { clickableShrunk } from "./style";
import { playSecondaryClickSound } from "../../utils/sound";

const TitleFlagContainer = styled.div.attrs({
  className: "d-flex position-relative",
})`
  width: fit-content;
`;

const ContentContainer = styled.div<{}>`
  display: flex;
  position: absolute;

  width: ${(412 / 491) * 100}%;
  height: ${(55 / 74) * 100}%;
  top: 0;
  left: ${(79 / 2 / 491) * 100}%;

  align-items: center;
  justify-content: center;
`;

const EditButtonContainer = styled.div`
  ${clickableShrunk}

  display: flex;
  position: absolute;
  height: ${(17 / 72) * 100}%;
  top: ${(20 / 74) * 100}%;
  right: ${(81 / 486) * 100}%;
`;

interface TitleFlagProps {
  flagProps?: TitleFlagSVGProps;
  onEditPressed?: () => void;
}

const TitleFlag: React.FC<TitleFlagProps> = ({
  flagProps,
  onEditPressed,
  children,
}) => {
  return (
    <TitleFlagContainer>
      <TitleFlagSVG width="49.1vh" height="7.4vh" {...flagProps} />
      <ContentContainer>{children}</ContentContainer>
      {onEditPressed && (
        <EditButtonContainer
          onMouseDown={playSecondaryClickSound}
          onClick={onEditPressed}
        >
          <EditFeather height="100%" width="auto" />
        </EditButtonContainer>
      )}
    </TitleFlagContainer>
  );
};

export default TitleFlag;
