import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import BasicModal from "../Common/BasicModal";
import ModalContent from "../../assets/welcome.png";
import { colors } from "../../designSystem/colors";
import { Title } from "../../designSystem";
import { clickableScale } from "../Common/style";
import { playClickSound } from "../../utils/sound";
import { WelcomeFlag } from "../../assets/welcomeFlag";
import { isFirstLaunch, updateLastLaunchedDate } from "../../utils/storage";

const WelcomeFlagContainer = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  width: 100%;
  bottom: 56vh;
`;

const ModalContentImg = styled.img`
  max-width: 90%;
  max-height: 75%;
`;

const BeginButton = styled.div.attrs({
  className: "d-flex justify-content-center",
})`
  ${clickableScale}
  width: 40%;
  background: ${colors.hoverTintBrown};
  border: 0.1vh solid ${colors.primaryRed};
  border-radius: 0.8vh;
  padding: 0.8vh 0;
  margin-top: 3.2vh;
`;

const WelcomeModal = () => {
  const firstLaunch = isFirstLaunch();
  const [dismiss, setDismiss] = useState(false);

  const show = useMemo(() => {
    return firstLaunch && !dismiss;
  }, [dismiss, firstLaunch]);

  const onClose = useCallback(() => {
    updateLastLaunchedDate();
    setDismiss(true);
  }, []);

  return (
    <BasicModal
      name="welcomemodal"
      show={show}
      onClose={onClose}
      height="60vh"
      maxWidth="90vh"
      backgroundColor="rgba(255, 255, 255, 0.7)"
    >
      <div className="d-flex flex-column align-items-center justify-content-center h-100 w-100 position-relative">
        <WelcomeFlagContainer>
          <WelcomeFlag width="60%" height="auto" />
        </WelcomeFlagContainer>
        <ModalContentImg src={ModalContent} alt="Welcome Modal Content" />
        <BeginButton
          role="button"
          onMouseDown={playClickSound}
          onClick={onClose}
        >
          <Title fontWeight={600}>Begin</Title>
        </BeginButton>
      </div>
    </BasicModal>
  );
};

export default WelcomeModal;
