import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { backgroundLayersMeta } from "../data/gameLayout/backgroundLayers";
import { BackgroundLayer } from "../model/hideout";

import { PersonalizedData } from "../model/state";
import PhaserGame from "../PhaserGame";
import HideoutScene from "../scenes/HideoutScene";
import { useGlobalState } from "../store/store";
import {
  getLayerDefault,
  getLayerDefaultColor,
  isLayerObjectUnlocked,
} from "../utils/backgroundLayer";

import { firstSixAddressChar } from "../utils/strings";
import useSavedPersonalizedData from "./useSavedPersonalizedData";
import useSiwe from "./useSiwe";
import useWeb3Wallet from "./useWeb3Wallet";
import { useOwnedExplorers } from "./web3DataContext";

export const PersonalizedDataContext = React.createContext<{
  data?: PersonalizedData;
  setData: (newData: Partial<PersonalizedData>) => void;
  loading: boolean;
}>({ loading: true, setData: () => {} });

export const usePersonalizedData = () => {
  const { data, setData, loading } = useContext(PersonalizedDataContext);

  const updateFieldData = useCallback<
    <T extends keyof PersonalizedData>(
      field: T,
      fieldData: PersonalizedData[T]
    ) => void
  >(
    (field, fieldData) => {
      setData({ [field]: fieldData });
    },
    [setData]
  );

  const updateHideoutLayerIndex = useCallback(
    (layer: BackgroundLayer, layerIndex: number) => {
      if (!data) {
        return;
      }

      setData({
        hideoutLayer: { [layer]: layerIndex },
      });
    },
    [data, setData]
  );

  return {
    ...data,
    setData,
    updateFieldData,
    updateHideoutLayerIndex,
    loading,
  };
};

export const PersonalizedDataContextProvider: React.FC<{
  children: ReactElement;
}> = ({ children }) => {
  const { account } = useWeb3Wallet();
  const ownedExplorers = useOwnedExplorers();
  const [phaserScene] = useGlobalState("phaserScene");
  const {
    settings: savedPersonalziedData,
    syncPersonalizedData,
    loading: personalizedDataLoading,
  } = useSavedPersonalizedData(account);

  const { token } = useSiwe();
  const [, setShowSiweModal] = useGlobalState("showSiweModal");

  /**
   * Perform data validation and return the personalized data
   * that should be shown to the frontend
   */
  const personalizedData = useMemo(() => {
    /**
     * Retun undefined under following condition
     * - Account not connected
     * - Data source is loading
     * - Owned explorers still loading
     */
    if (
      !account ||
      ownedExplorers.loading ||
      personalizedDataLoading ||
      !ownedExplorers.account ||
      ownedExplorers.account !== account
    ) {
      return undefined;
    }

    /**
     * Default value
     * If there is saved value from before, we take that value as default.
     * Otherwise, the default would be the first 5 explorers owned
     */
    const _personalizedData: PersonalizedData =
      savedPersonalziedData?.account === account
        ? savedPersonalziedData
        : {
            account: ownedExplorers.account,
            displayExplorers: ownedExplorers.ownedExplorers.slice(0, 5),
            name: `Explorers Hideout ${firstSixAddressChar(account)}`,
            squadName: `Explorers Hideout ${firstSixAddressChar(
              account
            )} Squad`,
            hideoutLayer: Object.fromEntries(
              backgroundLayersMeta.map((meta) => [
                meta.name,
                getLayerDefault(
                  meta.name,
                  ownedExplorers.ownedExplorers.slice(0, 5)
                ),
              ])
            ),
            hideoutLayerColor: Object.fromEntries(
              backgroundLayersMeta
                .map((meta) =>
                  meta.customization &&
                  meta.customization.find((item) => item.type === "color")
                    ? [
                        meta.name,
                        meta.customization.find(
                          (item) => item.type === "color"
                        )!.choices[0],
                      ]
                    : undefined
                )
                .filter((entry) => entry) as Array<[BackgroundLayer, string]>
            ),
          };

    /**
     * Remove explorers that is no longer owned by the user
     */
    [..._personalizedData.displayExplorers].forEach((displayExplorerId) => {
      if (!ownedExplorers.ownedExplorers.includes(displayExplorerId)) {
        _personalizedData.displayExplorers =
          _personalizedData.displayExplorers.filter(
            (_explorerId) => _explorerId !== displayExplorerId
          );
      }
    });

    if (!_personalizedData.hideoutLayerColor) {
      _personalizedData.hideoutLayerColor = {};
    }

    /**
     * This block aims to achieve 2 things
     * - If default value not given to multi type layer, a default value is given
     * - Also override the value in the event if layer is not unlocked for the user
     * - Also add default layer color for layer with customization option
     */
    backgroundLayersMeta.forEach((meta) => {
      /**
       * Provide default index
       */
      if (
        _personalizedData.hideoutLayer[meta.name] === undefined ||
        _personalizedData.hideoutLayer[meta.name] === -1 ||
        !isLayerObjectUnlocked(
          meta.name,
          _personalizedData.hideoutLayer[meta.name]!,
          _personalizedData.displayExplorers
        )
      ) {
        _personalizedData.hideoutLayer[meta.name] = getLayerDefault(
          meta.name,
          _personalizedData.displayExplorers
        );
      }

      /**
       * Provide default color
       */
      const colorCustomizationMeta = meta.customization?.find(
        (item) => item.type === "color"
      );
      if (
        colorCustomizationMeta &&
        !_personalizedData.hideoutLayerColor[meta.name]
      ) {
        _personalizedData.hideoutLayerColor[meta.name] =
          colorCustomizationMeta.choices[0];
      }
    });

    return _personalizedData;
  }, [account, savedPersonalziedData, personalizedDataLoading, ownedExplorers]);

  /**
   * Update pesonalized data context onto phaser scene
   */
  useEffect(() => {
    if (phaserScene !== "hideout") {
      return;
    }

    const hideoutScene = PhaserGame.scene.getScene("hideout") as HideoutScene;
    if (hideoutScene) {
      // Update explorer list
      hideoutScene.updateExplorerList(
        personalizedData ? [...personalizedData.displayExplorers] : []
      );

      if (!personalizedData || ownedExplorers.ownedExplorers.length <= 0) {
        hideoutScene.setConnectedState(false);
        hideoutScene.updateLayer("bg", 0);
        return;
      }

      hideoutScene.setConnectedState(true);

      // Update hideout layer
      backgroundLayersMeta.forEach((meta) => {
        hideoutScene.updateLayer(
          meta.name,
          personalizedData?.hideoutLayer[meta.name] ||
            getLayerDefault(meta.name, personalizedData?.displayExplorers),
          personalizedData?.hideoutLayerColor[meta.name] ||
            getLayerDefaultColor(meta.name)
        );
      });
    }
  }, [ownedExplorers, personalizedData, phaserScene]);

  const updatePersonalizedData = useCallback(
    (data: Partial<PersonalizedData>) => {
      if (!token) {
        return setShowSiweModal(true);
      }

      // Sync data
      syncPersonalizedData(data);
    },
    [setShowSiweModal, syncPersonalizedData, token]
  );

  return (
    <PersonalizedDataContext.Provider
      value={{
        data: personalizedData,
        setData: updatePersonalizedData,
        loading: personalizedDataLoading || ownedExplorers.loading,
      }}
    >
      {children}
    </PersonalizedDataContext.Provider>
  );
};
