import { FirestoreDataConverter } from "firebase/firestore";
import { PersonalizedData } from "./state";

export const personalizedDataFirestoreConverter: FirestoreDataConverter<PersonalizedData> =
  {
    fromFirestore(snapshot, _options?) {
      const data = snapshot.data();
      return {
        ...data,
        ...{
          displayExplorers: [...data.displayExplorers],
        },
      } as PersonalizedData;
    },
    toFirestore(personalizedData) {
      return personalizedData;
    },
  };

export type FirestoreMailboxPosterConfig = {
  storageRef: string;
  redirectUri?: string;
};

export type FirestoreMailboxPosterConfigs = Array<FirestoreMailboxPosterConfig>;
