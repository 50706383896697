import { API_BASE_URL } from "../config/constants";
import {
  backgroundLayersMeta,
  backgroundLayersMetaMap,
} from "../data/gameLayout/backgroundLayers";
import { BackgroundLayer } from "../model/hideout";
import { IExplorerMetadata } from "../model/loot";
import { getExplorerMetadata } from "./explorers";

export const isLayerObjectUnlocked = (
  layer: BackgroundLayer,
  index: number,
  squat: Array<string>
) => {
  const layerUnlockCriteria = backgroundLayersMetaMap[layer].unlockCriteria;

  /**
   * If there is no unlock criteria, it is assumed to be unlock for all
   */
  if (!layerUnlockCriteria || !layerUnlockCriteria[index]) {
    return true;
  }

  const unlockCriteria = layerUnlockCriteria[index];

  const matchedCount = squat
    .map((explorerId) => getExplorerMetadata(explorerId))
    .reduce((acc, curr) => {
      if (
        JSON.stringify(unlockCriteria.metaValue) ===
        JSON.stringify(curr[unlockCriteria.metaName])
      ) {
        return acc + 1;
      }
      return acc;
    }, 0);

  return matchedCount >= unlockCriteria.count;
};

export const getNewlyUnlockedLayers = (
  prevSquat: Array<string>,
  currSquad: Array<string>
) => {
  const newlyUnlockedLayers: Array<{
    layer: BackgroundLayer;
    index: number;
    criteria: {
      metaName: keyof IExplorerMetadata;
      metaValue: string;
      count: number;
    };
  }> = [];

  /**
   * Loop through all backgrounds layer
   */
  backgroundLayersMeta.forEach((backgroundLayerMeta) => {
    /**
     * Loop through each unlock criteria if layer has one
     */
    if (backgroundLayerMeta.unlockCriteria) {
      Object.keys(backgroundLayerMeta.unlockCriteria)
        .map((index) => parseInt(index))
        .forEach((index) => {
          /**
           * Check if layer is not unlocked before and is unlocked now
           */
          if (
            isLayerObjectUnlocked(backgroundLayerMeta.name, index, currSquad) &&
            !isLayerObjectUnlocked(backgroundLayerMeta.name, index, prevSquat)
          ) {
            /**
             * Push meta if they are newly unlocked
             */
            newlyUnlockedLayers.push({
              layer: backgroundLayerMeta.name,
              index: index,
              criteria: backgroundLayerMeta.unlockCriteria![index],
            });
          }
        });
    }
  });
  return newlyUnlockedLayers;
};

export const getLayerDefault = (
  layer: BackgroundLayer,
  squat: Array<string> = []
): number => {
  const layerMeta = backgroundLayersMetaMap[layer];

  if (!layerMeta.unlockCriteria || !layerMeta.unlockCriteria[0]) {
    return 0;
  }

  return [...new Array(layerMeta.count)].findIndex((_, index) =>
    isLayerObjectUnlocked(layer, index, squat)
  );
};

export const getDefaultLayerName = (layer: BackgroundLayer) => {
  const defaultLayerIndex = getLayerDefault(layer);
  return `${layer}_${defaultLayerIndex === -1 ? 0 : defaultLayerIndex}`;
};

export const getLayerPreviewImageURL = (
  layer: BackgroundLayer,
  layerIndex: number,
  color?: string
): string => {
  const meta = backgroundLayersMetaMap[layer];

  const customization =
    meta.customization?.filter((customizeItem) =>
      customizeItem.layerIndex.includes(layerIndex)
    ) || [];

  if (customization.length === 0) {
    return `/assets/background/preview/${layer}_${layerIndex}.png`;
  }

  /**
   * TODO: This part assume color is the only customization allowed. However, the structure
   * are made to accomodate more customization than just color. When there is more type of
   * customization, this needed to be changed to accomodate that.
   */
  return `${API_BASE_URL}/hideout/preview?layer=${layer}&layerIndex=${layerIndex}&colorHex=${(
    color || customization[0].choices[0]
  ).replace("#", "")}`;
};

export const getLayerPreviewPlaceholderImageURL = (
  layer: BackgroundLayer,
  layerIndex: number
): string | undefined => {
  if (layerCanCustomizeColor(layer, layerIndex)) {
    return `/assets/background/preview/${layer}_${layerIndex}-placeholder.png`;
  }
};

export const layerCanCustomizeColor = (layer: BackgroundLayer, index: number) =>
  Boolean(
    backgroundLayersMetaMap[layer].customization
      ?.find((item) => item.type === "color")
      ?.layerIndex.includes(index)
  );

export const getLayerDefaultColor = (layer: BackgroundLayer): string => {
  const colorCustomization = backgroundLayersMetaMap[layer].customization?.find(
    (item) => item.type === "color"
  );

  return colorCustomization?.choices[0] || "#000000";
};
