import { useCallback } from "react";

import { backgroundLayersMetaMap } from "../data/gameLayout/backgroundLayers";
import { BackgroundLayer } from "../model/hideout";
import { usePersonalizedData } from "./personalizedDataContext";

const useHideoutLayerCustomization = () => {
  const { hideoutLayer, hideoutLayerColor, updateHideoutLayerIndex, setData } =
    usePersonalizedData();

  const setHideoutLayerIndex = useCallback(
    (layerName: BackgroundLayer, layerIndex: number) => {
      const layerMeta = backgroundLayersMetaMap[layerName];

      /**
       * Data validation
       */
      if (layerIndex >= layerMeta.count) {
        return;
      }

      updateHideoutLayerIndex(layerName, layerIndex);
    },
    [updateHideoutLayerIndex]
  );

  const setHideoutLayerColor = useCallback(
    (layerName: BackgroundLayer, color: string) => {
      setData({ hideoutLayerColor: { [layerName]: color } });
    },
    [setData]
  );

  return {
    hideoutLayer: hideoutLayer || {},
    hideoutLayerColor: hideoutLayerColor || {},
    setHideoutLayerIndex,
    setHideoutLayerColor,
  };
};

export default useHideoutLayerCustomization;
