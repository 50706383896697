import { GameObjects } from "phaser";

import {
  Coordinate,
  DirectionHeadIndexMap,
  directionsMap,
  DirectionToFaceCodeMap,
  hasCDFaceCodes,
  requiredFaceCodes,
} from "../model/direction";
import {
  animationTypeMeta,
  ExplorerAction,
  ExplorerOutfit,
  explorerOutfitList,
  getInitialExplorerAction,
  WALKING_BOUNDARIES,
} from "../model/explorerAction";
import HideoutScene from "../scenes/HideoutScene";
import { getIsometricDirectionFromPixel } from "../utils/direction";
import {
  generateRandomCoordinateFromBoundary,
  generateRandomizeExplorerAction,
  getExplorerActionMotionName,
  isExplorerActionEnded,
} from "../utils/explorerAction";
import { getExplorerMetadata, getOriginColor } from "../utils/explorers";
import { hexStringToNumber, randElem, randInt } from "../utils/math";
import ExplorerPortraitObject from "./ExplorerPortraitObject";

class ExplorerObject extends GameObjects.Rectangle {
  static SPEED = 70;
  static HEAD_OFFSET_X = 0;
  static HEAD_OFFSET_X_BED = 20;
  static HEAD_OFFSET_Y = -70;
  static HEAD_OFFSET_Y_BED = -55;
  static HEAD_OFFSET_Y_TOWER = -60;
  static HEAD_OFFSET_Y_WORKSPACE = -65;

  hideoutScene: HideoutScene;

  lootId: string;
  head: string;
  face?: string;
  outfit: ExplorerOutfit;

  // Metadata of Explorer
  action: ExplorerAction;

  // Extra sprite of explorer
  bodySprite: GameObjects.Sprite;
  headSprite: GameObjects.Sprite;
  faceSprite?: GameObjects.Sprite;
  pantsMask: GameObjects.Sprite;

  explorerPortraitSprite: ExplorerPortraitObject;

  overrideDepth?: number;

  constructor(scene: HideoutScene, lootId: string) {
    const initialExplorerAction = getInitialExplorerAction();
    const { x, y } = generateRandomCoordinateFromBoundary(
      randElem([...WALKING_BOUNDARIES])
    );
    const { head, face, origins } = getExplorerMetadata(lootId);
    super(
      scene,
      0,
      0,
      scene.backgroundImage.layouttop?.width,
      scene.backgroundImage.layouttop?.height,
      hexStringToNumber(getOriginColor(origins))
    );
    this.setOrigin(0);

    this.hideoutScene = scene;

    this.lootId = lootId;
    this.head = head;
    this.face = face;
    this.outfit = randElem([...explorerOutfitList]);
    this.action = initialExplorerAction;

    this.bodySprite = this.scene.add.sprite(
      x,
      y,
      `explorerBody${getExplorerActionMotionName(initialExplorerAction)}${
        this.outfit
      }`,
      `${this.outfit}_${initialExplorerAction.direction.direction}_1`
    );
    this.bodySprite.depth = y;
    this.bodySprite.setInteractive();

    /**
     * Add mask for pants
     */
    this.pantsMask = this.scene.make.sprite(
      {
        x,
        y,
        key: `explorerBody${getExplorerActionMotionName(
          initialExplorerAction
        )}pants_mask`,
        frame: `pants_mask_${initialExplorerAction.direction.direction}_1`,
      },
      false
    );
    this.pantsMask.depth = y - 1;
    this.mask = new Phaser.Display.Masks.BitmapMask(scene, this.pantsMask);

    /**
     * Add head into scene
     */
    this.headSprite = this.scene.add.sprite(
      this.bodySprite.x + this.getHeadOffsetX(),
      this.bodySprite.y + this.getHeadOffsetY(),
      "explorerHead",
      `${this.head}_${DirectionHeadIndexMap[this.action.direction.direction]}`
    );
    this.headSprite.depth = this.bodySprite.y + 1;
    this.headSprite.setInteractive();

    /**
     * Add face into scene for explorer that has seperate face
     */
    if (this.face) {
      const faceCode = DirectionToFaceCodeMap[this.action.direction.direction];

      // Create face
      this.faceSprite = this.scene.add
        .sprite(
          this.bodySprite.x + this.getHeadOffsetX(),
          this.bodySprite.y + this.getHeadOffsetY(),
          "explorerFace",
          `${this.face}_${faceCode}`
        )
        .setVisible(false);
      this.faceSprite.depth = this.bodySprite.y + 2;

      // Show the face if it needs to
      if (requiredFaceCodes.includes(faceCode) || hasCDFaceCodes(this.face)) {
        this.faceSprite.setVisible(true);
      }
    }

    /**
     * Add event listener
     */
    this.bodySprite.on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
      this.scene.sound.play("click");
      this.handleGameObjectClick();
    });
    this.headSprite.on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
      this.scene.sound.play("click");
      this.handleGameObjectClick();
    });

    /**
     * Add explorer portrait sprite into the scene
     */
    this.explorerPortraitSprite = this.scene.add.existing(
      new ExplorerPortraitObject(scene, this.lootId, x, y)
    );

    this.playAnims(getExplorerActionMotionName(this.action));
  }

  /**
   * Play animation for body sprite and pants mask
   * @param motionName Name of the motion
   */
  playAnims(motionName: string) {
    this.bodySprite.play(
      `${motionName}-${this.outfit}-${this.action.direction.direction}`
    );
    this.pantsMask.play(
      `${motionName}-pants_mask-${this.action.direction.direction}`
    );
  }

  getHeadOffsetX() {
    if (this.action.motion === "bed" && this.action.stage === "bed") {
      return ExplorerObject.HEAD_OFFSET_X_BED;
    }

    return ExplorerObject.HEAD_OFFSET_X;
  }

  getHeadOffsetY() {
    if (this.action.motion === "bed" && this.action.stage === "bed") {
      return ExplorerObject.HEAD_OFFSET_Y_BED;
    }

    if (
      this.action.motion === "scoping_tower" &&
      this.action.stage === "scoping_tower"
    ) {
      return ExplorerObject.HEAD_OFFSET_Y_TOWER;
    }

    if (
      this.action.motion === "interact_station" &&
      this.action.stage === "interact_station"
    ) {
      return ExplorerObject.HEAD_OFFSET_Y_WORKSPACE;
    }

    return ExplorerObject.HEAD_OFFSET_Y;
  }

  handleGameObjectClick() {
    let success;
    if (this.explorerPortraitSprite.visible) {
      success = this.hideoutScene.handleUnfocusExplorer();
    } else {
      success = this.hideoutScene.handleFocusExplorer(this.lootId);
    }

    if (success) {
      this.explorerPortraitSprite.toggleState();
    }
  }

  updateActionDirectionFrame() {
    switch (this.action.motion) {
      case "bed":
      case "scoping_tower":
      case "interact_station":
        if (this.action.stage === "walkIn" || this.action.stage === "walkOut") {
          this.playAnims("walk");
          break;
        }

        this.playAnims(getExplorerActionMotionName(this.action));

        break;
      default:
        this.playAnims(getExplorerActionMotionName(this.action));
    }

    this.headSprite.setFrame(
      `${this.head}_${DirectionHeadIndexMap[this.action.direction.direction]}`
    );
    if (this.face) {
      const faceCode = DirectionToFaceCodeMap[this.action.direction.direction];
      if (requiredFaceCodes.includes(faceCode) || hasCDFaceCodes(this.face)) {
        this.faceSprite?.setFrame(`${this.face}_${faceCode}`).setVisible(true);
      } else {
        this.faceSprite?.setVisible(false);
      }
    }
  }

  walk(delta: number) {
    if (
      !(
        this.action.motion === "walk" ||
        ((this.action.motion === "bed" ||
          this.action.motion === "scoping_tower" ||
          this.action.motion === "interact_station") &&
          (this.action.stage === "walkIn" || this.action.stage === "walkOut"))
      )
    ) {
      return;
    }

    const newX =
      this.bodySprite.x +
      this.action.direction.x * ExplorerObject.SPEED * (delta / 1000);
    const newY =
      this.action.direction.y === 0
        ? this.bodySprite.y
        : this.bodySprite.y +
          this.action.direction.y * ExplorerObject.SPEED * (delta / 1000);

    const currDistance = Phaser.Math.Distance.Between(
      this.bodySprite.x,
      this.bodySprite.y,
      newX,
      newY
    );

    if (currDistance > this.action.distanceRemaining) {
      /**
       * When the explorer reach the end of the length,
       * we set the x and y to the destination and set the next walk to be in progress
       */
      this.action.distanceRemaining = 0;
      this.bodySprite.x = this.action.next[0].x;
      this.bodySprite.y = this.action.next[0].y;

      /**
       * Remove the done action from the queue
       */
      this.action.next = this.action.next.filter((_, index) => index !== 0);

      /**
       * Calculate distance remaining to next goal.
       * Also update the new direction along with the sprite that needed to be updated
       */
      if (this.action.next.length > 0) {
        this.action.direction =
          directionsMap[
            getIsometricDirectionFromPixel(
              { x: this.bodySprite.x, y: this.bodySprite.y },
              {
                x: this.action.next[0].x,
                y: this.action.next[0].y,
              }
            )
          ];
        this.action.distanceRemaining = Phaser.Math.Distance.Between(
          this.bodySprite.x,
          this.bodySprite.y,
          this.action.next[0].x,
          this.action.next[0].y
        );
      }
      this.updateActionDirectionFrame();
    } else {
      /**
       * The explorer had not reach the end of current milestone yet.
       * We simply make the explorer move forward as planned.
       */
      this.bodySprite.x = newX;
      this.bodySprite.y = newY;
      this.action.distanceRemaining -= currDistance;
    }
  }

  update(delta: number) {
    /**
     * Handle walk logic which update direction and new location of explorer
     */
    switch (this.action.motion) {
      /********
       * Idle *
       ********
       */
      case "idle":
        this.action.remainingLength -= delta;

        switch (this.action.movement) {
          case "thinking_from_standing":
            if (this.action.remainingLength <= 0) {
              this.action = {
                ...this.action,
                movement: "thinking",
                remainingLength: randInt(2000, 10000),
              };
              this.updateActionDirectionFrame();
            }
            break;
          case "thinking":
            if (this.action.remainingLength <= 0) {
              this.action = {
                ...this.action,
                movement: "thinking_to_standing",
                remainingLength: (14 / 24) * 1000,
              };
              this.updateActionDirectionFrame();
            }
        }

        break;

      /********
       * Walk *
       ********
       */
      case "walk":
        this.walk(delta);
        break;

      /*******
       * Bed *
       *******
       */
      case "bed":
        switch (this.action.stage) {
          case "walkIn":
          case "walkOut":
            this.walk(delta);

            if (
              this.action.stage === "walkIn" &&
              this.action.distanceRemaining === 0 &&
              this.action.next.length <= 0
            ) {
              this.action = {
                ...this.action,
                ...animationTypeMeta.bed.bedAttr.action,
              };
              this.bodySprite.x = animationTypeMeta.bed.bedAttr.x;
              this.bodySprite.y = animationTypeMeta.bed.bedAttr.y;
              this.overrideDepth = 9999;
              this.updateActionDirectionFrame();
            }
            break;
          case "bed":
            this.action.remainingLength -= delta;

            if (this.action.remainingLength <= 0) {
              const startingPosition = animationTypeMeta.bed.pathIn.at(-1)!;
              this.bodySprite.x = startingPosition.x;
              this.bodySprite.y = startingPosition.y;
              this.overrideDepth = undefined;

              const firstDestination = animationTypeMeta.bed.pathOut[0];

              this.action = {
                ...this.action,
                stage: "walkOut",
                direction:
                  directionsMap[
                    getIsometricDirectionFromPixel(
                      startingPosition,
                      firstDestination
                    )
                  ],
                destination: firstDestination,
                next: animationTypeMeta.bed.pathOut,
                distanceRemaining: Phaser.Math.Distance.Between(
                  startingPosition.x,
                  startingPosition.y,
                  firstDestination.x,
                  firstDestination.y
                ),
              };

              this.hideoutScene.hideoutSpaceOccupancy = {
                ...this.hideoutScene.hideoutSpaceOccupancy,
                bed: false,
              };

              this.updateActionDirectionFrame();
            }

            break;
        }
        break;
      case "scoping_tower":
        switch (this.action.stage) {
          case "walkIn":
          case "walkOut":
            this.walk(delta);

            if (
              this.action.stage === "walkIn" &&
              this.action.distanceRemaining === 0 &&
              this.action.next.length <= 0
            ) {
              this.action = {
                ...this.action,
                ...animationTypeMeta.scoping_tower.scopingAttr.action,
              };
              this.bodySprite.x = animationTypeMeta.scoping_tower.scopingAttr.x;
              this.bodySprite.y = animationTypeMeta.scoping_tower.scopingAttr.y;
              this.overrideDepth = 9999;
              this.updateActionDirectionFrame();
            }
            break;
          case "scoping_tower":
            this.action.remainingLength -= delta;

            if (this.action.remainingLength <= 0) {
              const startingPosition =
                animationTypeMeta.scoping_tower.pathIn.at(-1)!;
              this.bodySprite.x = startingPosition.x;
              this.bodySprite.y = startingPosition.y;
              this.overrideDepth = undefined;

              this.action = {
                ...this.action,
                stage: "walkOut",
                destination: startingPosition,
                next: [],
                distanceRemaining: 0,
              };

              this.hideoutScene.hideoutSpaceOccupancy = {
                ...this.hideoutScene.hideoutSpaceOccupancy,
                layouttop: false,
              };

              this.updateActionDirectionFrame();
            }

            break;
        }
        break;
      case "interact_station":
        switch (this.action.stage) {
          case "walkIn":
          case "walkOut":
            this.walk(delta);

            if (
              this.action.stage === "walkIn" &&
              this.action.distanceRemaining === 0 &&
              this.action.next.length <= 0
            ) {
              this.action = {
                ...this.action,
                ...animationTypeMeta.interact_station[
                  this.action.occupiedStation
                ].workspaceAttr.action,
              };
              this.bodySprite.x =
                animationTypeMeta.interact_station[
                  this.action.occupiedStation
                ].workspaceAttr.x;
              this.bodySprite.y =
                animationTypeMeta.interact_station[
                  this.action.occupiedStation
                ].workspaceAttr.y;
              this.overrideDepth =
                animationTypeMeta.interact_station[
                  this.action.occupiedStation
                ].workspaceAttr.overrideDepth;
              this.updateActionDirectionFrame();
            }
            break;

          case "interact_station":
            this.action.remainingLength -= delta;

            if (this.action.remainingLength <= 0) {
              /**
               * 30% chance will leave. 70% chance will change direction instead.
               */
              const changeDirection = randInt(1, 100) < 70;

              if (changeDirection) {
                const movement = this.action.movement === "a" ? "b" : "a";
                this.action = {
                  ...this.action,
                  movement,
                  direction:
                    animationTypeMeta.interact_station.direction[movement][0],
                  remainingLength: ((movement === "a" ? 54 : 72) / 24) * 1000,
                };
              } else {
                const startingPosition =
                  animationTypeMeta.interact_station[
                    this.action.occupiedStation
                  ].pathIn.at(-1)!;
                this.bodySprite.x = startingPosition.x;
                this.bodySprite.y = startingPosition.y;
                this.overrideDepth = undefined;

                const firstDestination =
                  animationTypeMeta.interact_station[
                    this.action.occupiedStation
                  ].pathOut[0];

                this.action = {
                  ...this.action,
                  stage: "walkOut",
                  direction:
                    directionsMap[
                      getIsometricDirectionFromPixel(
                        startingPosition,
                        firstDestination
                      )
                    ],
                  destination: firstDestination,
                  next: animationTypeMeta.interact_station[
                    this.action.occupiedStation
                  ].pathOut,
                  distanceRemaining: Phaser.Math.Distance.Between(
                    startingPosition.x,
                    startingPosition.y,
                    firstDestination.x,
                    firstDestination.y
                  ),
                };

                this.hideoutScene.hideoutSpaceOccupancy = {
                  ...this.hideoutScene.hideoutSpaceOccupancy,
                  [this.action.occupiedStation]: false,
                };
              }

              this.updateActionDirectionFrame();
            }
            break;
        }
    }

    /**
     * Check if we need to generate a new action
     */
    if (isExplorerActionEnded(this.action)) {
      const { action: newAction, hideoutSpaceOccupancy } =
        generateRandomizeExplorerAction(this.action, {
          currentCoordinate: {
            x: this.bodySprite.x,
            y: this.bodySprite.y,
          },
          hideoutSpaceOccupancy: this.hideoutScene.hideoutSpaceOccupancy,
          occupiedCoordinate: this.hideoutScene.explorers
            .filter((explorer) => explorer.lootId !== this.lootId)
            .map((explorer) => {
              switch (explorer.action.motion) {
                case "walk":
                  return explorer.action.destination;
                case "idle":
                  return { x: explorer.bodySprite.x, y: explorer.bodySprite.y };
                default:
                  return undefined;
              }
            })
            .filter((coordinate) => coordinate) as Coordinate[],
        });

      this.action = newAction;
      this.hideoutScene.hideoutSpaceOccupancy = hideoutSpaceOccupancy;
      this.updateActionDirectionFrame();
    }

    /**
     * Handle depth
     */
    this.bodySprite.depth = this.overrideDepth || this.bodySprite.y;
    this.pantsMask.depth = this.bodySprite.depth - 1;
    this.depth = this.pantsMask.depth;

    /**
     * Replicate body sprite coordination to pants mask
     */
    this.pantsMask.x = this.bodySprite.x;
    this.pantsMask.y = this.bodySprite.y;

    // Add depth to head and face
    this.headSprite.setDepth(this.bodySprite.depth + 1);
    this.faceSprite?.setDepth(this.bodySprite.depth + 2);

    /**
     * Update head sprite, face sprite
     */
    this.headSprite.setX(this.bodySprite.x + this.getHeadOffsetX());
    this.headSprite.setY(this.bodySprite.y + this.getHeadOffsetY());
    this.faceSprite?.setX(this.bodySprite.x + this.getHeadOffsetX());
    this.faceSprite?.setY(this.bodySprite.y + this.getHeadOffsetY());

    /**
     * Update Explorer portrait object
     */
    this.explorerPortraitSprite.update(this.bodySprite.x, this.bodySprite.y);
  }

  destroy(fromScene?: boolean | undefined): void {
    this.bodySprite.destroy(fromScene);
    this.headSprite.destroy(fromScene);
    this.faceSprite?.destroy(fromScene);
    this.pantsMask.destroy(fromScene);
    this.explorerPortraitSprite.destroy(fromScene);

    super.destroy(fromScene);
  }

  /**
   * When the mode are changed to edit mode, we disable clicking for explorer,
   * and hide any portrait sprite if any
   */
  setEditMode(isEdit: boolean): void {
    if (isEdit) {
      this.bodySprite.disableInteractive();
      this.headSprite.disableInteractive();

      if (this.explorerPortraitSprite.visible) {
        this.explorerPortraitSprite.toggleState();
      }

      return;
    }

    this.bodySprite.setInteractive();
    this.headSprite.setInteractive();
  }
}

export default ExplorerObject;
