import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import axios from "axios";
import { deleteObject, ref } from "firebase/storage";
import React, { useCallback, useRef } from "react";
import styled from "styled-components";

import { Diamond, EditFeather } from "../../../assets/icons";
import {
  API_BASE_URL,
  FIREBASE_STORAGE_STATIC_URL,
} from "../../../config/constants";
import { PrimaryText, Subtitle, Title } from "../../../designSystem";
import { colors } from "../../../designSystem/colors";
import { usePersonalizedData } from "../../../hooks/personalizedDataContext";
import { LoadingText } from "../../../hooks/useLoadingText";
import useWeb3Wallet from "../../../hooks/useWeb3Wallet";
import { useGlobalState } from "../../../store/store";
import { storage } from "../../../utils/firebase";
import { clickableScale } from "../../Common/style";
import ProfilePlaceholderImg from "../../../assets/icons/profile/profile-placeholder.jpg";
import useSiwe from "../../../hooks/useSiwe";
import { playSecondaryClickSound } from "../../../utils/sound";

const ProfileImgContainer = styled.div`
  position: relative;
  width: 17vh;
  height: 17vh;
`;

const AbsoluteCenteredContainer = styled.div.attrs({
  className:
    "position-absolute d-flex align-items-center justify-content-center h-100 w-100",
})``;

const ProfileImgRectLayer = styled.div`
  width: 12.021vh;
  height: 12.021vh;
  left: 8.5vh;
  top: 0px;

  border: 0.1vh solid ${colors.primaryRed};
  transform: rotate(45deg);
`;

const ProfileImgCircleShadow = styled.div`
  width: 13.5vh;
  height: 13.5vh;
  border-radius: 13.5vh;
  transform: translateY(0.3vh);

  background: ${colors.primaryRed};
`;

const ProfileImgZoomContainer = styled.div`
  width: 13.5vh;
  height: 13.5vh;
  border-radius: 13.5vh;
  border: 0.1vh solid ${colors.primaryRed};
  background: ${colors.pale};
  overflow: hidden;
`;

const ProfileImg = styled.img`
  width: 100%;
  height: 100%;
  transition: transform 0.25s ease-out;

  &:hover {
    transform: scale(1.25);
  }
`;

const ProfileDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 1.6vh;
  position: relative;
`;

const ProfileDetailTextContainer = styled.div.attrs({
  className: "d-flex align-items-center w-100",
})<{ placement: "top" | "bottom" }>`
  position: absolute;
  ${(props) => (props.placement === "top" ? "bottom" : "top")}: 0.8vh;
  width: 100%;
`;

const ProfileName = styled(Title)`
  margin-left: 1.2vh;
`;

const ProfileNameEditButton = styled.div`
  ${clickableScale}

  margin-left: auto;
  margin-right: 1vh;
`;

const ProfileDetailDivider = styled.div`
  width: 100%;
  border: 0.05vh solid ${colors.primaryRed};
`;

const ProfileAddress = styled(PrimaryText)`
  margin-left: 2.5vh;
`;

const DisconnectButton = styled(Subtitle)`
  ${clickableScale}
  position: absolute;
  right: 5vh;
  bottom: 4vh;
  letter-spacing: 0.1vh;
`;

const HideoutProfileModalProfileContent: React.FC<{
  onEditName: () => void;
}> = ({ onEditName }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { account, connector, deactivate } = useWeb3Wallet();
  const { name, profileImage, bannerProfileImage, updateFieldData, loading } =
    usePersonalizedData();
  const { token } = useSiwe();
  const [, setShowHideoutProfileModal] = useGlobalState(
    "showHideoutProfileModal"
  );
  const [, setShowSiweModal] = useGlobalState("showSiweModal");
  const [, setToastDatas] = useGlobalState("toastDatas");

  const handleDisconnect = useCallback(() => {
    if (connector && connector instanceof WalletConnectConnector) {
      const wcConnector = connector as WalletConnectConnector;
      wcConnector.close();
    }
    deactivate();

    setShowHideoutProfileModal(false);
  }, [connector, deactivate, setShowHideoutProfileModal]);

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/png, image/jpeg"
        style={{ display: "none" }}
        onChange={async (e) => {
          if (!e.target.files) {
            return;
          }

          const uploadingToastId = Date.now().valueOf().toString();
          setToastDatas((prev) => [
            ...prev,
            {
              id: uploadingToastId,
              type: "info",
              message: "Uploading profile image...",
            },
          ]);

          // Attempt to remove old image in async manner
          try {
            if (profileImage) {
              deleteObject(ref(storage, profileImage));
            }

            if (bannerProfileImage) {
              deleteObject(ref(storage, bannerProfileImage));
            }
          } catch (err) {}

          const formData = new FormData();
          formData.append("image", e.target.files[0]);

          const data = await axios.post(
            `${API_BASE_URL}/hideout/profile/image`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `bearer ${token}`,
              },
            }
          );

          updateFieldData<"profileImage">(
            "profileImage",
            data.data.profileImage
          );
          updateFieldData<"bannerProfileImage">(
            "bannerProfileImage",
            data.data.bannerImage
          );
          setToastDatas((prev) => [
            ...prev.filter((data) => data.id !== uploadingToastId),
            {
              id: Date.now().valueOf().toString(),
              type: "info",
              message: "Profile image successfully uploaded!",
              autoHide: {
                duration: 3000,
              },
            },
          ]);
        }}
      />
      <div className="d-flex flex-wrap w-100 h-100 align-items-center p-3">
        {/* Profile Image */}
        <ProfileImgContainer>
          {/* Rect */}
          <AbsoluteCenteredContainer>
            <ProfileImgRectLayer />
          </AbsoluteCenteredContainer>

          {/* Shadow circle */}
          <AbsoluteCenteredContainer>
            <ProfileImgCircleShadow />
          </AbsoluteCenteredContainer>

          {/* Profile Image */}
          <AbsoluteCenteredContainer>
            <ProfileImgZoomContainer>
              <ProfileImg
                role="button"
                className="clickable"
                onClick={() => {
                  playSecondaryClickSound();

                  if (!token) {
                    setShowHideoutProfileModal(false);
                    setShowSiweModal(true);
                    return;
                  }

                  fileInputRef.current?.click();
                }}
                src={
                  profileImage
                    ? `${FIREBASE_STORAGE_STATIC_URL}/${encodeURIComponent(
                        profileImage
                      )}?alt=media`
                    : ProfilePlaceholderImg
                }
              />
            </ProfileImgZoomContainer>
          </AbsoluteCenteredContainer>
        </ProfileImgContainer>

        {/* Profile Detail */}
        <ProfileDetailContainer>
          <ProfileDetailTextContainer placement="top">
            <Diamond height="1.2vh" width="1.2vh" />

            {/* Profile Name */}
            <ProfileName fontSize="2.8vh" lineHeight={1.5} normalCased>
              {loading ? <LoadingText text="Loading" /> : name}
            </ProfileName>

            {/* Edit Button */}
            <ProfileNameEditButton>
              <EditFeather
                onClick={() => {
                  playSecondaryClickSound();
                  onEditName();
                }}
                height="2.2vh"
                width="1.9vh"
              />
            </ProfileNameEditButton>
          </ProfileDetailTextContainer>

          <ProfileDetailDivider />

          {/* Public Address */}
          <ProfileDetailTextContainer placement="bottom">
            <ProfileAddress fontSize="1.6vh" lineHeight={1.5}>
              {account}
            </ProfileAddress>
          </ProfileDetailTextContainer>
        </ProfileDetailContainer>

        <DisconnectButton
          fontSize="1.4vh"
          lineHeight={1.5}
          onClick={() => {
            playSecondaryClickSound();
            handleDisconnect();
          }}
        >
          Disconnect
        </DisconnectButton>
      </div>
    </>
  );
};

export default HideoutProfileModalProfileContent;
