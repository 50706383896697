type ModeFlagSVGProps = React.SVGAttributes<SVGElement> & { color?: string };

export const ModeFlagSVG: React.FC<ModeFlagSVGProps> = (props) => (
  <svg
    width="491"
    height="86"
    viewBox="0 0 491 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 0H491L432.261 43L491 86H0V0Z" fill={props.color || "#FEC36F"} />
  </svg>
);
