import React from "react";

type SVGPropsWithColor = React.SVGAttributes<SVGElement> & { color?: string };

const PortraitBackground: React.FC<SVGPropsWithColor> = (props) => (
  <svg
    width="422"
    height="343"
    viewBox="0 0 422 343"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      x1="4"
      y1="171.5"
      x2="419"
      y2="171.5"
      stroke={props.color || "#FC5B49"}
    />
    <circle cx="210.5" cy="171.5" r="171" stroke={props.color || "#FC5B49"} />
    <rect
      x="417.818"
      y="167.818"
      width="5.5282"
      height="5.5282"
      transform="rotate(45 417.818 167.818)"
      fill={props.color || "#FC5B49"}
    />
    <rect
      x="3.81787"
      y="168"
      width="5.3995"
      height="5.3995"
      transform="rotate(45 3.81787 168)"
      fill={props.color || "#FC5B49"}
    />
  </svg>
);

export default PortraitBackground;
