export const firstSixAddressChar = (address?: string | null) => {
  if (!address) {
    return "";
  }

  return address.slice(0, 6);
};

export const lastFourAddressChar = (address?: string | null) => {
  if (!address) {
    return "";
  }
  return address.slice(-4, address.length);
};

export const shortenAddress = (address?: string | null) => {
  if (!address) {
    return "";
  }
  return `${firstSixAddressChar(address)}...${lastFourAddressChar(address)}`;
};
