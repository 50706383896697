import { useWeb3React } from "@web3-react/core";

import { impersonateAddress as _impersonateAddress } from "../config/development";
import { isProduction } from "../config/env";
import { useGlobalState } from "../store/store";

const useWeb3Wallet = () => {
  const { account: _account, active, ...web3Props } = useWeb3React();
  const [impersonateAddress, setImpersonateAddress] =
    useGlobalState("impersonateAddress");
  const account = isProduction()
    ? _account
    : impersonateAddress || _impersonateAddress || _account;

  return {
    ...web3Props,
    account,
    active: Boolean(account) || active,
    impersonateAddress,
    setImpersonateAddress,
  };
};

export default useWeb3Wallet;
