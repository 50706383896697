const SIWE_TOKEN_PREFIX_KEY = "SIWE";
const LAST_LAUNCHED_KEY = "LAST_LAUNCHED";
const OPENED_MAILBOX_HASH_KEY = "OPENED_MAILBOX_HASH";
const BUTTON_LAST_CLICKED_KEY = "BUTTON_CLICKED";

export const getSiweToken = (account: string) => {
  return localStorage.getItem(`${SIWE_TOKEN_PREFIX_KEY}-${account}`);
};

export const setSiweToken = (account: string, token: string) => {
  localStorage.setItem(`${SIWE_TOKEN_PREFIX_KEY}-${account}`, token);

  return token;
};

export const clearSiweToken = (account: string) => {
  localStorage.removeItem(`${SIWE_TOKEN_PREFIX_KEY}-${account}`);
};

export const isFirstLaunch = () => {
  return !getLastLaunchedDate();
};

export const getLastLaunchedDate = () => {
  const lastLaunched = localStorage.getItem(LAST_LAUNCHED_KEY);
  if (lastLaunched) {
    return new Date(lastLaunched);
  }
  return undefined;
};

export const updateLastLaunchedDate = () => {
  const date = new Date();
  localStorage.setItem(LAST_LAUNCHED_KEY, date.toISOString());
  return date;
};

export const getOpenedMailboxHash = () => {
  return localStorage.getItem(OPENED_MAILBOX_HASH_KEY);
};

export const updateOpenedMailboxHash = (newHash: string) => {
  localStorage.setItem(OPENED_MAILBOX_HASH_KEY, newHash);

  return newHash;
};

export const getButtonLastClicked = (button: "EDIT" | "SQUAD") => {
  const lastClicked = localStorage.getItem(
    `${button}_${BUTTON_LAST_CLICKED_KEY}`
  );

  return lastClicked ? new Date(lastClicked) : undefined;
};

export const updateButtonLastClicked = (button: "EDIT" | "SQUAD") => {
  const date = new Date();
  localStorage.setItem(
    `${button}_${BUTTON_LAST_CLICKED_KEY}`,
    date.toISOString()
  );

  return date;
};
