import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import {
  BackArrowIcon,
  CrossIcon,
  DashIcon,
  SaveFeatherIcon,
} from "../../../assets/icons";
import { API_BASE_URL } from "../../../config/constants";
import { PrimaryText, scrollbarStyle, Title } from "../../../designSystem";
import { colors } from "../../../designSystem/colors";
import { useDisplayExplorers } from "../../../hooks/useDisplayExplorers";
import { LoadingText } from "../../../hooks/useLoadingText";
import { useOwnedExplorers } from "../../../hooks/web3DataContext";
import { getExplorerFullName } from "../../../utils/explorers";
import { clickableScale, clickableShrunk } from "../../Common/style";
import { playSecondaryClickSound } from "../../../utils/sound";

const BackButton = styled(BackArrowIcon)`
  ${clickableShrunk}

  margin-right: auto;
  z-index: 1000;
`;

const ActionButton = styled.div.attrs({
  className: "d-flex align-items-center justify-content-center",
})`
  ${clickableShrunk}
  width: 4vh;
  height: 4vh;
  border-radius: 10vh;
  border: 0.1vh solid ${colors.primaryRed};
  background-color: ${colors.modalContentBackground};
  z-index: 1000;
`;

const ExplorersPreviewContianer = styled.div.attrs({
  className: "d-flex w-100 justify-content-center",
})`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 2.4vh;
  margin: 2.4vh 0;
`;

const ExplorersScrollContainer = styled.div.attrs({
  className: "d-flex mx-3 w-100 justify-content-center",
})`
  ${scrollbarStyle}
  overflow-y: auto;
  height: 37vh;
`;

const ExplorersSelectionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2.4vh;
`;

const ExplorerContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 10vh;
`;

const ExplorerImgContainer = styled.div`
  position: relative;
  width: 10vh;
  height: 10vh;
`;

const AbsoluteCenteredContainer = styled.div.attrs({
  className:
    "position-absolute d-flex align-items-center justify-content-center h-100 w-100",
})``;

const ExplorerImgCircleShadow = styled.div`
  width: 10vh;
  height: 10vh;
  border-radius: 10vh;
  transform: translateY(0.3vh);

  background: ${colors.primaryRed};
`;

const ExplorerImg = styled.div<{
  explorerImg: string;
}>`
  position: relative;
  overflow: hidden;
  width: 10vh;
  height: 10vh;
  border-radius: 10vh;
  border: 0.1vh solid ${colors.primaryRed};

  background: ${colors.modalContentBackground};

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url(${(props) => props.explorerImg});
    background-size: cover;
    transform: scale(1.4);
    transform-origin: top;
  }
`;

const ExplorerTintContainer = styled.div<{ selected: boolean }>`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  transition: 0.2s opacity ease;
  opacity: ${(props) => (props.selected ? "1" : "0")};

  &:hover {
    opacity: ${(props) => (props.selected ? "0" : "1")};
  }
`;

const ExplorerImgTint = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10vh;
  height: 10vh;
  border: 0.1vh solid ${colors.primaryRed};
  border-radius: 10vh;

  background: ${colors.hoverTintBrown}80;
`;

const ExplorerNameText = styled(PrimaryText).attrs({
  className: "text-center",
  fontSize: "1.4vh",
  lineHeight: 1.2,
})`
  margin-top: 1.6vh;
`;

const FloatingExplorerActiveCountContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  height: 2.4vh;
  width: 2.4vh;
  border-radius: 10vh;
  background-color: ${colors.primaryRed};
`;

const ClickableFloatingExplorerActiveCountContainer = styled(
  FloatingExplorerActiveCountContainer
)`
  ${clickableScale}
`;

const ExplorerAddContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10vh;
  height: 10vh;
  background: ${colors.primaryRed};
  border-radius: 10vh;
`;

interface ExplorersSquadEditContentProps {
  onReturn: () => void;
}

const ExplorersSquadEditContent: React.FC<ExplorersSquadEditContentProps> = ({
  onReturn,
}) => {
  const { ownedExplorers, loading } = useOwnedExplorers();
  const { displayExplorers, setDisplayExplorers } = useDisplayExplorers();

  const [editDisplayExplorers, setEditDisplayExplorers] = useState<
    (string | undefined)[] | undefined
  >();

  useEffect(() => {
    if (!editDisplayExplorers && displayExplorers) {
      setEditDisplayExplorers([
        ...displayExplorers,
        ...Array(5 - displayExplorers.length).fill(undefined),
      ]);
    }
  }, [displayExplorers, editDisplayExplorers]);

  const toggleDisplayExplorers = useCallback(
    (explorerId: string) => {
      if (!ownedExplorers) {
        return;
      }

      setEditDisplayExplorers((prevDisplayExplorers) => {
        if (!prevDisplayExplorers) {
          return prevDisplayExplorers;
        }

        const currentIndex = prevDisplayExplorers.indexOf(explorerId);

        /**
         * Add explorer
         */
        if (currentIndex === -1) {
          const nextFillIndex = prevDisplayExplorers.indexOf(undefined);

          if (nextFillIndex === -1) {
            return prevDisplayExplorers;
          }

          return prevDisplayExplorers.map((val, index) =>
            index === prevDisplayExplorers.indexOf(undefined) ? explorerId : val
          );
        }

        /**
         * Remove explorer
         */
        return prevDisplayExplorers.map((val, index) =>
          index === currentIndex ? undefined : val
        );
      });
    },
    [ownedExplorers]
  );

  const previewContent = useMemo(() => {
    if (!editDisplayExplorers || loading) {
      return <></>;
    }

    return (
      <ExplorersPreviewContianer>
        {editDisplayExplorers.map((explorerId) => {
          if (!explorerId) {
            return (
              <ExplorerAddContainer key={explorerId}>
                <CrossIcon color={colors.modalBackground} />
              </ExplorerAddContainer>
            );
          }

          return (
            <ExplorerContainer key={explorerId}>
              {/* Img */}
              <ExplorerImgContainer>
                <AbsoluteCenteredContainer>
                  <ExplorerImgCircleShadow />
                </AbsoluteCenteredContainer>

                <AbsoluteCenteredContainer>
                  <ExplorerImg
                    explorerImg={`${API_BASE_URL}/explorers-transparent/${explorerId}?size=small`}
                  />
                </AbsoluteCenteredContainer>
              </ExplorerImgContainer>

              {/* Name */}
              <ExplorerNameText>
                {getExplorerFullName(explorerId)}
              </ExplorerNameText>

              <ClickableFloatingExplorerActiveCountContainer
                role="button"
                onMouseDown={playSecondaryClickSound}
                onClick={() => toggleDisplayExplorers(explorerId)}
              >
                <DashIcon color="white" width="1vh" height="auto" />
              </ClickableFloatingExplorerActiveCountContainer>
            </ExplorerContainer>
          );
        })}
      </ExplorersPreviewContianer>
    );
  }, [editDisplayExplorers, loading, toggleDisplayExplorers]);

  const explorersSelectionContent = useMemo(() => {
    if (!editDisplayExplorers || loading) {
      return (
        <div className="d-flex w-100 justify-content-center">
          <LoadingText />
        </div>
      );
    }

    return (
      <ExplorersScrollContainer>
        <ExplorersSelectionContainer>
          {ownedExplorers.map((explorerId) => (
            <ExplorerContainer
              key={explorerId}
              role="button"
              className="clickable"
              onMouseDown={playSecondaryClickSound}
              onClick={() => toggleDisplayExplorers(explorerId)}
            >
              {/* Img */}
              <ExplorerImgContainer>
                <AbsoluteCenteredContainer>
                  <ExplorerImgCircleShadow />
                </AbsoluteCenteredContainer>

                <AbsoluteCenteredContainer>
                  <ExplorerImg
                    explorerImg={`${API_BASE_URL}/explorers-transparent/${explorerId}?size=small`}
                  />
                </AbsoluteCenteredContainer>
              </ExplorerImgContainer>

              {/* Name */}
              <ExplorerNameText>
                {getExplorerFullName(explorerId)}
              </ExplorerNameText>

              {/* Hover Container */}
              <ExplorerTintContainer
                selected={Boolean(editDisplayExplorers.includes(explorerId))}
              >
                <ExplorerImgTint></ExplorerImgTint>
              </ExplorerTintContainer>

              {editDisplayExplorers.includes(explorerId) && (
                <FloatingExplorerActiveCountContainer>
                  <PrimaryText color="white" fontSize="1.6vh">
                    {editDisplayExplorers.indexOf(explorerId) + 1}
                  </PrimaryText>
                </FloatingExplorerActiveCountContainer>
              )}
            </ExplorerContainer>
          ))}
        </ExplorersSelectionContainer>
      </ExplorersScrollContainer>
    );
  }, [editDisplayExplorers, loading, ownedExplorers, toggleDisplayExplorers]);

  return (
    <div
      className="d-flex flex-wrap h-100 align-items-start"
      style={{ padding: "1.6vh" }}
    >
      {/* Title */}
      <div className="d-flex w-100 align-items-center position-relative">
        <BackButton
          onMouseDown={playSecondaryClickSound}
          onClick={onReturn}
          width="8.3vh"
          height="auto"
        />
        <div className="d-flex w-100 position-absolute justify-content-center">
          <Title fontSize="1.6vh" normalCased>
            Select up to 5 explorers !
          </Title>
        </div>
        <ActionButton
          onMouseDown={playSecondaryClickSound}
          onClick={() => {
            if (editDisplayExplorers) {
              setDisplayExplorers(
                editDisplayExplorers.filter(
                  (explorerId) => explorerId
                ) as string[]
              );
            }

            onReturn();
          }}
        >
          <SaveFeatherIcon height="auto" width="75%" />
        </ActionButton>
      </div>

      {previewContent}
      {explorersSelectionContent}
    </div>
  );
};

export default ExplorersSquadEditContent;
