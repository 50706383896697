import React, { useCallback, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useKey } from "rooks";
import styled from "styled-components";

import { BaseInput, BaseInputContainer, Title } from "../../../designSystem";
import { colors } from "../../../designSystem/colors";
import theme from "../../../designSystem/theme";
import { clickableShrunk } from "../../Common/style";
import { playSecondaryClickSound } from "../../../utils/sound";

export const SingleFieldFormModalContentSize = {
  width: "70vh",
  height: "12.6vh",
};

const SaveButton = styled.button<{ disabled: boolean }>`
  ${(props) => (props.disabled ? "" : clickableShrunk)}

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${colors.modalContentBackground};
  border: ${theme.border.width} ${theme.border.style}
    ${(props) => (props.disabled ? colors.gray : colors.primaryRed)};
  border-radius: ${theme.border.radius};
`;

interface SingleFieldFormModalContentProps {
  initialValue?: string;
  placeholder: string;
  validateValue: (value: string) => boolean;
  onSubmit: (value: string) => void;
}

const SingleFieldFormModalContent: React.FC<
  SingleFieldFormModalContentProps
> = ({ initialValue, placeholder, validateValue, onSubmit: _onSubmit }) => {
  const [inputValue, setInputValue] = useState(initialValue || "");

  const inputError = useMemo(
    () => validateValue(inputValue),
    [inputValue, validateValue]
  );

  const onSubmit = useCallback(() => {
    if (inputError) {
      return;
    }

    _onSubmit(inputValue);
  }, [_onSubmit, inputError, inputValue]);

  useKey(["Enter"], onSubmit);

  return (
    <Row className="w-100 g-0">
      <Col xs={9} style={{ padding: "0.8vh" }}>
        <BaseInputContainer error={inputError}>
          <BaseInput
            className="form-control"
            placeholder={placeholder}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            fontSize="3.2vh"
            lineHeight="1"
            inputWidth="100%"
          />
        </BaseInputContainer>
      </Col>
      <Col xs={3} style={{ padding: "0.8vh" }}>
        <SaveButton
          disabled={inputError}
          onClick={() => {
            playSecondaryClickSound();
            onSubmit();
          }}
        >
          <Title color={inputError ? colors.gray : colors.primaryRed}>
            Save
          </Title>
        </SaveButton>
      </Col>
    </Row>
  );
};

export default SingleFieldFormModalContent;
