import { Coordinate } from "../../model/direction";
import { BackgroundLayer, backgroundLayerList } from "../../model/hideout";
import { IExplorerMetadata } from "../../model/loot";

type BaseBackgroundLayerMeta = {
  depth?: number;
  hitBoxes: { x: number; y: number; width: number; height: number }[];
  noTrimPreview?: boolean;
  noPaddingPreview?: boolean;
  unlockCriteria?: {
    [index: number]: {
      metaName: keyof IExplorerMetadata;
      metaValue: string;
      count: number;
    };
  };
  animated?: {
    [index: number]: {
      spritesheetCount: number;
    };
  };
  customization?: Array<{
    type: "color";
    layerIndex: number[];
    choices: string[];
  }>;
  count: number;
  center?: Coordinate;
};

export const backgroundLayersMeta: Array<
  {
    name: BackgroundLayer;
  } & BaseBackgroundLayerMeta
> = [
  {
    name: "bg",
    count: 4,
    hitBoxes: [],
    noTrimPreview: true,
    noPaddingPreview: true,
  },
  {
    name: "housebox",
    count: 1,
    hitBoxes: [],
    customization: [
      {
        type: "color",
        layerIndex: [0],
        choices: [
          "#f6a798",
          "#ffe8b4",
          "#bbe0c7",
          "#d4c9bf",
          "#bce5f4",
          "#bcb3d9",
          "#9594a4",
          "#a7cecc",
          "#f9bcc9",
          "#4fb1c5",
          "#67d2d2",
          "#d9e3ec",
          "#6e656b",
          "#d4c8c0",
        ],
      },
    ],
  },
  {
    name: "floor",
    count: 10,
    hitBoxes: [],
    customization: [
      {
        type: "color",
        layerIndex: [8, 9],
        choices: [
          "#f6a798",
          "#ffe8b4",
          "#bbe0c7",
          "#d4c9bf",
          "#bce5f4",
          "#bcb3d9",
          "#9594a4",
          "#a7cecc",
          "#f9bcc9",
          "#4fb1c5",
          "#67d2d2",
          "#d9e3ec",
          "#6e656b",
          "#d4c8c0",
        ],
      },
    ],
  },
  {
    name: "wallpaper",
    count: 7,
    hitBoxes: [],
    customization: [
      {
        type: "color",
        layerIndex: [3, 4, 5, 6],
        choices: [
          "#f6a798",
          "#ffe8b4",
          "#bbe0c7",
          "#d4c9bf",
          "#bce5f4",
          "#bcb3d9",
          "#9594a4",
          "#a7cecc",
          "#f9bcc9",
          "#4fb1c5",
          "#67d2d2",
          "#d9e3ec",
          "#6e656b",
          "#d4c8c0",
        ],
      },
    ],
  },
  {
    name: "liningwood",
    count: 4,
    hitBoxes: [],
  },
  {
    name: "door",
    count: 7,
    hitBoxes: [{ x: 2356, y: 1198, width: 126, height: 280 }],
    center: { x: 2430, y: 1350 },
  },
  {
    name: "ladderwood",
    count: 4,
    hitBoxes: [{ x: 1943, y: 924, width: 99, height: 342 }],
    center: { x: 2000, y: 1100 },
  },
  {
    name: "walldeco",
    count: 7,
    hitBoxes: [{ x: 979, y: 568, width: 246, height: 282 }],
    center: { x: 1230, y: 710 },
  },
  {
    name: "divider",
    count: 7,
    depth: 1000,
    hitBoxes: [{ x: 1070, y: 810, width: 275, height: 290 }],
    center: { x: 1310, y: 950 },
  },
  {
    name: "bed",
    count: 8,
    depth: 1001,
    hitBoxes: [{ x: 926, y: 888, width: 274, height: 244 }],
    unlockCriteria: {
      7: {
        metaName: "head",
        metaValue: "CHARACTER_HEAD_RARE_Octo1",
        count: 1,
      },
    },
    center: { x: 1180, y: 1010 },
  },
  {
    name: "window",
    count: 8,
    hitBoxes: [{ x: 1823, y: 530, width: 185, height: 266 }],
    center: { x: 1940, y: 640 },
  },
  {
    name: "deco2",
    count: 4,
    hitBoxes: [{ x: 2064, y: 996, width: 264, height: 393 }],
    unlockCriteria: {
      3: {
        metaName: "head",
        metaValue: "CHARACTER_HEAD_RARE_Murk1",
        count: 1,
      },
    },
    animated: {
      3: {
        spritesheetCount: 1,
      },
    },
    center: { x: 2300, y: 1200 },
  },
  {
    name: "carpet",
    count: 2,
    hitBoxes: [
      { x: 1932, y: 1256, width: 220, height: 77 },
      { x: 1846, y: 1326, width: 180, height: 136 },
      { x: 2026, y: 1333, width: 249, height: 80 },
      { x: 1939, y: 1413, width: 430, height: 110 },
      { x: 2026, y: 1484, width: 216, height: 97 },
      { x: 2235, y: 1447, width: 180, height: 127 },
      { x: 2122, y: 1574, width: 211, height: 72 },
    ],
    unlockCriteria: {
      1: {
        metaName: "head",
        metaValue: "CHARACTER_HEAD_RARE_Turnip1",
        count: 1,
      },
    },
    animated: {
      1: {
        spritesheetCount: 1,
      },
    },
    customization: [
      {
        type: "color",
        layerIndex: [0],
        choices: [
          "#f6a798",
          "#ffe8b4",
          "#bbe0c7",
          "#d4c9bf",
          "#bce5f4",
          "#bcb3d9",
          "#9594a4",
          "#a7cecc",
          "#f9bcc9",
          "#4fb1c5",
          "#67d2d2",
          "#d9e3ec",
          "#6e656b",
          "#d4c8c0",
        ],
      },
    ],
    center: { x: 2385, y: 1440 },
  },
  {
    name: "deco3",
    count: 6,
    depth: 1299,
    hitBoxes: [
      { x: 1229, y: 1112, width: 220, height: 214 },
      { x: 1335, y: 1057, width: 203, height: 224 },
      { x: 1426, y: 1001, width: 234, height: 215 },
    ],
    unlockCriteria: {
      0: {
        metaName: "head",
        metaValue: "CHARACTER_HEAD_RARE_Cat1",
        count: 1,
      },
      1: {
        metaName: "head",
        metaValue: "CHARACTER_HEAD_RARE_Bunny1",
        count: 1,
      },
      2: {
        metaName: "head",
        metaValue: "CHARACTER_HEAD_RARE_Golem1",
        count: 1,
      },
      3: {
        metaName: "head",
        metaValue: "CHARACTER_HEAD_RARE_Bear1",
        count: 1,
      },
      4: {
        metaName: "head",
        metaValue: "CHARACTER_HEAD_RARE_Tree1",
        count: 1,
      },
      5: {
        metaName: "head",
        metaValue: "CHARACTER_HEAD_RARE_Skele1",
        count: 1,
      },
    },
    animated: {
      2: {
        spritesheetCount: 1,
      },
      3: {
        spritesheetCount: 1,
      },
      4: {
        spritesheetCount: 1,
      },
      5: {
        spritesheetCount: 1,
      },
    },
    center: { x: 1570, y: 1165 },
  },
  {
    name: "railingwood",
    count: 13,
    depth: 1300,
    hitBoxes: [
      { x: 1340, y: 1200, width: 120, height: 120 },
      { x: 1380, y: 1172.5, width: 120, height: 120 },
      { x: 1420, y: 1145, width: 120, height: 120 },
      { x: 1460, y: 1117.5, width: 120, height: 120 },
      { x: 1500, y: 1090, width: 120, height: 120 },
      { x: 1540, y: 1062.5, width: 120, height: 120 },
      { x: 1580, y: 1035, width: 120, height: 120 },
      { x: 1620, y: 1007.5, width: 120, height: 120 },
      { x: 1660, y: 980, width: 80, height: 27.5 },
      { x: 1700, y: 952.5, width: 40, height: 27.5 },
    ],
    center: { x: 1730, y: 1155 },
  },
  {
    name: "workspacesright",
    count: 13,
    depth: 1500,
    hitBoxes: [{ x: 1574, y: 1270, width: 316, height: 310 }],
    unlockCriteria: {
      7: {
        metaName: "secondaryType",
        metaValue: "Ancients",
        count: 2,
      },
      8: {
        metaName: "secondaryType",
        metaValue: "Dark Spirit",
        count: 2,
      },
      9: {
        metaName: "secondaryType",
        metaValue: "Octo",
        count: 2,
      },
      10: {
        metaName: "secondaryType",
        metaValue: "Undead",
        count: 2,
      },
      11: {
        metaName: "secondaryType",
        metaValue: "Ursa",
        count: 2,
      },
      12: {
        metaName: "secondaryType",
        metaValue: "Goblin",
        count: 2,
      },
    },
    center: { x: 1890, y: 1460 },
  },
  {
    name: "workspacesleft",
    count: 13,
    depth: 1502,
    hitBoxes: [{ x: 1420, y: 1423, width: 289, height: 291 }],
    unlockCriteria: {
      7: {
        metaName: "secondaryType",
        metaValue: "Ancients",
        count: 2,
      },
      8: {
        metaName: "secondaryType",
        metaValue: "Dark Spirit",
        count: 2,
      },
      9: {
        metaName: "secondaryType",
        metaValue: "Octo",
        count: 2,
      },
      10: {
        metaName: "secondaryType",
        metaValue: "Undead",
        count: 2,
      },
      11: {
        metaName: "secondaryType",
        metaValue: "Ursa",
        count: 2,
      },
      12: {
        metaName: "secondaryType",
        metaValue: "Goblin",
        count: 2,
      },
    },
    center: { x: 1690, y: 1600 },
  },
  {
    name: "recgame",
    count: 13,
    depth: 1700,
    hitBoxes: [{ x: 1745, y: 1630, width: 295, height: 265 }],
    unlockCriteria: {
      5: {
        metaName: "secondaryType",
        metaValue: "Flame",
        count: 2,
      },
      6: {
        metaName: "secondaryType",
        metaValue: "Murk",
        count: 2,
      },
      7: {
        metaName: "secondaryType",
        metaValue: "Guardian",
        count: 2,
      },
      8: {
        metaName: "head",
        metaValue: "CHARACTER_HEAD_RARE_Bird1",
        count: 1,
      },
      9: {
        metaName: "head",
        metaValue: "CHARACTER_HEAD_RARE_Goblin1",
        count: 1,
      },
      10: {
        metaName: "head",
        metaValue: "CHARACTER_HEAD_RARE_Raccoon1",
        count: 1,
      },
      11: {
        metaName: "head",
        metaValue: "CHARACTER_HEAD_RARE_Golem1",
        count: 1,
      },
      12: {
        metaName: "secondaryType",
        metaValue: "Wildcat",
        count: 2,
      },
    },
    animated: {
      8: {
        spritesheetCount: 1,
      },
    },
    center: { x: 2135, y: 1780 },
  },
  {
    name: "banner",
    count: 1,
    hitBoxes: [],
  },
  {
    name: "layouttop",
    count: 7,
    hitBoxes: [],
  },
  {
    name: "layoutbottom",
    count: 7,
    depth: 10000,
    hitBoxes: [],
  },
  {
    name: "shelves",
    count: 8,
    depth: 600,
    hitBoxes: [{ x: 1280, y: 308, width: 520, height: 487 }],
    center: { x: 1785, y: 610 },
  },
  {
    name: "deco1",
    count: 8,
    depth: 749,
    hitBoxes: [{ x: 1750, y: 675, width: 240, height: 215 }],
    unlockCriteria: {
      6: {
        metaName: "head",
        metaValue: "CHARACTER_HEAD_RARE_Evil1",
        count: 1,
      },
      7: {
        metaName: "head",
        metaValue: "CHARACTER_HEAD_RARE_Flame1",
        count: 1,
      },
    },
    animated: {
      6: {
        spritesheetCount: 1,
      },
      7: {
        spritesheetCount: 1,
      },
    },
    center: { x: 1970, y: 795 },
  },
];

export const backgroundLayersMetaMap = Object.fromEntries(
  backgroundLayersMeta.map(({ name, ...meta }) => [name, meta])
) as {
  [layer in BackgroundLayer]: BaseBackgroundLayerMeta;
};

/**
 * Validate backgroundLayersMeta to have all the required categories inside
 */
(() => {
  if (backgroundLayerList.length !== backgroundLayersMeta.length) {
    throw new Error(
      "Background Layers Meta not tally with amount of layers available"
    );
  }
})();
