// We use the same environment for development and staging
// But we still need a switch to only show dev features locally
export const isStaging = () => process.env.REACT_APP_ENV === "staging";
export const isProduction = () => process.env.REACT_APP_ENV === "production";

// We just default to staging by default
export const isDevelopment = () => !isStaging() && !isProduction();

export const getNodeURI = () => {
  const uri = isDevelopment()
    ? process.env.REACT_APP_TESTNET_URI
    : process.env.REACT_APP_MAINNET_URI;
  return uri;
};

export const chainIdsMapping: {
  1: "Mainnet";
  4: "Rinkeby";
} = {
  1: "Mainnet",
  4: "Rinkeby",
};
export const supportedChainIDs = isDevelopment() ? [4, 1] : [1];
export const getDefaultNetworkName = () =>
  isDevelopment() ? "rinkeby" : "mainnet";
export const getDefaultChainID = () => (isDevelopment() ? 4 : 1);

export const SUBGRAPH_URL =
  "https://api.goldsky.com/api/public/project_clkrtknwf9s9438pe0d7sgvm8/subgraphs/loot-explorers/1.0.0/gn";
