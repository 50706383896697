import React, { useMemo } from "react";
import styled from "styled-components";
import { BrushIcon, Diamond } from "../../../assets/icons";
import { AnimatePresence, motion } from "framer-motion";

import { backgroundLayersMetaMap } from "../../../data/gameLayout/backgroundLayers";
import { scrollbarStyle, Title } from "../../../designSystem";
import { colors } from "../../../designSystem/colors";
import theme from "../../../designSystem/theme";
import { useDisplayExplorers } from "../../../hooks/useDisplayExplorers";
import useHideoutLayerCustomization from "../../../hooks/useHideoutLayerCustomization";
import {
  BackgroundLayer,
  BackgroundLayerDisplay,
  BackgroundLayersDisplayName,
} from "../../../model/hideout";
import {
  getLayerPreviewImageURL,
  getLayerPreviewPlaceholderImageURL,
  isLayerObjectUnlocked,
  layerCanCustomizeColor,
} from "../../../utils/backgroundLayer";
import ImageWithPlaceholder from "../../Common/ImageWithPlaceholder";
import { PrimaryText } from "../../../designSystem";
import { playSecondaryClickSound } from "../../../utils/sound";

const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.8vh;
  padding-bottom: 1.6vh;
`;

const OptionsScrollContainer = styled.div.attrs({
  className: "d-flex justify-content-center",
  scrollBarWidth: "0.8vh",
})`
  ${scrollbarStyle}
  overflow-y: auto;
  margin: 0 2vh 2vh 2vh;
`;

const CustomizationOptions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.8vh;
  width: 100%;
  height: fit-content;
  padding: 0px 1.2vh;
`;

const CustomizationOption = styled.div.attrs({
  role: "button",
  className: "position-relative clickable",
  onMouseDown: playSecondaryClickSound,
})<{ active: boolean; noPadding: boolean }>`
  width: 100%;
  border-radius: 1.2vh;
  ${(props) => (props.noPadding ? "" : "padding: 10%;")}
  border: 0.1vh
    ${theme.border.style} ${colors.primaryRed};
  outline: 0.4vh ${theme.border.style}
    ${(props) => (props.active ? colors.primaryRed : "transparent")};
  background-color: ${colors.modalContentBackground};
  overflow: hidden;
  transition: 0.2s all ease;

  img {
    transition: 0.2s transform ease;
    aspect-ratio: 3/2;
    object-fit: ${(props) => (props.noPadding ? "cover" : "contain")};
  }

  &:hover {
    img {
      transform: scale(1.2);
    }
  }
`;

const CustomizationOptionName = styled(PrimaryText)`
  text-transform: lowercase;
`;

const CustomizationOptionFloatingContainer = styled.div`
  position: absolute;
  top: 0.8vh;
  right: 0.8vh;
`;

const ColorSelectorContainer = styled(motion.div).attrs({
  className:
    "d-flex position-absolute align-items-center justify-content-center",
})`
  top: 48.9vh; // 50vh - 1.6vh (padding) - 0.3vh (border) + 0.8vh (spacing)
  left: calc(-1.6vh - 0.3vh);
  width: calc(100% + 3.2vh + 0.6vh);
  background: ${colors.hoverTintBrown};
  border: 0.3vh solid ${colors.primaryRed};
  border-radius: 1.6vh;
  padding: 2.4vh 3.6vh;
`;

const ColorSelectorChoices = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1vh 0.5vh;
`;

const ColorSelectorChoice = styled.div.attrs({
  onMouseDown: playSecondaryClickSound,
})<{ color: string; active: boolean }>`
  width: 5vh;
  height: 5vh;
  background: ${(props) => props.color};
  border-radius: 100px;
  border: 0.3vh solid
    ${(props) => (props.active ? colors.primaryRed : "transparent")};
  transition: 0.2s all ease;

  &:hover {
    transform: translateY(-0.5vh);
  }
`;

interface CustomizationContentProps {
  layer: BackgroundLayer;
}

const CustomizationContent: React.FC<CustomizationContentProps> = ({
  layer,
}) => {
  const { displayExplorers } = useDisplayExplorers();
  const {
    hideoutLayer,
    hideoutLayerColor,
    setHideoutLayerIndex,
    setHideoutLayerColor,
  } = useHideoutLayerCustomization();

  const customizationOptions = useMemo(() => {
    const backgroundLayerMeta = backgroundLayersMetaMap[layer];

    if (!backgroundLayerMeta || !hideoutLayer) {
      return <></>;
    }

    return (
      <OptionsScrollContainer>
        <CustomizationOptions>
          {[...new Array(backgroundLayerMeta.count)]
            .map((_, index) => index)
            .filter((index) =>
              isLayerObjectUnlocked(layer, index, displayExplorers || [])
            )
            .map((index) => {
              const previewImg = getLayerPreviewImageURL(
                layer,
                index,
                hideoutLayerColor[layer]
              );
              const placeholderImg = getLayerPreviewPlaceholderImageURL(
                layer,
                index
              );

              return (
                <div
                  className="d-flex flex-column align-items-center"
                  key={index}
                >
                  <div
                    className="d-flex"
                    style={{
                      border: `${theme.border.widthHigh} ${theme.border.style} transparent`,
                      width: "100%",
                    }}
                  >
                    <CustomizationOption
                      key={index}
                      active={hideoutLayer[layer]! === index}
                      noPadding={Boolean(backgroundLayerMeta.noPaddingPreview)}
                      onClick={() => {
                        // When user change layout top, we also change layout bottom together
                        if (layer === "layouttop") {
                          setHideoutLayerIndex("layoutbottom", index);
                        }

                        setHideoutLayerIndex(layer, index);
                      }}
                    >
                      {placeholderImg ? (
                        <ImageWithPlaceholder
                          key={previewImg}
                          className="w-100 h-100"
                          src={previewImg}
                          placeholder={placeholderImg}
                          alt={`${layer} ${index}`}
                        />
                      ) : (
                        <img
                          key={previewImg}
                          className="w-100 h-100"
                          src={previewImg}
                          alt={`${layer} ${index}`}
                        />
                      )}
                      {layerCanCustomizeColor(layer, index) && (
                        <CustomizationOptionFloatingContainer>
                          <BrushIcon width="1.8vh" height="auto" />
                        </CustomizationOptionFloatingContainer>
                      )}
                    </CustomizationOption>
                  </div>
                  <CustomizationOptionName fontSize="1.6vh" lineHeight={1.5}>
                    {BackgroundLayersDisplayName[layer][index]}
                  </CustomizationOptionName>
                </div>
              );
            })}
        </CustomizationOptions>
      </OptionsScrollContainer>
    );
  }, [
    displayExplorers,
    layer,
    hideoutLayer,
    hideoutLayerColor,
    setHideoutLayerIndex,
  ]);

  return (
    <Content>
      <Header>
        <Diamond height="1.2vh" />
        <Title style={{ fontSize: "3.2vh", margin: "0 1.6vh" }}>
          {BackgroundLayerDisplay[layer]}
        </Title>
        <Diamond height="1.2vh" />
      </Header>
      {customizationOptions}

      <AnimatePresence>
        {layerCanCustomizeColor(layer, hideoutLayer[layer]!) && (
          <ColorSelectorContainer
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 50, opacity: 0 }}
            transition={{ type: "keyframes" }}
          >
            <BrushIcon height="6.3vh" style={{ marginRight: "5vh" }} />
            <ColorSelectorChoices>
              {backgroundLayersMetaMap[layer]
                .customization!.find((item) => item.type === "color")!
                .choices.map((color) => (
                  <ColorSelectorChoice
                    key={color}
                    role="button"
                    className="clickable"
                    color={color}
                    active={hideoutLayerColor[layer] === color}
                    onClick={() => setHideoutLayerColor(layer, color)}
                  />
                ))}
            </ColorSelectorChoices>
          </ColorSelectorContainer>
        )}
      </AnimatePresence>
    </Content>
  );
};

export default CustomizationContent;
