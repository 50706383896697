import { GameObjects, Scene } from "phaser";
import { API_BASE_URL } from "../config/constants";
import { colors } from "../designSystem/colors";

import { setGlobalState } from "../store/store";
import { getExplorerFullName } from "../utils/explorers";

class ExplorerPortraitObject extends GameObjects.Sprite {
  static EXPLORER_NAME_WIDTH = 76;
  static Y_OFFSET = -200;
  static X_OFFSET_NAME = -(ExplorerPortraitObject.EXPLORER_NAME_WIDTH / 2);
  static Y_OFFSET_NAME = -247;
  static Y_OFFSET_EXPLORER_IMAGE = -165;
  static DEPTH = 100000;

  lootId: string;

  explorerName?: GameObjects.Text;
  explorerImage?: GameObjects.Image;
  explorerImageMask: GameObjects.Image;

  constructor(scene: Scene, lootId: string, x: number, y: number) {
    super(
      scene,
      x,
      y + ExplorerPortraitObject.Y_OFFSET,
      "floatingExplorerInfoBox"
    );

    this.lootId = lootId;
    this.explorerImageMask = scene.make.image({
      x,
      y: y + ExplorerPortraitObject.Y_OFFSET,
      key: "floatingExplorerInfoBoxPortraitMask",
      add: false,
    });

    /**
     * Update default sprite attributes
     */
    this.setVisible(false);
    /**
     * Always on top of everything
     */
    this.depth = ExplorerPortraitObject.DEPTH;

    // Add name
    const name = getExplorerFullName(lootId);
    this.explorerName = scene.add
      .text(
        x + ExplorerPortraitObject.X_OFFSET_NAME,
        y + ExplorerPortraitObject.Y_OFFSET_NAME,
        name,
        {
          fontFamily: "GinoraSans",
          fontSize: "13px",
          color: colors.primaryRed,
          align: "center",
          fixedWidth: ExplorerPortraitObject.EXPLORER_NAME_WIDTH,
          wordWrap: { width: 30 },
        }
      )
      .setVisible(false)
      .setDepth(ExplorerPortraitObject.DEPTH + 1);

    scene.load.image(
      `explorer-${lootId}`,
      `${API_BASE_URL}/explorers-transparent/${lootId}?size=small`
    );
    scene.load.on("complete", () => {
      this.explorerImage = scene.add
        .image(
          x,
          y + ExplorerPortraitObject.Y_OFFSET_EXPLORER_IMAGE,
          `explorer-${lootId}`
        )
        .setDisplaySize(100, 100)
        .setVisible(this.visible);
      this.explorerImage.setDepth(ExplorerPortraitObject.DEPTH + 1);
      this.explorerImage.mask = new Phaser.Display.Masks.BitmapMask(
        scene,
        this.explorerImageMask
      );
    });
    scene.load.start();

    /**
     * Register onclick event
     */
    this.on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
      this.scene.sound.play("click");
      setGlobalState("explorerInfoModal", {
        show: true,
        explorerId: this.lootId,
      });
    });
  }

  setVisibleState() {
    this.setVisible(true);
    this.explorerName?.setVisible(true);
    this.explorerImage?.setVisible(true);
    this.setInteractive();
  }

  setInvisibleState() {
    this.setVisible(false);
    this.explorerName?.setVisible(false);
    this.explorerImage?.setVisible(false);
    this.disableInteractive();
  }

  toggleState() {
    if (this.visible) {
      this.setInvisibleState();
    } else {
      this.setVisibleState();
    }
  }

  update(x: number, y: number) {
    this.setX(x);
    this.setY(y + ExplorerPortraitObject.Y_OFFSET);
    this.explorerName?.setX(x + ExplorerPortraitObject.X_OFFSET_NAME);
    this.explorerName?.setY(y + ExplorerPortraitObject.Y_OFFSET_NAME);
    this.explorerImage?.setX(x);
    this.explorerImage?.setY(
      y + ExplorerPortraitObject.Y_OFFSET_EXPLORER_IMAGE
    );
    this.explorerImageMask.setX(x);
    this.explorerImageMask.setY(y + ExplorerPortraitObject.Y_OFFSET);
  }
}

export default ExplorerPortraitObject;
