import { Cameras } from "phaser";

export type PositionMeta = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export const convertScreenPositionToPhaserPosition = (
  screenPos: PositionMeta,
  camera: Cameras.Scene2D.Camera
): PositionMeta => ({
  x: screenPos.x / camera.zoom + camera.worldView.x,
  y: screenPos.y / camera.zoom + camera.worldView.y,
  width: screenPos.width / camera.zoom,
  height: screenPos.height / camera.zoom,
});
