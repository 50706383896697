import React from "react";
import styled from "styled-components";

import { useGlobalState } from "../../store/store";
import { clickableShrunk } from "../Common/style";

import useWeb3Wallet from "../../hooks/useWeb3Wallet";
import ConnectWalletBanner from "../../assets/icons/wallet/connect-wallet.png";
import ProfileBannerImg from "../../assets/icons/profile/profile-banner.png";
import ProfileBannerProfilePlaceholderImg from "../../assets/icons/profile/banner-profile-placeholder.png";
import { shortenAddress } from "../../utils/strings";
import { PrimaryText, Title } from "../../designSystem";
import { usePersonalizedData } from "../../hooks/personalizedDataContext";
import { LoadingText } from "../../hooks/useLoadingText";
import { FIREBASE_STORAGE_STATIC_URL } from "../../config/constants";
import { playClickSound } from "../../utils/sound";

const HeaderContainer = styled.div`
  position: absolute;
  top: 2vh;
  left: 2vh;
`;

const ConnectWalletButton = styled.img`
  ${clickableShrunk}

  height: 5vh;
`;

const ProfileBannerContainer = styled.div`
  ${clickableShrunk}

  position: relative;
  height: 11vh;
`;

const ProfileBanner = styled.img`
  height: 100%;
`;

const AbsoluteTextContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfileAddressContainer = styled(AbsoluteTextContainer)`
  top: 20%;
  height: 27%;
  left: 20%;
  width: 60%;
`;

const ProfileNameContainer = styled(AbsoluteTextContainer)`
  top: 65%;
  height: 22%;
  left: 24%;
  width: 62%;
`;

const ProfileNameText = styled(PrimaryText)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const AbsoluteProfileImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`;

const Identity: React.FC = () => {
  const { active, account } = useWeb3Wallet();
  const { name, bannerProfileImage, loading } = usePersonalizedData();
  const [, setShowConnectWallet] = useGlobalState("showConnectWallet");
  const [, setShowHideoutProfileModal] = useGlobalState(
    "showHideoutProfileModal"
  );

  return (
    <HeaderContainer>
      {active ? (
        <ProfileBannerContainer
          onClick={() => {
            playClickSound();
            setShowHideoutProfileModal(true);
          }}
        >
          <ProfileBanner src={ProfileBannerImg} alt="Profile" />
          <ProfileAddressContainer>
            <Title fontSize="2vh" lineHeight={1.5}>
              {shortenAddress(account)}
            </Title>
          </ProfileAddressContainer>
          <ProfileNameContainer>
            <ProfileNameText fontSize="1.5vh">
              {loading ? <LoadingText text="Loading" /> : name}
            </ProfileNameText>
          </ProfileNameContainer>
          <AbsoluteProfileImage
            src={
              bannerProfileImage
                ? `${FIREBASE_STORAGE_STATIC_URL}/${encodeURIComponent(
                    bannerProfileImage
                  )}?alt=media`
                : ProfileBannerProfilePlaceholderImg
            }
            alt="Profile Image"
          />
        </ProfileBannerContainer>
      ) : (
        <ConnectWalletButton
          src={ConnectWalletBanner}
          onClick={() => {
            playClickSound();
            setShowConnectWallet(true);
          }}
          alt="Connect Wallet"
        />
      )}
    </HeaderContainer>
  );
};

export default Identity;
