import { useEffect, useState } from "react";
import { injectedConnector } from "../utils/connectors";
import useWeb3Wallet from "./useWeb3Wallet";

const useEagerConnect = () => {
  const { activate } = useWeb3Wallet();
  const [tried, setTried] = useState(false);

  useEffect(() => {
    if (tried) {
      return;
    }

    injectedConnector.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized) {
        activate(injectedConnector, undefined, true).catch(() => {
          setTried(true);
        });
      } else {
        setTried(true);
      }
    });
  }, [activate, tried]); // intentionally only running on mount (make sure it's only mounted once :))

  return tried;
};
export default useEagerConnect;
