import React, { useEffect, useMemo, useState } from "react";
import Marquee from "react-fast-marquee";
import styled, { css, keyframes } from "styled-components";

import useScreenSize from "../../hooks/useScreenSize";
import { colors } from "../../designSystem/colors";
import sizes from "../../utils/sizes";
import { clickable } from "../Common/style";
import { FIREBASE_STORAGE_STATIC_URL } from "../../config/constants";
import { VolumeMuteIcon, VolumeUpIcon } from "../../assets/icons";
import { playSecondaryClickSound } from "../../utils/sound";
import { useGlobalState } from "../../store/store";

const CONTAINER_WIDTH = 18;
const SONG_LIST = [
  "emerald-cave",
  "explorers-march",
  "off-day",
  "warmth",
  "merlade",
  "magma",
  "lagoona",
] as const;
type SONG_TYPE = (typeof SONG_LIST)[number];
const SONG_DISPLAY: { [key in SONG_TYPE]: string } = {
  "emerald-cave": "Emerald Cave",
  "explorers-march": "Explorer's March",
  "off-day": "Off Day",
  warmth: "Warmth",
  merlade: "Merlade",
  magma: "Magma",
  lagoona: "Lagoona",
};

const Container = styled.div<{ showing: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.modalContentBackground};
  color: ${colors.brown2};

  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 20vw;
  bottom: 0;
  left: 0;

  @media (min-width: ${sizes.md}px) {
    flex-direction: column;
    bottom: 14vh;
    left: 2vh;
    width: ${CONTAINER_WIDTH}vh;
    height: ${CONTAINER_WIDTH * 1.3}vh;
  }

  transition: all 0.3s ease;
  opacity: ${({ showing }) => (showing ? 1 : 0)};
  transform: translateX(${({ showing }) => (showing ? 0 : "-20%")});
  pointer-events: ${({ showing }) => (showing ? "unset" : "none")};
`;

const TopRightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1;

  @media (min-width: ${sizes.md}px) {
    position: relative;
  }
`;

const MuteContainer = styled.div`
  padding: 1vh 1vh 0 0;
  position: absolute;
  ${clickable}
`;

const DiscContainer = styled.div`
  position: relative;
  width: 13vw;
  height: 13vw;
  border-radius: 50%;
  // Fixes overflow issues on Safari https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 24px;

  @media (min-width: ${sizes.md}px) {
    margin-left: 0;

    width: ${CONTAINER_WIDTH * 0.7}vh;
    height: ${CONTAINER_WIDTH * 0.7}vh;
  }
`;

export const rotate = (deg: string) => keyframes`
  to { 
    transform: rotate(${deg});
  }
`;

const Background = styled.div<{
  image?: string;
  animate?: boolean;
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(${({ image }) => image});
  background-size: cover;
  ${({ animate }) => {
    if (animate) {
      return css`
        animation: ${rotate("365deg")} 6s linear infinite;
      `;
    }
    return css`
      animation: ${rotate("0deg")} 6s linear infinite;
    `;
  }}
`;

const BlurEffect = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(8px);
`;

const WhiteCircle = styled.div`
  width: ${CONTAINER_WIDTH * 0.1}vh;
  height: ${CONTAINER_WIDTH * 0.1}vh;
  border-radius: 50%;
  background-color: white;
  z-index: 1;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 8px;

  @media (min-width: ${sizes.md}px) {
    flex-direction: row;
  }
`;

const SongName = styled.div`
  width: 100%;
  color: ${colors.primaryRed};
  font-size: 4vw;
  letter-spacing: 0.05vh;

  @media (min-width: ${sizes.md}px) {
    font-size: 1.5vh;
  }
`;

const MusicPlayer: React.FC = () => {
  const { width } = useScreenSize();
  const [song, setSong] = useState<SONG_TYPE>(SONG_LIST[0]);
  const [audioEl, setAudioEl] = useState<HTMLAudioElement>();
  const [muted, setMuted] = useState(false);
  const [mode] = useGlobalState("mode");

  useEffect(() => {
    const audio = new Audio(
      `${FIREBASE_STORAGE_STATIC_URL}/${encodeURIComponent(
        `hideout/bgm/${song}.mp3`
      )}?alt=media`
    );

    /**
     * Set to next song once the song ended
     */
    audio.addEventListener("ended", () => {
      audio.remove();
      setSong(SONG_LIST[(SONG_LIST.indexOf(song) + 1) % SONG_LIST.length]);
    });

    setAudioEl(audio);
  }, [song]);

  useEffect(() => {
    if (audioEl) {
      audioEl.muted = muted;
      audioEl.play().catch(() => setMuted(true));
    }
  }, [audioEl, muted]);

  const marqueeWidth = useMemo(() => {
    if (width <= sizes.md) {
      return "65vw";
    }
    return `${CONTAINER_WIDTH * 0.7}vh`;
  }, [width]);

  return (
    <Container showing={!mode}>
      <TopRightContainer
        onMouseDown={playSecondaryClickSound}
        onClick={() => setMuted((prev) => !prev)}
      >
        <MuteContainer>
          {muted ? (
            <VolumeMuteIcon width="1.8vh" height="auto" />
          ) : (
            <VolumeUpIcon width="1.8vh" height="auto" />
          )}
        </MuteContainer>
      </TopRightContainer>
      <DiscContainer>
        <Background
          animate={true}
          image={`${FIREBASE_STORAGE_STATIC_URL}/${encodeURIComponent(
            `hideout/bgm/${song}.webp`
          )}?alt=media`}
        >
          <BlurEffect />
        </Background>
        <WhiteCircle />
      </DiscContainer>
      <DetailsContainer>
        <Marquee
          style={{
            width: marqueeWidth,
          }}
          gradient={false}
          speed={width * 0.01}
        >
          <SongName>{SONG_DISPLAY[song]}</SongName>&nbsp;
        </Marquee>
      </DetailsContainer>
    </Container>
  );
};

export default MusicPlayer;
