import { GameObjects, Scene } from "phaser";

import {
  PositionMeta,
  convertScreenPositionToPhaserPosition,
} from "../utils/camera";

class StopPropagationGameObject extends GameObjects.Rectangle {
  scene: Scene;
  domPosition: PositionMeta;

  constructor(_scene: Scene, _position: PositionMeta) {
    const { x, y, width, height } = convertScreenPositionToPhaserPosition(
      _position,
      _scene.cameras.main
    );
    super(_scene, x, y, width, height, 0x000000, 0x00);
    this.setOrigin(0);
    this.setDepth(99999);

    this.domPosition = _position;
    this.scene = _scene;
  }

  update() {
    const { x, y, width, height } = convertScreenPositionToPhaserPosition(
      this.domPosition,
      this.scene.cameras.main
    );
    this.setPosition(x, y);
    this.setSize(width, height);
  }
}

export default StopPropagationGameObject;
