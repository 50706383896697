import Phaser from "phaser";
import { Coordinate } from "../model/direction";

export const randInt = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export function randElem<T>(array: Array<T>): T {
  return array[Math.floor(Math.random() * array.length)];
}

/**
 * Calculate next coordinate of moving object
 * @param currCoordinate Current coordinate of the moving object
 * @param destCoordinate Final destination of the object moving towards
 * @param timeDelta (millisecond) Time delta that past during calculation frame
 * @param speed Speed of moving object
 * @returns
 */
export function calculateNextCoordinate(
  currCoordinate: Coordinate,
  destCoordinate: Coordinate,
  timeDelta: number,
  speed: number
): { coordinate: Coordinate; delta: { x: number; y: number } } {
  const rad = Phaser.Math.Angle.Between(
    currCoordinate.x,
    currCoordinate.y,
    destCoordinate.x,
    destCoordinate.y
  );

  const xDelta = ((speed * timeDelta) / 1000) * Math.cos(rad);
  const yDelta = ((speed * timeDelta) / 1000) * Math.sin(rad);

  const remainingDistance = Phaser.Math.Distance.Between(
    currCoordinate.x,
    currCoordinate.y,
    destCoordinate.x,
    destCoordinate.y
  );
  const distanceToNewCoordinate = Phaser.Math.Distance.Between(
    currCoordinate.x,
    currCoordinate.y,
    currCoordinate.x + xDelta,
    currCoordinate.y + yDelta
  );

  return remainingDistance > distanceToNewCoordinate
    ? {
        coordinate: {
          x: currCoordinate.x + xDelta,
          y: currCoordinate.y + yDelta,
        },
        delta: { x: xDelta, y: yDelta },
      }
    : {
        coordinate: destCoordinate,
        delta: {
          x: destCoordinate.x - currCoordinate.x,
          y: destCoordinate.y - currCoordinate.y,
        },
      };
}

export function hexStringToNumber(hexString: string): number {
  // Remove the '#' symbol if present
  hexString = hexString.replace("#", "");

  // Convert the hexadecimal string to a number using base 16
  const hexNumber = parseInt(hexString, 16);

  // Return the number
  return hexNumber;
}
