import { randElem } from "../utils/math";
import {
  Boundary,
  Coordinate,
  DirectionMeta,
  directionsMap,
} from "./direction";

export const WALKING_BOUNDARIES: readonly Boundary[] = [
  // Upper floor of hideout
  [
    {
      x: 1620,
      y: 740,
    },
    {
      x: 1570,
      y: 1080,
    },
  ],
  [
    {
      x: 2030,
      y: 1280,
    },
    {
      x: 2240,
      y: 1580,
    },
  ],
];

export const animationTypes = [
  "idle",
  "walk",
  "bed",
  "scoping_tower",
  "interact_station",
] as const;
export type AnimationType = (typeof animationTypes)[number];

export const explorerOutfitList = ["tunic", "vest", "robe"] as const;
export type ExplorerOutfit = (typeof explorerOutfitList)[number];

export const explorerBodyMaskLayers = ["pants"] as const;
export type ExplorerBodyMaskLayer = (typeof explorerBodyMaskLayers)[number];

export const explorerBodyLayers = [
  ...explorerBodyMaskLayers.map((maskLayer) => `${maskLayer}_mask`),
  ...explorerOutfitList,
];

export const animationTypeMovements = [
  "hip",
  "standing",
  "sway",
  "reading",
  "thinking",
  "thinking_from_standing",
  "thinking_to_standing",
  "a",
  "b",
] as const;
export type AnimationTypeMovement = (typeof animationTypeMovements)[number];
export const animationTypeMeta = {
  idle: {
    type: "multi",
    movements: [
      "hip",
      "standing",
      "sway",
      "thinking_from_standing",
      "thinking",
      "thinking_to_standing",
    ],
    startingMovements: ["hip", "standing", "sway", "thinking_from_standing"],
    direction: {
      thinking_from_standing: [
        directionsMap["southEast"],
        directionsMap["southWest"],
      ],
    } as Partial<{ [movement in AnimationTypeMovement]: DirectionMeta[] }>,
  },
  walk: { type: "single" },
  bed: {
    type: "multi",
    movements: ["reading"],
    direction: "southWest",
    availableBoundary: WALKING_BOUNDARIES[0],
    pathIn: [
      { x: 1505, y: 1025 },
      { x: 1245, y: 1155 },
      { x: 1155, y: 1110 },
    ] as Coordinate[],
    pathOut: [
      { x: 1245, y: 1155 },
      { x: 1505, y: 1025 },
    ] as Coordinate[],
    bedAttr: {
      action: {
        stage: "bed",
        direction: directionsMap["southWest"],
        remainingLength: 15000,
      },
      x: 1110,
      y: 975,
    },
  },
  scoping_tower: {
    type: "single",
    direction: "northEast",
    availableBoundary: WALKING_BOUNDARIES[0],
    pathIn: [{ x: 1825, y: 850 }] as Coordinate[],
    scopingAttr: {
      action: {
        stage: "scoping_tower",
        direction: directionsMap["northEast"],
        remainingLength: 20000,
      },
      x: 2205,
      y: 250,
    },
  },
  interact_station: {
    type: "multi",
    movements: ["a", "b"],
    direction: {
      a: [directionsMap["northWest"]],
      b: [directionsMap["northEast"]],
    } as { [movement in "a" | "b"]: DirectionMeta[] },
    availableBoundary: WALKING_BOUNDARIES[1],
    workspacesright: {
      pathIn: [
        { x: 2050, y: 1485 },
        { x: 1825, y: 1597.5 },
      ] as Coordinate[],
      pathOut: [{ x: 2040, y: 1480 }] as Coordinate[],
      workspaceAttr: {
        action: {
          stage: "interact_station",
          direction: directionsMap["northWest"],
          remainingLength: (54 / 24) * 1000,
        },
        x: 1740,
        y: 1555,
        overrideDepth: 1501,
      },
    },
    workspacesleft: {
      pathIn: [
        { x: 2050, y: 1485 },
        { x: 1625, y: 1697.5 },
      ] as Coordinate[],
      pathOut: [{ x: 2040, y: 1480 }] as Coordinate[],
      workspaceAttr: {
        action: {
          stage: "interact_station",
          direction: directionsMap["northWest"],
          remainingLength: (54 / 24) * 1000,
        },
        x: 1545,
        y: 1657.5,
        overrideDepth: 9999,
      },
    },
  },
} as const;

type ExplorerActionWalkAttribute = {
  destination: Coordinate;
  next: Coordinate[];
  distanceRemaining: number;
};

export type ExplorerAction =
  | ({
      direction: DirectionMeta;
    } & (
      | ({
          motion: "walk";
        } & ExplorerActionWalkAttribute)
      | {
          motion: "idle";
          movement: AnimationTypeMovement;
          remainingLength: number;
        }
    ))
  /**
   * Below are used to define action that does not have full range of direction
   */
  | ({
      motion: "bed";
      movement: AnimationTypeMovement;
    } & (
      | ({
          stage: "walkIn" | "walkOut";
          direction: DirectionMeta;
        } & ExplorerActionWalkAttribute)
      | {
          stage: "bed";
          direction: (typeof directionsMap)["southWest"];
          remainingLength: number;
        }
    ))
  | ({ motion: "scoping_tower" } & (
      | ({
          stage: "walkIn" | "walkOut";
          direction: DirectionMeta;
        } & ExplorerActionWalkAttribute)
      | {
          stage: "scoping_tower";
          direction: (typeof directionsMap)["northEast"];
          remainingLength: number;
        }
    ))
  | ({
      motion: "interact_station";
      movement: AnimationTypeMovement;
      occupiedStation: "workspacesleft" | "workspacesright";
    } & (
      | ({
          stage: "walkIn" | "walkOut";
          direction: DirectionMeta;
        } & ExplorerActionWalkAttribute)
      | {
          stage: "interact_station";
          direction: (typeof directionsMap)["northEast"];
          remainingLength: number;
        }
    ));

export const getInitialExplorerAction = (): ExplorerAction => ({
  motion: "idle",
  movement: randElem([...animationTypeMeta.idle.movements]),
  direction: randElem(Object.values(directionsMap)),
  remainingLength: 0,
});
