export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://api.lootexplorers.quest";

export const FIRESTORE_COLLECTION = {
  PERSONALIZED_DATA: "hideout-settings",
};

export const OPENSEA_COLLECTION_BASE_URL =
  "https://opensea.io/assets/ethereum/0x508d06b8f3a4b0fd363239ce61e0c4b0b82f3626";

export const FIREBASE_STORAGE_STATIC_URL =
  "https://firebasestorage.googleapis.com/v0/b/lootexplorers-feff0.appspot.com/o";
