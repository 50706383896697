export type TitleFlagSVGProps = React.SVGAttributes<SVGElement> & {
  color?: string;
  backgroundColor?: string;
  contentRef?: React.RefObject<SVGPathElement>;
};

export const TitleFlagSVG: React.FC<TitleFlagSVGProps> = (props) => (
  <svg
    width="491"
    height="74"
    viewBox="0 0 491 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M488.156 71.6419H412V16.338H488.156L468.149 43.9899L488.156 71.6419Z"
      fill={props.backgroundColor || "#FFF2E9"}
    />
    <path
      d="M412 15V16.338M412 16.338V71.6419H488.156L468.149 43.9899L488.156 16.338H412Z"
      stroke={props.color || "#FC5B49"}
      strokeWidth="2"
    />
    <path
      d="M2.00004 72.6419H78.1559V17.338H2.00004L22.0071 44.9899L2.00004 72.6419Z"
      fill={props.backgroundColor || "#FFF2E9"}
    />
    <path
      d="M78.1559 16V17.338M78.1559 17.338V72.6419H2.00004L22.0071 44.9899L2.00004 17.338H78.1559Z"
      stroke={props.color || "#FC5B49"}
      strokeWidth="2"
    />
    <path
      d="M412 71.1923L451.529 56.3576V31.1834H412V71.1923Z"
      fill={props.color || "#FC5B49"}
      stroke={props.color || "#FC5B49"}
    />
    <path
      d="M78.1559 72.1923L38.6274 57.3575V32.1834H78.1559V72.1923Z"
      fill={props.color || "#FC5B49"}
      stroke={props.color || "#FC5B49"}
    />

    {/* Box */}
    <path
      ref={props.contentRef}
      d="M39 1H451V56H39V1Z"
      fill={props.backgroundColor || "#FFF2E9"}
      stroke={props.color || "#FC5B49"}
      strokeWidth="2"
    />

    <rect
      x="58.2145"
      y="24"
      width="7.37438"
      height="7.37438"
      transform="rotate(45 58.2145 24)"
      fill={props.color || "#FC5B49"}
    />
    <rect
      x="432.214"
      y="24"
      width="7.37438"
      height="7.37438"
      transform="rotate(45 432.214 24)"
      fill={props.color || "#FC5B49"}
    />
    <rect
      x="58.2145"
      y="24"
      width="7.37438"
      height="7.37438"
      transform="rotate(45 58.2145 24)"
      fill={props.color || "#FC5B49"}
    />
  </svg>
);
