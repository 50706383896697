export function mergeObjects(obj1: any, obj2: any): Object {
  const newObj = { ...obj1 };
  for (const prop in obj2) {
    if (obj2.hasOwnProperty(prop)) {
      if (
        newObj.hasOwnProperty(prop) &&
        typeof newObj[prop] === "object" &&
        typeof obj2[prop] === "object" &&
        !Array.isArray(newObj[prop]) &&
        !Array.isArray(obj2[prop])
      ) {
        newObj[prop] = mergeObjects(newObj[prop], obj2[prop]);
      } else {
        newObj[prop] = obj2[prop];
      }
    }
  }

  return newObj;
}
