import React from "react";
import styled, { keyframes } from "styled-components";
import { AnimatePresence, motion } from "framer-motion";

import { Title } from "../../designSystem";
import { colors } from "../../designSystem/colors";

const Background = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${colors.modalContentBackground};
`;

const FloatingContainer = styled(motion.div)`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

/**
 * Following animation are defined in an analogy where the entire
 * animation cycle are made up of 48 frames.
 *
 * Animations are defined as follow
 * (frame + delay) / total frames
 *
 * frame        : specific frame within the animation cycle
 * delay        : number of frame delay compared to the baseline
 *                (compared to first letter in this case)
 * total frames : total number of frames
 */
const textAnimation = (delay: number) => keyframes`
  0% {
    transform: translateY(0%);
  }

  ${((9 + delay) / 48) * 100}% {
    transform: translateY(0%);
  }

  ${((11 + delay) / 48) * 100}% {
    transform: translateY(1%);
  }

  ${((15 + delay) / 48) * 100}% {
    transform: translateY(-1%);
  }

  ${((18 + delay) / 48) * 100}% {
    transform: translateY(-18%);
  }

  ${((20 + delay) / 48) * 100}% {
    transform: translateY(-20%);
  }

  ${((23 + delay) / 48) * 100}% {
    transform: translateY(-21%);
  }

  ${((27 + delay) / 48) * 100}% {
    transform: translateY(-20%);
  }

  ${((30 + delay) / 48) * 100}% {
    transform: translateY(-18%);
  }

  ${((32 + delay) / 48) * 100}% {
    transform: translateY(1.5%);
  }

  ${((35 + delay) / 48) * 100}% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(0%);
  }
`;

const TitleChar = styled(Title).attrs({
  fontSize: "17.5vh",
  fontWeight: 500,
  color: colors.hoverTintBrown,
})<{ index: number }>`
  text-shadow: 0 1vh ${colors.shadowRed};
  animation: 2s ${(props) => textAnimation(props.index)} linear infinite;
`;

interface LoadingSceneProps {
  show: boolean;
}

const LoadingScene: React.FC<LoadingSceneProps> = ({ show }) => {
  return (
    <AnimatePresence>
      {show && (
        <Background
          key="loading-bg"
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        />
      )}
      {show && (
        <FloatingContainer
          key="loading-text"
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {Array.from("HIDEOUTS").map((char, index) => (
            <TitleChar key={index} index={index}>
              {char}
            </TitleChar>
          ))}
        </FloatingContainer>
      )}
    </AnimatePresence>
  );
};

export default LoadingScene;
