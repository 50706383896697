import { Coordinate, Direction } from "../model/direction";

/**
 * Get direction from 2 coordinate in isomatric
 * @param from Coordinate of from location
 * @param to Coordinate of destination
 * @returns Direction
 */
export const getIsometricDirectionFromPixel = (
  from: Coordinate,
  to: Coordinate
): Direction => {
  if (from.x > to.x && from.y > to.y) {
    return "northWest";
  }

  if (from.x < to.x && from.y < to.y) {
    return "southEast";
  }

  if (from.x > to.x) {
    return "southWest";
  }

  return "northEast";
};

/**
 * Get turnpoint from 2 different coordinate
 * @param from Coordinate of first point
 * @param to Coordinate of second point
 * @returns [Coordinate, Coordinate]
 */
export const getIsometricTurnPointFromPixel = (
  point1: Coordinate,
  point2: Coordinate
): [Coordinate, Coordinate] => {
  const [higherPoint, lowerPoint] =
    point1.y < point2.y ? [point1, point2] : [point2, point1];

  /**
   * xDeficit can be positive or negative.
   * Positive: Upper point is to the right of the lower point
   * Negative: Lower point is to the right of the upper point
   */
  const xDeficit = higherPoint.x - lowerPoint.x;

  /**
   * We try to get the 2 different points where the first point having it's x
   * align with the bottom point, where as the second point having it's x align
   * with the top point.
   * We then calculate their deficit in y
   */
  const topXAlignPoint: Coordinate = {
    x: lowerPoint.x,
    y: higherPoint.y + Math.abs(xDeficit / 2),
  };
  const topYDeficit = lowerPoint.y - topXAlignPoint.y;
  const bottomXAlignPoint: Coordinate = {
    x: higherPoint.x,
    y: lowerPoint.y - Math.abs(xDeficit / 2),
  };
  const bottomYDeficit = bottomXAlignPoint.y - higherPoint.y;

  /**
   * Finally, we use the 2 x align point found above to find the turning point.
   * The first returned value are calculated using the point where it's x
   * aligned with the bottom point.
   * The second returned value are calculated using the point where it's x
   * aligned with the top point.
   */
  return [
    {
      x: xDeficit > 0 ? lowerPoint.x - topYDeficit : lowerPoint.x + topYDeficit,
      y: topXAlignPoint.y + topYDeficit / 2,
    },
    {
      x:
        xDeficit > 0
          ? higherPoint.x + bottomYDeficit
          : higherPoint.x - bottomYDeficit,
      y: higherPoint.y + bottomYDeficit / 2,
    },
  ];
};

export const distanceBetweenCoordinates = (
  coord1: Coordinate,
  coord2: Coordinate
): number => {
  const dx = coord2.x - coord1.x;
  const dy = coord2.y - coord1.y;
  return Math.sqrt(dx * dx + dy * dy);
};

export const areCoordinatesWithinRadius = (
  coord1: Coordinate,
  coord2: Coordinate,
  radius: number
): boolean => {
  const distance = distanceBetweenCoordinates(coord1, coord2);
  return distance <= radius;
};
