import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useState } from "react";

import { FIRESTORE_COLLECTION } from "../config/constants";
import { personalizedDataFirestoreConverter } from "../model/firestore";
import { PersonalizedData } from "../model/state";
import { firestore } from "../utils/firebase";
import { mergeObjects } from "../utils";

const useSavedPersonalizedData = (account?: string | null) => {
  const [settings, setSettings] = useState<PersonalizedData | undefined>();
  const [loading, setLoading] = useState(true);
  const [, setCounter] = useState(0);

  const settingsDocRef = useMemo(
    () =>
      account
        ? doc(
            collection(firestore, FIRESTORE_COLLECTION.PERSONALIZED_DATA),
            account
          ).withConverter(personalizedDataFirestoreConverter)
        : undefined,
    [account]
  );

  const fetchSavedPersonalizedData = useCallback(async () => {
    // If no account, we simply reset all the state to nothing
    if (!settingsDocRef) {
      setSettings(undefined);
      setLoading(false);
      return;
    }

    // Fetch saved personalized data
    setLoading(true);
    let currentCounter: number;
    setCounter((_counter) => {
      currentCounter = _counter + 1;
      return currentCounter;
    });

    const settingsSnapshot = await getDoc(settingsDocRef);
    const settingsData = settingsSnapshot.data();

    setCounter((_counter) => {
      if (currentCounter === _counter) {
        setSettings(settingsData);
        setLoading(false);
      }
      return _counter;
    });
  }, [settingsDocRef]);

  const syncPersonalizedData = useCallback(
    (data: Partial<PersonalizedData>) => {
      if (!settingsDocRef) {
        return;
      }

      setDoc(settingsDocRef, data, { merge: true });
      setSettings((prev) => mergeObjects(prev || {}, data) as PersonalizedData);
    },
    [settingsDocRef]
  );

  useEffect(() => {
    fetchSavedPersonalizedData();
  }, [fetchSavedPersonalizedData]);

  return { settings, syncPersonalizedData, loading };
};

export default useSavedPersonalizedData;
