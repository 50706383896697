import React, { useEffect, useMemo, useState } from "react";

import { usePersonalizedData } from "../../../hooks/personalizedDataContext";
import useSiwe from "../../../hooks/useSiwe";
import { useGlobalState } from "../../../store/store";
import BasicModal from "../../Common/BasicModal";
import SingleFieldFormModalContent, {
  SingleFieldFormModalContentSize,
} from "../../Common/Modal/SingleFieldFormModalContent";
import ExplorersSquadContent from "./ExplorersSquadContent";
import ExplorersSquadEditContent from "./ExplorersSquadEditContent";

const modeList = ["squad", "editName", "editSquad"] as const;
type Mode = (typeof modeList)[number];

const MODE_MODAL_SIZE: { [mode in Mode]: { width: string; height: string } } = {
  squad: {
    width: "72.4vh",
    height: "34.2vh",
  },
  editName: SingleFieldFormModalContentSize,
  editSquad: {
    width: "72.4vh",
    height: "69.2vh",
  },
};

const ExplorersSelectionModal: React.FC = () => {
  const [mode, setMode] = useState<Mode>(modeList[0]);
  const { token } = useSiwe();
  const [showExplorersModal, setShowExplorersModal] =
    useGlobalState("showExplorersModal");
  const [, setShowSiweModal] = useGlobalState("showSiweModal");
  const { name, updateFieldData } = usePersonalizedData();

  useEffect(() => {
    setMode(modeList[0]);
  }, [showExplorersModal]);

  const modalContent = useMemo(() => {
    switch (mode) {
      case "squad":
        return (
          <ExplorersSquadContent
            onEditSquad={() => {
              if (!token) {
                setShowExplorersModal(false);
                setShowSiweModal(true);
                return;
              }

              setMode("editSquad");
            }}
            onEditName={() => {
              if (!token) {
                setShowExplorersModal(false);
                setShowSiweModal(true);
                return;
              }

              setMode("editName");
            }}
          />
        );
      case "editName":
        return (
          <SingleFieldFormModalContent
            initialValue={name}
            placeholder="Profile Name"
            validateValue={(value) => value.length > 24}
            onSubmit={(value) => {
              updateFieldData("name", value.trim());

              setMode("squad");
            }}
          />
        );
      case "editSquad":
        return <ExplorersSquadEditContent onReturn={() => setMode("squad")} />;
    }
  }, [
    mode,
    setShowExplorersModal,
    setShowSiweModal,
    name,
    token,
    updateFieldData,
  ]);

  return (
    <BasicModal
      name="explorerselection"
      show={showExplorersModal}
      onClose={() => setShowExplorersModal(false)}
      height={MODE_MODAL_SIZE[mode].height}
      maxWidth={MODE_MODAL_SIZE[mode].width}
      animationProps={{
        key: mode,
        transition: {
          duration: 0.25,
          type: "keyframes",
          ease: "easeInOut",
        },
        initial: {
          opacity: 0,
        },
        animate: {
          opacity: 1,
        },
        exit: {
          opacity: 0,
        },
      }}
    >
      {modalContent}
    </BasicModal>
  );
};

export default ExplorersSelectionModal;
