import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Buffer } from "buffer";

import {
  API_BASE_URL,
  OPENSEA_COLLECTION_BASE_URL,
} from "../../config/constants";
import { BaseLink, PrimaryText, Title } from "../../designSystem";
import { colors } from "../../designSystem/colors";
import theme from "../../designSystem/theme";
import { useGlobalState } from "../../store/store";
import {
  getExplorerFullName,
  getExplorerMetadata,
} from "../../utils/explorers";
import BasicModal from "../Common/BasicModal";
import { useDisplayExplorers } from "../../hooks/useDisplayExplorers";
import PortraitBackground from "../../assets/portraitBackground";
import { getRaceLogoSrc } from "../../assets/logo/race/allegiance";
import { getAllegianceLogoSrc } from "../../assets/logo/allegiance/allegiance";
import { getOriginsLogoSrc } from "../../assets/logo/origins/origins";

const PortraitContainer = styled.div.attrs({
  className:
    "d-flex align-items-center justify-content-center position-relative",
})`
  width: 42.2vh;
  height: 34.3vh;
`;

const PortraitImage = styled.img`
  height: 32vh;
  width: 32vh;
  border-radius: ${theme.border.radiusBig};
  border: 0.1vh solid ${colors.primaryRed};
  z-index: 1;
`;

const LeftInfoContainer = styled.div.attrs({
  className: "d-flex flex-column",
})`
  position: absolute;
  top: 1.6vh;
  left: 1.6vh;
  width: 11.7vh;
`;

const ExplorerSelectionHyperlink = styled(BaseLink)`
  font-size: 1.4vh;
  &:hover {
    opacity: ${theme.hover.opacity};
  }
`;

const RightInfoContainer = styled.div.attrs({
  className: "d-flex flex-column align-items-end",
})`
  position: absolute;
  top: 1.6vh;
  right: 0.8vh;
  width: 12.5vh;
  text-align: right;
  height: calc(100% - 1.6vh - 2.4vh);
`;

const RightInfoLogo = styled.div<{
  imageUrl: string;
}>`
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  width: 4vh;
  margin-left: 0.8vh;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
`;

const ExplorerInfoModal = () => {
  const [explorerInfoModal, setExplorerInfoModal] =
    useGlobalState("explorerInfoModal");
  const { displayExplorers } = useDisplayExplorers();

  const explorerMetadata = useMemo(() => {
    if (explorerInfoModal.explorerId) {
      return getExplorerMetadata(explorerInfoModal.explorerId);
    }

    return undefined;
  }, [explorerInfoModal.explorerId]);

  const [imageData, setImageData] = useState<{
    [key: string]: string;
  }>({});

  const loadImage = useCallback(async () => {
    const imagesToLoad: string[] =
      displayExplorers?.filter((explorerId) => !imageData[explorerId]) || [];

    if (imagesToLoad.length <= 0) {
      return;
    }

    const explorersImageBuffer = Object.fromEntries(
      await Promise.all(
        imagesToLoad.map(async (explorerId) => {
          const res = await axios.get(
            `${API_BASE_URL}/explorers/${explorerId}`,
            {
              responseType: "arraybuffer",
            }
          );

          return [
            explorerId,
            Buffer.from(res.data, "binary").toString("base64"),
          ] as [string, string];
        })
      )
    );

    setImageData((_imageData) => ({
      ..._imageData,
      ...explorersImageBuffer,
    }));
  }, [displayExplorers, imageData]);

  useEffect(() => {
    loadImage();
  }, [loadImage]);

  const handleModalClose = useCallback(() => {
    setExplorerInfoModal((prev) => ({ ...prev, show: false }));
  }, [setExplorerInfoModal]);

  const modalContent = useMemo(() => {
    if (
      !explorerInfoModal.explorerId ||
      !explorerInfoModal.show ||
      !explorerMetadata
    ) {
      return <></>;
    }

    const explorerName = getExplorerFullName(explorerInfoModal.explorerId);

    return (
      <div className="d-flex h-100 w-100 align-items-center justify-content-center position-relative">
        <PortraitContainer>
          {/* Background */}
          <div className="position-absolute">
            <PortraitBackground width="42.2vh" height="34.3vh" />
          </div>

          <PortraitImage
            // TODO: Add placeholder image
            src={
              imageData[explorerInfoModal.explorerId]
                ? `data:image/jpeg;base64,${
                    imageData[explorerInfoModal.explorerId]
                  }`
                : `${API_BASE_URL}/explorers/${explorerInfoModal.explorerId}`
            }
            alt={`${explorerName} #${explorerInfoModal.explorerId}`}
          />
        </PortraitContainer>

        <LeftInfoContainer>
          <Title fontSize="2vh" style={{ marginBottom: "1.6vh" }}>
            {explorerName}
          </Title>
          <ExplorerSelectionHyperlink
            href={`${OPENSEA_COLLECTION_BASE_URL}/${explorerInfoModal.explorerId}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            &#128279;
          </ExplorerSelectionHyperlink>
        </LeftInfoContainer>

        <RightInfoContainer>
          <div className="d-flex">
            <RightInfoLogo
              imageUrl={getRaceLogoSrc(explorerMetadata.primaryType)}
            />
            <RightInfoLogo
              imageUrl={getAllegianceLogoSrc(explorerMetadata.allegiance)}
            />
            <RightInfoLogo
              imageUrl={getOriginsLogoSrc(explorerMetadata.origins)}
            />
          </div>
          {[
            explorerMetadata.background,
            explorerMetadata.headName,
            explorerMetadata.faceName,
            explorerMetadata.weaponVariation.name,
            explorerMetadata.headArmorVariation.name,
            explorerMetadata.waistArmorVariation.name,
            explorerMetadata.chestArmorVariation.name,
          ]
            .filter((text) => text)
            .map((text, index) => (
              <PrimaryText
                fontSize="1.2vh"
                className={`${index === 0 ? "mt-auto" : "mt-1"}`}
                key={index}
              >
                {text?.toLowerCase()}
              </PrimaryText>
            ))}
        </RightInfoContainer>
      </div>
    );
  }, [explorerInfoModal, explorerMetadata, imageData]);

  return (
    <BasicModal
      name="explorerinfo"
      show={explorerInfoModal.show}
      onClose={handleModalClose}
      height="40.6vh"
      maxWidth={{ xs: "90vw", md: "72.6vh" }}
    >
      {modalContent}
    </BasicModal>
  );
};

export default ExplorerInfoModal;
