import { UnsupportedChainIdError } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { ReactComponent as MetamaskIcon } from "../../assets/icons/metamask.svg";
import { ReactComponent as WalletConnectIcon } from "../../assets/icons/walletconnect.svg";
import { chainIdsMapping, supportedChainIDs } from "../../config/env";
import {
  ConnectorType,
  getWalletConnectConnector,
  injectedConnector,
} from "../../utils/connectors";
import BasicModal from "../Common/BasicModal";
import { colors } from "../../designSystem/colors";
import { clickable } from "../Common/style";
import { useGlobalState } from "../../store/store";
import useWeb3Wallet from "../../hooks/useWeb3Wallet";
import { PrimaryText, Title } from "../../designSystem";
import { Diamond } from "../../assets/icons";
import { playSecondaryClickSound } from "../../utils/sound";

interface ConnectorButtonProps {
  status: "normal" | "initializing" | "neglected" | "connected";
  isConnected: boolean;
  isDisabled?: boolean;
  wrongNetwork?: boolean;
}

const ICON_WIDTH = "4vh";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.6vh;
  padding-bottom: 2.4vh;
`;

const ConnectorButton = styled.button<ConnectorButtonProps>`
  ${({ wrongNetwork }) => (wrongNetwork ? "cursor: default;" : clickable)}
  background-color: ${colors.modalContentBackground};
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  margin: 0 0.8vh 1.6vh 0.8vh;
  padding: 1.6vh;
  border-radius: 0.8vh;
  transition: 0.25s background-color ease;
  border: ${({ isConnected }) => {
    return isConnected ? `0.3vh solid ${colors.primaryRed}` : "none";
  }};
  opacity: ${({ isDisabled }) => {
    return isDisabled ? "0.5" : "1";
  }};

  span {
    transition: 0.25s color ease;
  }

  ${(props) => {
    switch (props.status) {
      case "neglected":
        return `
          opacity: 0.24;

          &:hover {
            opacity: 0.24;
          }
        `;
      case "initializing":
        return `
          &:hover {
            opacity: 1;
          }
        `;
      default:
        return props.wrongNetwork
          ? ""
          : `
            &:hover {
              background-color: ${colors.primaryRed};

              span {
                color: ${colors.modalContentBackground};
              }
            }
          `;
    }
  }}
`;

const IconContainer = styled.div`
  background-color: white;
  padding: 0.4vh;
  border-radius: 0.8vh;
`;

const StatusText = styled.span`
  color: ${colors.brown};
  text-align: center;
  padding: 0.8vh 2.4vh;
`;

const WalletConnectModal: React.FC = () => {
  const [showConnectWallet, setShowConnectWallet] =
    useGlobalState("showConnectWallet");
  const { connector, activate, error, library, account, active, deactivate } =
    useWeb3Wallet();
  const [connectingConnector, setConnectingConnector] =
    useState<ConnectorType>();

  const connectedTo: ConnectorType | undefined = useMemo(() => {
    return library && account
      ? library.connection.url === "metamask"
        ? "metamask"
        : "walletConnect"
      : undefined;
  }, [account, library]);

  const isUnsupportedNetwork = useMemo(() => {
    return error instanceof UnsupportedChainIdError;
  }, [error]);

  const onHide = useCallback(() => {
    setShowConnectWallet(false);
  }, [setShowConnectWallet]);

  const handleDisconnect = useCallback(
    (closeModal?: boolean) => {
      if (connector && connector instanceof WalletConnectConnector) {
        const wcConnector = connector as WalletConnectConnector;
        wcConnector.close();
      }
      deactivate();

      if (closeModal) {
        onHide();
      }
    },
    [connector, deactivate, onHide]
  );

  const handleConnect = useCallback(
    async (type: ConnectorType) => {
      // Disconnect wallet if currently connected already
      if (connectedTo) {
        handleDisconnect(false);
        return;
      }

      // Connect wallet
      setConnectingConnector(type);
      switch (type) {
        case "metamask":
          await activate(injectedConnector);
          break;
        case "walletConnect":
          await activate(getWalletConnectConnector());
          break;
      }
      setConnectingConnector(undefined);
      onHide();
    },
    [activate, connectedTo, handleDisconnect, onHide]
  );

  const getConnectorStatus = useCallback(
    (ConnectorType: ConnectorType) => {
      // If connected, check if current button is connected
      if (active) {
        switch (ConnectorType) {
          case "metamask":
            if (connector instanceof InjectedConnector) return "connected";
            break;
          case "walletConnect":
            if (connector instanceof WalletConnectConnector) return "connected";
            break;
        }
      }

      // Check initializing status
      switch (connectingConnector) {
        case undefined:
          return "normal";
        case ConnectorType:
          return "initializing";
      }
      return "neglected";
    },
    [active, connector, connectingConnector]
  );

  const renderConnectorIcon = useCallback((type: ConnectorType) => {
    switch (type) {
      case "metamask":
        return <MetamaskIcon width={ICON_WIDTH} height={ICON_WIDTH} />;
      case "walletConnect":
        return <WalletConnectIcon width={ICON_WIDTH} height={ICON_WIDTH} />;
    }
  }, []);

  const renderConnectorButton = useCallback(
    (type: ConnectorType, title: string) => {
      return (
        <ConnectorButton
          role="button"
          className="clickable"
          disabled={
            isUnsupportedNetwork || (connectedTo && connectedTo !== type)
          }
          isDisabled={
            isUnsupportedNetwork || (connectedTo && connectedTo !== type)
          }
          wrongNetwork={
            isUnsupportedNetwork || (connectedTo && connectedTo !== type)
          }
          onClick={() => {
            playSecondaryClickSound();
            handleConnect(type);
          }}
          status={getConnectorStatus(type)}
          isConnected={connectedTo === type}
        >
          <PrimaryText
            fontSize="1.8vh"
            lineHeight={1.5}
            fontWeight={700}
            className="mx-2"
          >
            {connectingConnector === type ? "Loading..." : title}
            {connectedTo === type && (
              <span style={{ fontSize: "0.7rem", paddingLeft: "8px" }}>
                (Connected. Click to disconnect)
              </span>
            )}
          </PrimaryText>
          <IconContainer>{renderConnectorIcon(type)}</IconContainer>
        </ConnectorButton>
      );
    },
    [
      connectingConnector,
      getConnectorStatus,
      renderConnectorIcon,
      handleConnect,
      connectedTo,
      isUnsupportedNetwork,
    ]
  );

  return (
    <BasicModal
      name="walletconnect"
      show={showConnectWallet}
      onClose={onHide}
      height="33vh"
      maxWidth={{ xs: "50vh" }}
    >
      <Content>
        <Header>
          <Diamond width="1.1vh" height="auto" />
          <Title
            fontSize="2.4vh"
            lineHeight={1.5}
            fontWeight={400}
            letterSpacing={1}
            className="mx-3"
            normalCased
          >
            {isUnsupportedNetwork ? "Unsupported Network" : "Connect Wallet"}
          </Title>
          <Diamond width="1.1vh" height="auto" />
        </Header>
        {renderConnectorButton("metamask", "Metamask")}
        {renderConnectorButton("walletConnect", "Wallet Connect")}
        {Boolean(!connectedTo && isUnsupportedNetwork) && (
          <StatusText>
            You are on an unsupported network.
            <br />
            Please switch to&nbsp;
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {supportedChainIDs
              .map((id) => (chainIdsMapping as any)[id])
              .join(", ")}
            .
          </StatusText>
        )}
      </Content>
    </BasicModal>
  );
};

export default WalletConnectModal;
