const theme = {
  hover: {
    opacityLow: 0.48,
    opacity: 0.64,
    opacityHigher: 0.84,
  },
  border: {
    radiusExtraBig: "1.6vh",
    radiusBig: "1.2vh",
    radius: "0.8vh",
    radiusSmall: "0.4vh",
    width: "0.1vh",
    widthMed: "0.2vh",
    widthHigh: "0.4vh",
    style: "solid",
  },
};

export default theme;
