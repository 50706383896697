import { backgroundLayersMeta } from "../data/gameLayout/backgroundLayers";

import BaseScene from "./BaseScene";
import {
  animationTypeMeta,
  animationTypes,
  explorerBodyLayers,
} from "../model/explorerAction";

export default class LoadingScene extends BaseScene {
  constructor() {
    super("loading");
  }

  create() {
    // Start preload assets
    this.load.on("complete", () => {
      this.scene.start("hideout");
      this.scene.stop("loading");
    });

    this.load.atlas(
      "explorerHead",
      "assets/explorer-head.png",
      "assets/explorer-head.json"
    );
    this.load.atlas(
      "explorerFace",
      "assets/explorer-face.png",
      "assets/explorer-face.json"
    );

    this.load.image(
      "floatingExplorerInfoBox",
      "assets/floating-explorer-info-box.png"
    );
    this.load.image(
      "floatingExplorerInfoBoxPortraitMask",
      "assets/floating-explorer-info-box-portrait-mask.png"
    );

    animationTypes.forEach((animation) => {
      const currAnimMeta = animationTypeMeta[animation];

      explorerBodyLayers.forEach((layer) => {
        switch (currAnimMeta.type) {
          case "single":
            this.load.animation(
              `explorerBodyAnims${animation}${layer}`,
              `assets/explorer-body-${animation}-${layer}-anims.json`
            );
            this.load.atlas(
              `explorerBody${animation}${layer}`,
              `assets/explorer-body-${animation}-${layer}.png`,
              `assets/explorer-body-${animation}-${layer}.json`
            );
            break;
          case "multi":
            currAnimMeta.movements.forEach((movement) => {
              this.load.animation(
                `explorerBodyAnims${animation}_${movement}${layer}`,
                `assets/explorer-body-${animation}_${movement}-${layer}-anims.json`
              );
              this.load.atlas(
                `explorerBody${animation}_${movement}${layer}`,
                `assets/explorer-body-${animation}_${movement}-${layer}.png`,
                `assets/explorer-body-${animation}_${movement}-${layer}.json`
              );
            });
        }
      });
    });

    this.load.image("unconnectedHideout", "assets/unconnected-hideout.png");

    backgroundLayersMeta.forEach((layerMeta) => {
      const assetBasePath = "assets/background/";

      [...new Array(layerMeta.count)].forEach((_, index) => {
        const layerName = `${layerMeta.name}_${index}`;
        const animateMeta = layerMeta.animated?.[index];

        if (animateMeta) {
          // Load animation assets and atlas spritesheet
          this.load.animation(
            `${layerName}animation`,
            `${assetBasePath}${layerName}/anims.json`
          );
          this.load.atlas(
            `layer${layerName}`,
            `${assetBasePath}${layerName}/spritesheet.png`,
            `${assetBasePath}${layerName}/atlas.json`
          );
        } else {
          // Load image assets
          this.load.atlas(
            layerName,
            `${assetBasePath}${layerName}.png`,
            `${assetBasePath}${layerName}.json`
          );
        }
      });

      // Load mask if needed
      if (
        layerMeta.customization?.find(
          (customizeItem) => customizeItem.type === "color"
        )
      ) {
        this.load.atlas(
          `${layerMeta.name}colorMask`,
          `${assetBasePath}${layerMeta.name}_color-mask.png`,
          `${assetBasePath}${layerMeta.name}_color-mask.json`
        );
      }
    });

    /**
     * SFX
     */
    this.load.audio("click", [`assets/SFX/click.mp3`]);

    this.load.start();
  }
}
