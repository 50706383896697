import styled, { css } from "styled-components";

import { colors } from "./colors";
import theme from "./theme";

export const BaseText = styled.span`
  color: ${colors.darkGreen};
  font-size: 16px;
  color: white;
`;

export const BaseLink = styled.a`
  text-decoration: none;
`;

export const scrollbarStyle = css<{ scrollBarWidth?: string }>`
  ::-webkit-scrollbar {
    width: ${(props) => props.scrollBarWidth || "0.8vh"};
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.primaryRed};
    border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.hoverTintBrown}D4;
    border-radius: 100px;
  }
`;

export const Title = styled.span<{
  color?: string;
  fontSize?: string;
  lineHeight?: number | string;
  letterSpacing?: number;
  fontWeight?: number;
  normalCased?: boolean;
}>`
  color: ${(props) => props.color || colors.primaryRed};
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || "normal"};
  ${(props) => (!props.normalCased ? `text-transform: uppercase;` : ``)}
  font-size: ${(props) => props.fontSize || "1.6vh"};
  ${(props) => (props.lineHeight ? `line-height: ${props.lineHeight};` : ``)}
  ${(props) =>
    props.letterSpacing ? `letter-spacing: ${props.letterSpacing}px;` : ""}
`;

export const Subtitle = styled.span<{
  color?: string;
  fontSize?: string;
  lineHeight?: number | string;
  letterSpacing?: number;
  normalCased?: boolean;
}>`
  color: ${(props) => props.color || colors.primaryRed};
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => props.fontSize || "1.2vh"};
  ${(props) => (props.lineHeight ? `line-height: ${props.lineHeight};` : ``)}
  letter-spacing: ${(props) => props.letterSpacing || 1.5}px;
  ${(props) => (!props.normalCased ? `text-transform: uppercase;` : ``)}
`;

export const PrimaryText = styled(BaseText)<{
  color?: string;
  fontSize?: string;
  lineHeight?: number | string;
  fontWeight?: number;
}>`
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 400};
  font-size: ${(props) => props.fontSize || "1.6vh"};
  line-height: ${(props) => props.lineHeight || "2.4vh"};
  color: ${(props) => props.color || colors.primaryRed};
`;

export const SecondaryText = styled.span<{
  color?: string;
  fontSize?: string;
  lineHeight?: number | string;
  fontWeight?: number;
}>`
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 400};
  font-size: ${(props) => props.fontSize || "1.4vh"};
  line-height: ${(props) => props.lineHeight || "2.0vh"};
  color: ${(props) => props.color || colors.brown};
`;

export const BaseInputLabel = styled.div<{ color?: string }>`
  color: ${(props) => props.color || colors.primaryRed};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.5px;
`;

export const BaseInputContainer = styled.div<{ error?: boolean }>`
  position: relative;
  width: 100%;
  height: 7.2vh;
  background: rgba(0, 0, 0, 0.12);
  border-radius: 0.4vh;
  border: ${theme.border.width} ${theme.border.style}
    ${(props) => (props.error ? colors.red : `transparent`)};
  transition: border 0.25s;
`;

export const BaseInput = styled.input<{
  color?: string;
  inputWidth?: string;
  fontSize?: string;
  lineHeight?: number | string;
}>`
  width: ${(props) => props.inputWidth || "80%"};
  height: 100%;
  font-size: ${(props) => props.fontSize || "4vh"};
  line-height: ${(props) => props.lineHeight || 1.5};
  color: ${(props) => props.color || colors.brown};
  border: none;
  background: none;

  &:focus {
    color: ${(props) => props.color || colors.brown};
    background: none;
    border: none;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    border: rgba(255, 255, 255, 0);
  }

  &:disabled {
    background-color: transparent !important;
  }
`;

export const BaseInputButton = styled.div<{ color?: string }>`
  position: absolute;
  top: 50%;
  transform: translate(-8px, -50%);
  right: 0;
  background: rgba(0, 0, 0, 0.04);
  color: ${(props) => props.color || colors.primaryRed};
  border-radius: 4px;
  padding: 8px;
  height: 32px;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.5px;
  cursor: pointer;
`;
