import axios from "axios";
import { Signer } from "ethers";
import { useCallback, useEffect } from "react";
import { generateNonce, SiweMessage } from "siwe";
import { API_BASE_URL } from "../config/constants";
import { getDefaultChainID, isProduction } from "../config/env";
import { useGlobalState } from "../store/store";
import { clearSiweToken, getSiweToken, setSiweToken } from "../utils/storage";
import useWeb3Wallet from "./useWeb3Wallet";

const useSiwe = () => {
  const { account, library } = useWeb3Wallet();
  const [token, setToken] = useGlobalState("siweToken");

  useEffect(() => {
    setToken((account && getSiweToken(account)) || undefined);
  }, [account, setToken]);

  const onRequestSignature = useCallback(
    async (
      onSuccess: () => void,
      onError: (error: string) => void = () => {}
    ) => {
      if (!account || !library) {
        return;
      }

      /**
       * Skip actual signature in development environment by
       * asking for token from impersonate endpoint
       */
      try {
        if (!isProduction()) {
          const { data } = await axios.post<{
            success: boolean;
            token: string;
          }>(`${API_BASE_URL}/siwe-verify-impersonate`, {
            publicAddress: account,
          });

          setToken(setSiweToken(account, data.token));
          onSuccess();
          return;
        }
      } catch (err) {}

      const message = new SiweMessage({
        domain: window.location.host,
        address: account,
        statement: "Verify wallet ownership",
        uri: window.location.origin,
        version: "1",
        chainId: getDefaultChainID(),
        nonce: generateNonce(),
        expirationTime: new Date(Date.now() + 1000 * 60 * 60).toISOString(),
      });
      const signer = library.getSigner() as Signer;

      try {
        const signature = await signer.signMessage(message.prepareMessage());

        const { data } = await axios.post<{ success: boolean; token: string }>(
          `${API_BASE_URL}/siwe-verify`,
          {
            signature,
            message,
          }
        );
        if (!data.success) {
          onError("siweVerifyError");
        }

        setToken(setSiweToken(account, data.token));
        onSuccess();
      } catch (err) {
        onError("siweVerifyError");
      }
    },
    [account, library, setToken]
  );

  const resetToken = useCallback(() => {
    if (!account) {
      return;
    }

    clearSiweToken(account);
    setToken(undefined);
  }, [account, setToken]);

  return { token, onRequestSignature, resetToken };
};

export default useSiwe;
