import { useCallback, useContext } from "react";
import { useGlobalState } from "../store/store";
import { getNewlyUnlockedLayers } from "../utils/backgroundLayer";
import { PersonalizedDataContext } from "./personalizedDataContext";
import { ExplorerMetaDisplay } from "../model/loot";

export const useDisplayExplorers = () => {
  const [, setFurnitureUnlockToast] = useGlobalState("furnitureUnlockToast");
  const { data, setData, loading } = useContext(PersonalizedDataContext);

  const setDisplayExplorers = useCallback(
    (explorerIds: string[]) => {
      if (!data) {
        return;
      }

      const newDisplayExplorers = Array.from(new Set(explorerIds));

      const newlyUnlockedLayers = getNewlyUnlockedLayers(
        data.displayExplorers,
        newDisplayExplorers
      );

      if (newlyUnlockedLayers.length > 0) {
        setFurnitureUnlockToast((prev) => [
          ...prev,
          ...newlyUnlockedLayers.map((unlockLayer, index) => ({
            id: `${Date.now().valueOf().toString()}${index}`,
            layer: unlockLayer.layer,
            index: unlockLayer.index,
            unlockCriteria: `${unlockLayer.criteria.count} ${
              ExplorerMetaDisplay[unlockLayer.criteria.metaValue] ||
              unlockLayer.criteria.metaValue
            }`,
          })),
        ]);
      }

      setData({
        displayExplorers: newDisplayExplorers,
      });
    },
    [data, setData, setFurnitureUnlockToast]
  );

  return {
    displayExplorers: data?.displayExplorers || [],
    setDisplayExplorers,
    loading,
  };
};
