export const backgroundLayerList = [
  "banner",
  "bed",
  "bg",
  "carpet",
  "deco1",
  "deco2",
  "deco3",
  "divider",
  "door",
  "floor",
  "housebox",
  "ladderwood",
  "layouttop",
  "layoutbottom",
  "liningwood",
  "railingwood",
  "recgame",
  "shelves",
  "walldeco",
  "wallpaper",
  "window",
  "workspacesleft",
  "workspacesright",
] as const;
export type BackgroundLayer = (typeof backgroundLayerList)[number];

export const BackgroundLayerDisplay: { [key in BackgroundLayer]: string } = {
  banner: "Banner",
  bed: "Bed",
  bg: "Background Color",
  carpet: "Carpet",
  deco1: "Decoration 1",
  deco2: "Decoration 2",
  deco3: "Decoration 3",
  divider: "Divider",
  door: "Door",
  floor: "Floor",
  housebox: "Housebox",
  ladderwood: "Ladder",
  layouttop: "Layout",
  layoutbottom: "Layout",
  liningwood: "Wooden Lining",
  railingwood: "Wooden Railing",
  recgame: "Recreational",
  shelves: "Shelves",
  walldeco: "Wall Decoration",
  wallpaper: "Wallpaper",
  window: "Window",
  workspacesleft: "Workspace (Left)",
  workspacesright: "Worksapce (Right)",
};

export type HideoutSpaceOccupancy = { [layer in BackgroundLayer]: boolean };
export const defaultHideoutSpaceOccupancy = Object.fromEntries(
  backgroundLayerList.map((layer) => [layer, false])
) as HideoutSpaceOccupancy;

export const BackgroundLayersDisplayName: {
  [layer in BackgroundLayer]: string[];
} = {
  banner: ["Rolled Up Banner"],
  bed: [
    "Simple Bed",
    "Rustic Bed",
    "Ornate Bed",
    "Adventurer's Nook",
    "Cozy Platform Bed",
    "Bubble Bed",
    "Carved Molten Bed",
    "Royal Deep Sea Bed",
  ],
  bg: ["Sky Blue", "Lavender Mist", "Amber Sunset", "Golden Hour"],
  carpet: ["Fuzzy Carpet", "Dance Floor"],
  deco1: [
    "Green Stump Sidetable",
    "Relaxing Tea Corner",
    "Relaxing Tea Corner",
    "Igneous Workspace",
    "Shroom Log",
    "Classic Stone Bench",
    "Altar of the Occult",
    "Living Flame Fireplace",
  ],
  deco2: [
    "Wooden Storage Space",
    "Weapon Rack",
    "Minimalist Sideboard",
    "Deeptide Aquarium",
  ],
  deco3: [
    "Catnip Garden",
    "Carrot Farm",
    "Crystal Collection",
    "Salmon Crates",
    "Bonsai Corner",
    "Stashed Treasure",
  ],
  divider: [
    "Simple Wooden Divider ",
    "Potted Greenery",
    "Ornate Divider",
    "Carved Log Half Wall",
    "Paper Wall Divider",
    "Turquoise Stone Half Wall",
    "Igneous Rock Half Wall ",
  ],
  door: [
    "Basic Entrance",
    "Sunken Doorway",
    "Cerulean Gates",
    "Molten Passage",
    "Sprucewood Door",
    "Gothic Archway",
    "Nautical Door",
  ],
  floor: [
    "Lightwood",
    "Darkwood",
    "Stone",
    "Argyle",
    "Chessboard",
    "Tatami",
    "Grass",
    "Dry Grass",
    "Tiles",
    "Carpet",
  ],
  housebox: ["Default"],
  ladderwood: ["Oak Ladder", "Pine Ladder", "Maple Ladder", "Spruce Ladder"],
  layouttop: [
    "Explorer's Treehouse",
    "Snowtop Bathhouse",
    "Underlune Palace",
    "Lava Clusters",
    "The Great Ship",
    "Everside Cottage",
    "Gothic Sanctum",
  ],
  layoutbottom: [
    "Explorer's Treehouse",
    "Snowtop Bathhouse",
    "Underlune Palace",
    "Lava Clusters",
    "The Great Ship",
    "Everside Cottage",
    "Gothic Sanctum",
  ],
  liningwood: ["Oak Lining", "Pine Lining", "Maple Lining", "Spruce Lining"],
  railingwood: [
    "Oak Railing",
    "Pine Railing",
    "Maple Railing",
    "Spruce Railing",
    "Leafy Railing",
    "Ornate Railing",
    "Paper Screen",
    "Glass Panel",
    "Seaweed Panel",
    "Molten Railing",
    "Plank Railing",
    "Iron Railing",
    "Stone Railing",
  ],
  recgame: [
    "Mint Game",
    "Purple Game",
    "Great Feast",
    "Bar",
    "Grand Piano",
    "Firepit",
    "Footbath",
    "Cartography Table",
    "Birdbath",
    "Gambling Den",
    "Trashcan Drums",
    "Meditation Zone",
    "Training Dojo",
  ],
  shelves: [
    "Simple Shelving",
    "Adventure Stockpile",
    "Sliding Wardrobe",
    "Oceanic Display",
    "Rocky Shelving",
    "Pirate's Stash",
    "Leafy Shelving",
    "Gothic Shelving",
  ],
  walldeco: [
    "Simple Notes",
    "Botanical Research",
    "Ornate Paintings",
    "Adventure Set",
    "Lisuni Heirlooms",
    "Ocean Keepsakes",
    "Magma Isle Relics",
  ],
  wallpaper: [
    "Lightwood",
    "Darkwood",
    "Stone",
    "Plain",
    "Stripes",
    "Wood Panels",
    "Ornate",
  ],
  window: [
    "Simple Window",
    "Grill Window",
    "Paper Window",
    "Cerulean Porthole",
    "Melted Window",
    "Gilded Porthole",
    "Curtained Window",
    "Iron Window",
  ],
  workspacesleft: [
    "Kitchen",
    "Blacksmith",
    "Library",
    "Tailoring Table",
    "Writer's Desk",
    "Wine Cabinet",
    "Botany Corner",
    "Ancient Garden",
    "Potion Brewery",
    "Ocean Memoirs",
    "Incantation Ring",
    "Fishery",
    "Heaps of Gold",
  ],
  workspacesright: [
    "Kitchen",
    "Blacksmith",
    "Library",
    "Tailoring Table",
    "Writer's Desk",
    "Winery Cabinet",
    "Botany Corner",
    "Ancient Garden",
    "Potion Brewery",
    "Ocean Memoirs",
    "Arcane Circle",
    "Fishery",
    "Heaps of Gold",
  ],
};
