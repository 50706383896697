import React, { useMemo } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { isDevelopment, isProduction } from "../../config/env";
import useEagerConnect from "../../hooks/useEagerConnect";
import { useGlobalState } from "../../store/store";
import ExplorerInfoModal from "../Modal/ExplorerInfoModal";
import ExplorersSelectionModal from "../Modal/ExplorersSelection/ExplorersSelectionModal";
import HideoutProfileModal from "../Modal/HIdeoutProfile/HideoutProfileModal";
import ImpersonateModal from "../Modal/ImpersonateModal";
import ScreenshotModal from "../Modal/ScreenshotModal";
import SiweModal from "../Modal/SiweModal";
import WalletConnectModal from "../Modal/WalletConnectModal";
import Identity from "./Identity";
import Impersonate from "./Impersonate";
import MainMenu from "./MainMenu";
import ToastHandler from "./ToastHandler";
import ModeFlag from "./ModeFlag";
import EditModeMenu from "./EditModeMenu/EditModeMenu";
import FurnitureUnlockToastHandler from "./FurnitureUnlockToastHandler";
import MusicPlayer from "../Shared/MusicPlayer";
import WelcomeModal from "../Modal/WelcomeModal";
import MailboxPoster from "../Modal/MailboxPoster";
import LoadingScene from "./LoadingScene";

const Layout = () => {
  useEagerConnect();

  const [scene] = useGlobalState("phaserScene");

  const impersonateElement = useMemo(() => {
    if (isDevelopment()) {
      return (
        <>
          <Impersonate />
          <ImpersonateModal />
        </>
      );
    }

    return <></>;
  }, []);

  const mainUI = useMemo(
    () => (
      <AnimatePresence>
        <motion.div
          className="d-flex w-100 h-100"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: 1.2,
            duration: 0.5,
          }}
        >
          <WelcomeModal />
          <WalletConnectModal />
          <HideoutProfileModal />
          <ScreenshotModal />
          <ExplorersSelectionModal />
          <ExplorerInfoModal />
          <SiweModal />
          <ToastHandler />
          <FurnitureUnlockToastHandler />

          {/* Top Left Identity of the user */}
          <Identity />

          {/* Left Menu Buttons */}
          <MainMenu />

          <ModeFlag />
          <EditModeMenu />

          <MusicPlayer />
          <MailboxPoster />
        </motion.div>
      </AnimatePresence>
    ),
    []
  );

  return (
    <>
      <LoadingScene key="loading" show={scene === "loading"} />

      {scene !== "loading" && mainUI}

      {impersonateElement}
    </>
  );
};

export default Layout;
