import { css } from "styled-components";
import hoverImg from "../../assets/icons/hover-cursor.png";

export const clickable = css`
  cursor: url(${hoverImg}), auto;
`;

export const clickableScale = css`
  ${clickable}
  transition: 0.2s transform ease;
  &:hover {
    transform: scale(1.1);
  }
`;

export const clickableShrunk = css`
  ${clickable}
  transition: 0.2s transform ease;
  &:hover {
    transform: scale(0.9);
  }
`;
