import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useGlobalState } from "../../store/store";
import { colors } from "../../designSystem/colors";
import { getLayerPreviewImageURL } from "../../utils/backgroundLayer";
import useHideoutLayerCustomization from "../../hooks/useHideoutLayerCustomization";
import { PrimaryText, Title } from "../../designSystem";
import { BackgroundLayerDisplay } from "../../model/hideout";
import { Diamond } from "../../assets/icons";
import { FurnitureUnlockToast } from "../../model/toast";
import { playSecondaryClickSound } from "../../utils/sound";

const ToastContainer = styled(motion.div).attrs({
  className: "d-flex position-absolute clickable",
})`
  bottom: 6vh;
  height: 11vh;
  right: 0;
`;

const ToastContent = styled.div.attrs({
  className: "d-flex h-100 align-items-center justify-content-center",
})`
  padding: 0 3vw;
  background: ${colors.modalContentBackground};
  border-radius: 100px 0px 0px 100px;
`;

const FurnitureImage = styled.img`
  height: 80%;
  margin-right: 2.5vh;
`;

const FirstLineText = styled(PrimaryText)`
  line-height: 1.5;
  margin-bottom: 0.5vh;
`;
const SecondLineText = styled(Title)`
  line-height: 1.5;
`;

const FurnitureUnlockToastHandler = () => {
  const [toastData, setToastData] = useGlobalState("furnitureUnlockToast");
  const [showExplorersModal] = useGlobalState("showExplorersModal");
  const [, setMode] = useGlobalState("mode");
  const [, setEditModeActiveLayer] = useGlobalState("editModeActiveLayer");
  const { hideoutLayerColor } = useHideoutLayerCustomization();
  const [displayToast, setDisplayToast] = useState<
    FurnitureUnlockToast | undefined
  >();

  const containerAnimateProps = useMemo(() => {
    if (displayToast) {
      return {
        x: 0,
        opacity: 1,
      };
    }

    return {
      x: -200,
      opacity: 0,
    };
  }, [displayToast]);

  useEffect(() => {
    setDisplayToast((_displayToast) => {
      const firstToastData = toastData[0];

      if (firstToastData?.id !== _displayToast?.id) {
        if (firstToastData && !_displayToast && !showExplorersModal) {
          /**
           * Play audio when new toast are showed
           */
          new Audio("assets/SFX/furniture-unlock.mp3").play();

          return firstToastData;
        }

        return undefined;
      }

      return _displayToast;
    });
  }, [displayToast, setToastData, showExplorersModal, toastData]);

  useEffect(() => {
    if (!displayToast) {
      return;
    }

    const timeout = setTimeout(() => {
      setToastData(([_first, ...next]) => next);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [setToastData, displayToast]);

  return (
    <AnimatePresence exitBeforeEnter>
      {displayToast && (
        <ToastContainer
          key={displayToast.id}
          initial={{
            x: -200,
            opacity: 0,
          }}
          animate={containerAnimateProps}
          exit={{
            x: -200,
            opacity: 0,
          }}
          transition={{
            type: "keyframes",
          }}
          role="button"
          onMouseDown={playSecondaryClickSound}
          onClick={() => {
            setMode("edit");
            setEditModeActiveLayer(displayToast.layer);
          }}
        >
          <ToastContent>
            <FurnitureImage
              src={getLayerPreviewImageURL(
                displayToast.layer,
                displayToast.index,
                hideoutLayerColor[displayToast.layer]
              )}
            />
            <div className="d-flex flex-column align-items-center">
              <div className="d-flex align-items-center">
                <FirstLineText fontSize="1.75vh">
                  You have{" "}
                  <span style={{ color: colors.highlightText }}>
                    {displayToast.unlockCriteria}
                  </span>{" "}
                  explorers!
                </FirstLineText>
              </div>
              <div className="d-flex align-items-center">
                <Diamond />
                <SecondLineText className="mx-2" fontSize="1.75vh">
                  New {BackgroundLayerDisplay[displayToast.layer]} Unlocked
                </SecondLineText>
                <Diamond />
              </div>
            </div>
          </ToastContent>
        </ToastContainer>
      )}
    </AnimatePresence>
  );
};

export default FurnitureUnlockToastHandler;
