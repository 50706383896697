import React, { useState } from "react";

const ImageWithPlaceholder: React.FC<
  React.ImgHTMLAttributes<HTMLImageElement> & { placeholder: string }
> = ({ src, alt, placeholder, style, ...props }) => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => setLoading(false);
  const handleError = () => setLoading(false);

  return (
    <>
      {loading && <img {...props} src={placeholder} alt="Placeholder" />}
      <img
        {...props}
        src={src}
        alt={alt}
        onLoad={handleLoad}
        onError={handleError}
        style={{ ...style, display: loading ? "none" : "block" }}
      />
    </>
  );
};

export default ImageWithPlaceholder;
