import { Boundary, Coordinate, directionsMap } from "../model/direction";
import {
  animationTypeMeta,
  animationTypes,
  ExplorerAction,
  WALKING_BOUNDARIES,
} from "../model/explorerAction";
import { BackgroundLayer, HideoutSpaceOccupancy } from "../model/hideout";
import {
  areCoordinatesWithinRadius,
  getIsometricDirectionFromPixel,
  getIsometricTurnPointFromPixel,
} from "./direction";
import {
  convertIsometricToOrthographic,
  convertOrthographicToIsometric,
} from "./isometric";
import { randElem, randInt } from "./math";

export const getExplorerActionMotionName = (action: ExplorerAction): string => {
  switch (action.motion) {
    case "walk":
    case "scoping_tower":
      return action.motion;
    case "idle":
    case "bed":
    case "interact_station":
      return `${action.motion}_${action.movement}`;
  }
};

export const isExplorerActionEnded = (action: ExplorerAction): boolean => {
  switch (action.motion) {
    case "idle":
      if (["thinking_from_standing", "thinking"].includes(action.movement))
        return false;

      return action.remainingLength <= 0;
    case "walk":
      return action.distanceRemaining <= 0 && action.next.length <= 0;
    case "bed":
    case "interact_station":
      return (
        action.stage === "walkOut" &&
        action.distanceRemaining <= 0 &&
        action.next.length <= 0
      );
    case "scoping_tower":
      return action.stage === "walkOut";
  }
};

/**
 * Test if the coordinate given is within the boundary
 * @param coordinate Coordinate to test if it is within the boundary
 * @param boundary Boundary to be tested against
 * @returns If the coordinate is within the boundary
 */
export const isWithinBoundary = (
  coordinate: Coordinate,
  boundary: Boundary
): boolean => {
  const { x, y } = convertIsometricToOrthographic(coordinate);
  const { x: minX, y: minY } = convertIsometricToOrthographic(boundary[0]);
  const { x: maxX, y: maxY } = convertIsometricToOrthographic(boundary[1]);

  return x >= minX && x <= maxX && y >= minY && y <= maxY;
};

/**
 * Generate a random coordinate within a boundary provided.
 * @param boundary Boundary to generate a randomize location from within
 * @returns Randomized coordinate
 */
export const generateRandomCoordinateFromBoundary = (
  boundary: Boundary,
  collision?: { occupiedCoordinate: Coordinate[]; collisionRadius: number }
): Coordinate => {
  const { x: minX, y: minY } = convertIsometricToOrthographic(boundary[0]);
  const { x: maxX, y: maxY } = convertIsometricToOrthographic(boundary[1]);

  const coordinate = convertOrthographicToIsometric({
    x: randInt(minX, maxX),
    y: randInt(minY, maxY),
  });

  if (
    collision &&
    collision.occupiedCoordinate.find((_coordinate) =>
      areCoordinatesWithinRadius(
        coordinate,
        _coordinate,
        collision.collisionRadius
      )
    )
  ) {
    return generateRandomCoordinateFromBoundary(boundary, collision);
  }

  return coordinate;
};

/**
 * Generate next random explorer action
 * @param prevAction Previous action that had ended
 * @param context Context required to generate next action
 * @returns New explorer action
 */
export const generateRandomizeExplorerAction = (
  prevAction: ExplorerAction,
  {
    currentCoordinate,
    hideoutSpaceOccupancy,
    occupiedCoordinate,
  }: {
    currentCoordinate: Coordinate;
    hideoutSpaceOccupancy: HideoutSpaceOccupancy;
    occupiedCoordinate: Coordinate[];
  }
): { action: ExplorerAction; hideoutSpaceOccupancy: HideoutSpaceOccupancy } => {
  const nextMotion = randElem(
    animationTypes.filter((type) => {
      if (type === prevAction.motion) {
        return false;
      }

      if (type === "idle") {
        return !["interact_station", "scoping_tower", "bed"].includes(
          prevAction.motion
        );
      }

      if (type === "bed") {
        return (
          isWithinBoundary(
            currentCoordinate,
            animationTypeMeta.bed.availableBoundary
          ) && !hideoutSpaceOccupancy["bed"]
        );
      }

      if (type === "scoping_tower") {
        return (
          isWithinBoundary(
            currentCoordinate,
            animationTypeMeta.scoping_tower.availableBoundary
          ) && !hideoutSpaceOccupancy["layouttop"]
        );
      }

      if (type === "interact_station") {
        return (
          isWithinBoundary(
            currentCoordinate,
            animationTypeMeta.interact_station.availableBoundary
          ) &&
          (!hideoutSpaceOccupancy["workspacesleft"] ||
            !hideoutSpaceOccupancy["workspacesright"])
        );
      }

      return true;
    })
  );

  switch (nextMotion) {
    case "idle": {
      const movement = randElem([...animationTypeMeta.idle.startingMovements]);
      // Will stand randomly from 2s to 10s
      const remainingLength =
        movement === "thinking_from_standing"
          ? (14 / 24) * 1000
          : randInt(2000, 10000);

      return {
        action: {
          motion: "idle",
          direction:
            randElem(animationTypeMeta.idle.direction[movement] || []) ||
            prevAction.direction,
          movement: movement,
          remainingLength: remainingLength,
        },
        hideoutSpaceOccupancy,
      };
    }
    case "walk": {
      const currentBoundary = WALKING_BOUNDARIES.find((boundary) =>
        isWithinBoundary(currentCoordinate, boundary)
      )!;
      const destination = generateRandomCoordinateFromBoundary(
        currentBoundary,
        { occupiedCoordinate, collisionRadius: 50 }
      );
      const path = [
        randElem(
          getIsometricTurnPointFromPixel(currentCoordinate, destination)
        ),
        destination,
      ];

      return {
        action: {
          motion: "walk",
          direction:
            directionsMap[
              getIsometricDirectionFromPixel(currentCoordinate, path[0])
            ],
          destination: destination,
          next: path,
          distanceRemaining: Phaser.Math.Distance.Between(
            currentCoordinate.x,
            currentCoordinate.y,
            path[0].x,
            path[0].y
          ),
        },
        hideoutSpaceOccupancy,
      };
    }
    case "bed": {
      const destination = animationTypeMeta.bed.pathIn[0];
      const path = [
        getIsometricTurnPointFromPixel(currentCoordinate, destination)[0],
        ...animationTypeMeta.bed.pathIn,
      ];

      return {
        action: {
          motion: "bed",
          movement: randElem([...animationTypeMeta.bed.movements]),
          stage: "walkIn",
          direction:
            directionsMap[
              getIsometricDirectionFromPixel(currentCoordinate, path[0])
            ],
          destination: destination,
          next: path,
          distanceRemaining: Phaser.Math.Distance.Between(
            currentCoordinate.x,
            currentCoordinate.y,
            path[0].x,
            path[0].y
          ),
        },
        hideoutSpaceOccupancy: { ...hideoutSpaceOccupancy, bed: true },
      };
    }
    case "scoping_tower": {
      const destination = animationTypeMeta.scoping_tower.pathIn[0];
      const path = [
        getIsometricTurnPointFromPixel(currentCoordinate, destination)[0],
        ...animationTypeMeta.scoping_tower.pathIn,
      ];

      return {
        action: {
          motion: "scoping_tower",
          stage: "walkIn",
          direction:
            directionsMap[
              getIsometricDirectionFromPixel(currentCoordinate, path[0])
            ],
          destination: destination,
          next: path,
          distanceRemaining: Phaser.Math.Distance.Between(
            currentCoordinate.x,
            currentCoordinate.y,
            path[0].x,
            path[0].y
          ),
        },
        hideoutSpaceOccupancy: { ...hideoutSpaceOccupancy, layouttop: true },
      };
    }
    case "interact_station": {
      const emptyWorkspace = (
        ["workspacesleft", "workspacesright"] as BackgroundLayer[]
      ).find((workspace) => !hideoutSpaceOccupancy[workspace]) as
        | "workspacesleft"
        | "workspacesright";

      const destination =
        animationTypeMeta.interact_station[emptyWorkspace].pathIn[0];
      const path = [
        getIsometricTurnPointFromPixel(currentCoordinate, destination)[0],
        ...animationTypeMeta.interact_station[emptyWorkspace].pathIn,
      ];

      return {
        action: {
          motion: "interact_station",
          movement: "a",
          occupiedStation: emptyWorkspace,
          stage: "walkIn",
          direction:
            directionsMap[
              getIsometricDirectionFromPixel(currentCoordinate, path[0])
            ],
          destination: destination,
          next: path,
          distanceRemaining: Phaser.Math.Distance.Between(
            currentCoordinate.x,
            currentCoordinate.y,
            path[0].x,
            path[0].y
          ),
        },
        hideoutSpaceOccupancy: {
          ...hideoutSpaceOccupancy,
          [emptyWorkspace]: true,
        },
      };
    }
  }
};
