import { motion } from "framer-motion";
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { PrimaryText } from "../../designSystem";

import { colors } from "../../designSystem/colors";
import { useGlobalState } from "../../store/store";

const ToastContainer = styled(motion.div)`
  position: fixed;
  bottom: 2.4vh;
  left: 50%;
  z-index: 10000;

  background: ${colors.modalBackground};
  border: 0.1vh solid ${colors.primaryRed};
  border-radius: 1.6vh;
  padding: 0.8vh 1.6vh;
`;

const ToastHandler = () => {
  const [toastDatas, setToastDatas] = useGlobalState("toastDatas");

  const showingToastData = useMemo(() => {
    if (toastDatas.length > 0) {
      return toastDatas[0];
    }
  }, [toastDatas]);

  useEffect(() => {
    if (!showingToastData) {
      return;
    }

    if (showingToastData.autoHide) {
      const timeout = setTimeout(() => {
        setToastDatas(([_first, ...prev]) => [...prev]);
      }, showingToastData.autoHide.duration);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [setToastDatas, showingToastData]);

  return (
    <ToastContainer
      key={showingToastData?.id || ""}
      initial={{ opacity: 0, x: "-50%", y: 100 }}
      animate={{ opacity: showingToastData ? 1 : 0, x: "-50%", y: 0 }}
    >
      <PrimaryText lineHeight={1}>{showingToastData?.message}</PrimaryText>
    </ToastContainer>
  );
};

export default ToastHandler;
