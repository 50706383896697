import SageLogo from "./sage.png"
import SpectreLogo from "./spectre.png"
import UnboundLogo from "./unbound.png"
import ValiantLogo from "./valiant.png"
import WarswornLogo from "./warsworn.png"

export const getAllegianceLogoSrc = (allegiance: string) => {
  switch (allegiance) {
    case "Sage":
      return SageLogo
    case "Warsworn":
      return WarswornLogo
    case "Spectre":
      return SpectreLogo
    case "Valiant":
      return ValiantLogo
    case "Unbound":
      return UnboundLogo
  }

  return ""
}
