import React, { useEffect, useMemo, useState } from "react";

import useSiwe from "../../../hooks/useSiwe";
import { usePersonalizedData } from "../../../hooks/personalizedDataContext";
import useWeb3Wallet from "../../../hooks/useWeb3Wallet";
import { useGlobalState } from "../../../store/store";
import BasicModal from "../../Common/BasicModal";
import SingleFieldFormModalContent, {
  SingleFieldFormModalContentSize,
} from "../../Common/Modal/SingleFieldFormModalContent";
import HideoutProfileModalProfileContent from "./HideoutProfileModalProfileContent";

const modeList = ["profile", "editName"] as const;
type Mode = (typeof modeList)[number];

const MODE_MODAL_SIZE: { [mode in Mode]: { width: string; height: string } } = {
  profile: {
    width: "70vh",
    height: "26.7vh",
  },
  editName: SingleFieldFormModalContentSize,
};

const HideoutProfileModal = () => {
  const [mode, setMode] = useState<Mode>(modeList[0]);
  const { name, updateFieldData } = usePersonalizedData();
  const { account } = useWeb3Wallet();
  const [showHideoutProfileModal, setShowHideoutProfileModal] = useGlobalState(
    "showHideoutProfileModal"
  );
  const { token } = useSiwe();
  const [, setShowSiweModal] = useGlobalState("showSiweModal");

  useEffect(() => {
    if (!account) {
      setShowHideoutProfileModal(false);
    }
  }, [account, setShowHideoutProfileModal]);

  // Reset mode back to default
  useEffect(() => {
    setMode(modeList[0]);
  }, [showHideoutProfileModal]);

  const modalContent = useMemo(() => {
    switch (mode) {
      case "profile":
        return (
          <HideoutProfileModalProfileContent
            onEditName={() => {
              if (!token) {
                setShowHideoutProfileModal(false);
                setShowSiweModal(true);
                return;
              }

              setMode("editName");
            }}
          />
        );
      case "editName":
        return (
          <SingleFieldFormModalContent
            initialValue={name}
            placeholder="Profile Name"
            validateValue={(value) => value.length > 24}
            onSubmit={(value) => {
              updateFieldData("name", value.trim());

              setMode("profile");
            }}
          />
        );
    }
  }, [
    mode,
    name,
    setShowHideoutProfileModal,
    setShowSiweModal,
    token,
    updateFieldData,
  ]);

  return (
    <BasicModal
      name="hideoutprofile"
      show={showHideoutProfileModal}
      onClose={() => setShowHideoutProfileModal(false)}
      height={MODE_MODAL_SIZE[mode].height}
      maxWidth={MODE_MODAL_SIZE[mode].width}
      animationProps={{
        key: mode,
        transition: {
          duration: 0.25,
          type: "keyframes",
          ease: "easeInOut",
        },
        initial: {
          opacity: 0,
        },
        animate: {
          opacity: 1,
        },
        exit: {
          opacity: 0,
        },
      }}
    >
      {modalContent}
    </BasicModal>
  );
};

export default HideoutProfileModal;
