import { HTMLMotionProps, motion } from "framer-motion";
import React, { RefAttributes, useEffect } from "react";
import { Modal } from "react-bootstrap";
import styled, { css } from "styled-components";

import CloseButtonImage from "../../assets/icons/button-close.webp";
import { colors } from "../../designSystem/colors";
import PhaserGame from "../../PhaserGame";
import BaseScene from "../../scenes/BaseScene";
import sizes, {
  resolveResponsiveCSSUnits,
  ResponsiveCSSUnit,
} from "../../utils/sizes";
import { clickableShrunk } from "./style";

const StyledModal = styled(Modal)<{
  height: ResponsiveCSSUnit;
  maxWidth?: ResponsiveCSSUnit;
  backgroundColor?: string;
}>`
  background-color: ${(props) =>
    props.backgroundColor || css`rgba(0, 0, 0, 0.6)`};

  .modal-dialog {
    width: 95vw;
    margin-left: auto;
    margin-right: auto;

    ${(props) =>
      props.maxWidth
        ? resolveResponsiveCSSUnits("max-width", props.maxWidth)
        : ""}
  }

  .modal-content {
    transition: min-height 0.25s;
    ${(props) => resolveResponsiveCSSUnits("min-height", props.height)}
    overflow: visible;

    background: ${colors.modalBackground};
    border: 0.3vh solid ${colors.primaryRed};
    border-radius: 1.6vh;
  }
`;

const ModalContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 1.6vh;
`;
const CloseButton = styled.img.attrs({
  src: CloseButtonImage,
})`
  ${clickableShrunk}
  width: 60px;
  height: 60px;

  position: absolute;
  top: -35px;
  right: -35px;
  z-index: 1;

  @media (max-width: ${sizes.sm}px) {
    width: 40px;
    height: 40px;
    top: -10px;
    right: -10px;
  }
`;

const ChildrenContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
  height: 100%;
`;

interface BasicModalProps {
  name: string;
  show: boolean;
  height: ResponsiveCSSUnit;
  maxWidth?: ResponsiveCSSUnit;
  onClose: () => void;
  closeButton?: boolean;
  backButton?: {
    onClick: () => void;
  };
  children: any;
  animationProps?: HTMLMotionProps<"div"> & RefAttributes<HTMLDivElement>;
  backgroundColor?: string;
}

const BasicModal: React.FC<BasicModalProps> = ({
  name,
  show,
  height,
  maxWidth,
  closeButton,
  onClose,
  children,
  animationProps = {},
  backgroundColor,
}) => {
  /**
   * Whenever a modal is show, we pause the scene behind so it does not interfere with the modal interaction
   */
  useEffect(() => {
    const activeScenes = PhaserGame.scene.getScenes(false);
    activeScenes.forEach((scene) => {
      (scene as BaseScene).setModalShow(name, show);
    });
  }, [name, show]);

  return (
    <StyledModal
      show={show}
      centered
      height={height}
      maxWidth={maxWidth}
      onHide={onClose}
      backgroundColor={backgroundColor}
    >
      <Modal.Body>
        <ModalContent {...animationProps}>
          <ChildrenContainer>{children}</ChildrenContainer>
        </ModalContent>
      </Modal.Body>
      {closeButton && <CloseButton onClick={onClose} />}
    </StyledModal>
  );
};

export default BasicModal;
