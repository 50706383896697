import React, { useMemo } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import { ModeFlagSVG } from "../../assets/modeFlag";
import { useGlobalState } from "../../store/store";
import { Title } from "../../designSystem";
import { EditModeIcon } from "../../assets/icons";

const ModeFlagContainer = styled(motion.div).attrs({
  className: "d-flex position-absolute",
})`
  bottom: 6vh;
  height: 6vh;
`;

const ModeFlagContent = styled.div.attrs({
  className:
    "position-absolute d-flex h-100 align-items-center justify-content-center",
})`
  width: ${(432 / 491) * 100}%;
`;

const FlagTitle = styled(Title)`
  margin-left: 2vh;
`;

const ModeFlag = () => {
  const [mode] = useGlobalState("mode");

  const containerAnimateProps = useMemo(() => {
    switch (mode) {
      case "edit":
        return {
          x: 0,
        };
      default:
        return {
          x: -1000,
        };
    }
  }, [mode]);

  const flagContent = useMemo(() => {
    switch (mode) {
      case "edit":
        return (
          <>
            <EditModeIcon width="4vh" height="auto" />
            <FlagTitle fontSize="2.5vh" fontWeight={600}>
              Edit Mode
            </FlagTitle>
          </>
        );
      default:
        return <></>;
    }
  }, [mode]);

  return (
    <ModeFlagContainer
      initial={{
        x: -1000,
      }}
      animate={containerAnimateProps}
      transition={{
        type: "keyframes",
      }}
    >
      <div className="d-flex h-100 w-100 position-relative">
        <ModeFlagSVG height="auto" width="auto" />

        <ModeFlagContent>{flagContent}</ModeFlagContent>
      </div>
    </ModeFlagContainer>
  );
};

export default ModeFlag;
