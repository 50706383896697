import { useCallback } from "react";

import PhaserGame from "../PhaserGame";
import { useGlobalState } from "../store/store";

/**
 * @module Main_Hook
 * Hook return
 * @typedef {Array} HookReturn
 * @property {string} HookReturn[0] - image string
 * @property {string} HookReturn[1] - take screen shot string
 * @property {object} HookReturn[2] - errors
 */

/**
 * hook for creating screenshot from html node
 * @returns {HookReturn}
 */

type UseScreenshotType = () => [string | undefined, () => void];

const useScreenshot: UseScreenshotType = () => {
  const [screenshotModal, setScreenshotModal] =
    useGlobalState("screenshotModal");
  // const [image, setImage] = useState<string>();
  /**
   * convert html node to image
   * @param {HTMLElement} node
   */
  const takeScreenShot = useCallback(() => {
    PhaserGame.renderer.snapshot((image) => {
      if (image instanceof HTMLImageElement) {
        setScreenshotModal({ show: true, image: image.src });
      }
    });
  }, [setScreenshotModal]);

  return [screenshotModal.image, takeScreenShot];
};

export default useScreenshot;
