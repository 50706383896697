import axios from "axios";
import { signInWithCustomToken } from "firebase/auth";
import React, { useCallback, useEffect } from "react";
import { API_BASE_URL } from "../../config/constants";
import styled from "styled-components";

import useSiwe from "../../hooks/useSiwe";
import useWeb3Wallet from "../../hooks/useWeb3Wallet";
import { auth } from "../../utils/firebase";
import BasicModal from "../Common/BasicModal";
import { Diamond } from "../../assets/icons";
import { PrimaryText, Title } from "../../designSystem";
import { colors } from "../../designSystem/colors";
import { clickableShrunk } from "../Common/style";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { useGlobalState } from "../../store/store";
import { playSecondaryClickSound } from "../../utils/sound";

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 0.8vh;
`;

const SignContent = styled.div.attrs({ className: "d-flex w-100" })`
  padding: 0 0.8vh;
  margin-top: 1.6vh;
`;

const SignButton = styled.div`
  ${clickableShrunk}
  display: flex;
  justify-content: center;
  width: calc(100% - 1.6vh);
  padding: 0.8vh;
  background: ${colors.modalContentBackground};
  border: 0.1vh solid ${colors.primaryRed};
  border-radius: 0.8vh;
  margin: 4vh 0.8vh 0 0.8vh;
`;

const SiweModal: React.FC = () => {
  const { account, connector, deactivate } = useWeb3Wallet();
  const { token, onRequestSignature, resetToken } = useSiwe();

  const [show, setShow] = useGlobalState("showSiweModal");

  const loginFirebase = useCallback(async () => {
    if (account && auth.currentUser?.uid !== account && token) {
      try {
        const { data } = await axios.post<{ firebaseAuthToken: string }>(
          `${API_BASE_URL}/firebase-login`,
          undefined,
          { headers: { Authorization: `bearer ${token}` } }
        );

        signInWithCustomToken(auth, data.firebaseAuthToken);
      } catch (err) {
        resetToken();
      }
    }
  }, [account, resetToken, token]);

  /**
   * Auto hide modal if siwe token exists
   */
  useEffect(() => {
    if (show && token) {
      setShow(false);
    }
  }, [setShow, show, token]);

  useEffect(() => {
    if (account && !token) {
      setShow(true);
    }
  }, [account, setShow, token]);

  useEffect(() => {
    loginFirebase();
  }, [loginFirebase]);

  const handleDisconnect = useCallback(() => {
    if (connector && connector instanceof WalletConnectConnector) {
      const wcConnector = connector as WalletConnectConnector;
      wcConnector.close();
    }
    deactivate();
    setShow(false);
  }, [connector, deactivate, setShow]);

  return (
    <BasicModal
      name="siwe"
      show={show}
      onClose={() => setShow(false)}
      height="34.8vh"
      maxWidth="34.3vh"
    >
      <Header>
        <Diamond width="1vh" height="auto" />
        <Title
          fontSize="2.4vh"
          lineHeight={1.5}
          fontWeight={400}
          letterSpacing={1}
          style={{ marginLeft: "1.6vh", marginRight: "1.6vh" }}
          normalCased
        >
          Verify Wallet
        </Title>
        <Diamond width="1vh" height="auto" />
      </Header>
      <SignContent>
        <PrimaryText className="text-center" fontSize="1.6vh" lineHeight={1.5}>
          To unlock all the features of hideouts, you need to sign a message
          that verifies the ownership of your explorers. You only need to do
          this the first time you connect your wallet.
        </PrimaryText>
      </SignContent>
      <SignButton
        onClick={() => {
          playSecondaryClickSound();
          onRequestSignature(() => setShow(false));
        }}
      >
        <Title>Sign</Title>
      </SignButton>
      <div className="w-100 d-flex justify-content-center mt-3">
        <PrimaryText
          role="button"
          className="clickable"
          onClick={() => {
            playSecondaryClickSound();
            handleDisconnect();
          }}
        >
          Disconnect
        </PrimaryText>
      </div>
    </BasicModal>
  );
};

export default SiweModal;
